import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, Directive, AfterViewInit, OnDestroy, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { EventsService } from '../../../core/events/events.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { LibrariesService } from '../../../core/libraries/libraries.service';
import { FormControl } from '@angular/forms';
import { SettingsService, userTypes, userRoles } from '../../../core/settings/settings.service';
import { ErrorsService } from '../../../core/errors/errors.service';
import { UntypedFormBuilder } from '@angular/forms';
import { SubscriptionsFormComponent } from '../../../shared/component/forms/subscriptions/subscriptions-form/subscriptions-form.component';
import { SubscriptionsAdminViewComponent } from '../../../shared/component/views/subscriptions-admin-view/subscriptions-admin-view.component';
import { ClientsService } from '../../../core/api/clients.service';

@Component({
	selector: 'app-subscriptions-admin',
	templateUrl: './subscriptions.component.html',
	styleUrls: ['./subscriptions.component.scss'],
})

export class SubscriptionsAdminComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(SubscriptionsFormComponent) public subscriptionsAdminForm: SubscriptionsFormComponent;
	@ViewChild(SubscriptionsAdminViewComponent) public subscriptionAdminView: SubscriptionsAdminViewComponent;


	private onDestroy$: Subject<void> = new Subject<void>();
	private paramSub: any;
	public showForm: boolean = false;
	private firstLoad: boolean = true;

	constructor(
		public evensService: EventsService,
		public errorsService: ErrorsService,
		public toastrService: ToastrService,
		private formBuilder: UntypedFormBuilder,
		private clientsService: ClientsService,
		public librariesService: LibrariesService,
		public settingsService: SettingsService,
		private router: Router,
		private route: ActivatedRoute,
		private errorService: ErrorsService


	) { }

	userTypes = userTypes;
	userRoles = userRoles;


	public get dataLoading(): boolean {
		if (!this.subscriptionAdminView) return false;
		return this.subscriptionAdminView.dataLoading;
	}

	private _subscriptionUuid: string = "";
	get SubscriptionUuid(): string {
		return this._subscriptionUuid;
	}
	set SubscriptionUuid(newSubscriptionUuid: string) {
		if (newSubscriptionUuid && newSubscriptionUuid.toString().toLowerCase() == 'add' || this.librariesService.guidValidate(newSubscriptionUuid)) {
			this._subscriptionUuid = newSubscriptionUuid;
			this.showForm = true;
		}
		else {
			this._subscriptionUuid = "";
			this.showForm = false;

		}
	}

	ngOnInit() {

		this.paramSub = this.route.params.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
			this.SubscriptionUuid = params['uuid'];
		});

	}

	ngOnDestroy() {
		this.onDestroy$.next();
	}

	ngAfterViewInit() {


	}


	onDataLoading(event) {
		if (this.dataLoading && this.firstLoad) this.settingsService.showSpinner("subscriptions", false, 250);
		this.firstLoad = false;

		if (!this.dataLoading) this.settingsService.hideSpinner("subscriptions");
	}


	onViewSelected(event) {
		if (event && event.uuid && event.uuid.toString().length > 10) {
			this.router.navigate(['/administration/subscriptions', event.uuid.toString()], { replaceUrl: false })
		}
	}

	dataRefresh() {
		var self = this;
		if (!this.settingsService.client ||
			!this.subscriptionAdminView) {
			setTimeout(() => {
				self.dataRefresh();
			}, 100);
		}
		setTimeout(function () {
			//self.subscriptionAdminView.getSubscriptions();
		}, 500);



	}


	buttonAdd(event) {
		this.router.navigate(['/administration/subscriptions', 'add'], { replaceUrl: false })
	}

	buttonBack(event) {
		this.router.navigate(['/administration/subscriptions/all'])
	}

	buttonEdit(event) {
		this.subscriptionsAdminForm.formState = this.subscriptionsAdminForm.formStates.Edit

	}

	buttonSave(event) {
		this.subscriptionsAdminForm.formState = this.subscriptionsAdminForm.formStates.Save
	}

	buttonCancel(event) {
		this.subscriptionsAdminForm.formState = this.subscriptionsAdminForm.formStates.Cancelled
		this.dataRefresh();
	}


}


