
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild, TemplateRef, ElementRef, Directive, AfterViewInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { EventsService } from '../../core/events/events.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { SettingsService, userTypes, userRoles } from '../../core/settings/settings.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LibrariesService } from '../../core/libraries/libraries.service';
import { UsersService } from '../../core/api/api.services';
import { ErrorsService } from '../../core/errors/errors.service';
import { UsersViewComponent } from '../../shared/component/views/users-view/users-view.component';
import { UserClientFormComponent } from '../../shared/component/forms/users/user-client-form/user-client-form.component';
import { Subject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EmailValidator } from '../../core/validators/email.validator';
import { UserProfileFormComponent } from '../../shared/component/forms/users/user-profile-form/user-profile-form.component';
import { Callbacks } from 'jquery';
import { Options } from 'selenium-webdriver';
import { result } from 'lodash';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(UserClientFormComponent) public userForm: UserClientFormComponent;
  @ViewChild(UserProfileFormComponent) public profileForm: UserProfileFormComponent;
  @ViewChild(UsersViewComponent) public userView: UsersViewComponent;

  userTypes = userTypes;
  userRoles = userRoles;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public authenticationService: AuthenticationService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    public usersService: UsersService,
    public errorsService: ErrorsService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder
  ) { }

  public modalRef: BsModalRef;
  private paramSub: any;
  public showForm: boolean = false;
  public showProfile: boolean = false;
  public enableEditForm: boolean = false;
  public imEditMode: boolean = false;

  private _userUuid: string = "";
  get userUuid(): string {
    return this._userUuid;
  }
  set userUuid(newDeviceUuid: string) {
    if (newDeviceUuid && (newDeviceUuid.toString().toLowerCase() == 'add' || this.librariesService.guidValidate(newDeviceUuid))) {
      this._userUuid = newDeviceUuid;
      this.showForm = true;
      var modalCount = this.modalService.getModalsCount()
      if (modalCount > 0) {
        this.imEditMode = true;
      }
    } else {
      this._userUuid = "";
      this.showForm = false;
      this.imEditMode = false;
    }
  }

  public fgEmail: UntypedFormGroup = this.formBuilder.group({

    userEmail: ['', [Validators.required, EmailValidator.strong]],

  });

  public tknUserUuid = this.authenticationService.tokenUserUuid.toString().toLowerCase();

  ngOnInit() {
    let userPath = "userProfile";
    let myRoute = this.router.url;
    console.log(this.showProfile);

    if (myRoute.search(userPath) != -1) {
      console.log("eureka");
      this.showProfile = true;
    }

    this.paramSub = this.route.params.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.userUuid = params['uuid'];
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {

  }

  onFormEvent(event) {
    switch (event.toString()) {
      case "saved":
        {
          break;
        }
      case "cancelled":
        {
          break;
        }
    }
  }

  onViewSelected(event) {
    if (event && event.uuid && event.uuid.toString().length > 10) {
      this.imEditMode = false;
      this.router.navigate(['/users', event.uuid.toString()], { replaceUrl: false })
    }
  }

  buttonAdd() {
    this.router.navigate(['/users', 'add'],
      {
        queryParams: { email: this.fgEmail.value.userEmail },
        replaceUrl: false
      })
  }

  buttonBack(event) {
    this.enableEditForm = false
    this.router.navigate(['/users/all'])
  }

  buttonEdit(event) {
    if (this.userForm) {
      this.userForm.formState = this.userForm.formStates.Edit
    }
    else if (this.profileForm) {
      this.profileForm.formState = this.profileForm.formStates.Edit;
    }
    this.enableEditForm = false
  }

  buttonSave(event) {
    if (this.userForm) {
      this.userForm.formState = this.userForm.formStates.Save
    }
    else if (this.profileForm) {
      this.profileForm.formState = this.profileForm.formStates.Save;
    }
    this.enableEditForm = false
  }

  buttonCancel(event) {
    if (this.userForm) {
      this.userForm.formState = this.userForm.formStates.Cancelled
      this.enableEditForm = false;
      this.router.navigate(['/users/all']);
    }
    else if (this.profileForm) {
      this.profileForm.formState = this.profileForm.formStates.Cancelled;
    }
    this.enableEditForm = false
  }

  openDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
    this.imEditMode = true;
  }

  buttonDeleteCancel(): void {
    this.modalRef.hide();
    this.imEditMode = false;
  }

  userDeleteConfirm() {

    this.usersService.removeRoleId(this.userForm.userUuid)
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(
      (result: any) => {
        this.toastrService.success('Success', 'User Deleted', { timeOut: 2000, tapToDismiss: true });
        this.modalRef.hide();
        this.router.navigate(['/users/all']);
      },

      error => {
        this.toastrService.error("Server Error (UserDeleteConfirm)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        this.modalRef.hide();
        this.imEditMode = false;
      });
  }

  openEmailModal(template: TemplateRef<any>) {
    this.fgEmail.reset();
    this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
    this.imEditMode = true;
  }

  emailModalCancel(): void {
    this.modalRef.hide();
    this.imEditMode = false;
  }

  emailModalConfirm() {
    this.imEditMode = true;

    if (!this.fgEmail.valid) {
      this.librariesService.validateFormGroup(this.fgEmail);
      return
    }

    this.usersService.checkUserByEmail(this.fgEmail.value.userEmail)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          const user = result;

          if (user && user.uuid && user.uuid.toString().length > 10) {
            this.router.navigate(['/users', user.uuid.toString()], { replaceUrl: false });
          }

          this.enableEditForm = true;
          this.fgEmail.reset();
          this.modalRef.hide();
        },
        error => {

          if (error.status.toString() === '404') {
            this.buttonAdd();
            this.modalRef.hide();
          } else {
            this.toastrService.error("Server Error (checkUserByEmail)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
          }
        });
  }

}
