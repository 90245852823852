<div class="card border-0">
    <div class="card-body pt-0">
        <div class="row">
            <div class="col-lg-6 align-middle">
                <input type='text' #datatableFilter class="form-control" placeholder='Type to filter by Name or Description' (keyup)='updateFilter($event?.target?.value?.toLowerCase())'
                />
            </div>
        </div>
        <ag-grid-angular #agGrid id="devicesViewTable" name="devicesViewTable" style="height: 78vh; width: 100%;"
            class="devicesViewTable m-0 pt-2 border-0"
            [animateRows]="true" [suppressCellFocus]="true"  [rowData]="modems" [theme]="theme"
            (rowSelected)="onRowSelected($event)" [chartThemes]="chartThemes" [rowStyle]="rowStyle"
            (rowClicked)="onRowClicked($event)" [gridOptions]="gridOptions"
            [getChartToolbarItems]="getChartToolbarItems">
        </ag-grid-angular>
    </div>
</div>
