import { Component, OnInit, ViewChild, ElementRef, Directive, AfterViewInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { EventsService } from '../../../core/events/events.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsService, userTypes, userRoles } from '../../../core/settings/settings.service';

import { Router, ActivatedRoute } from '@angular/router';
import { LibrariesService } from '../../../core/libraries/libraries.service';
import { FirmwaresAdminViewComponent } from '../../../shared/component/views/firmwares-admin-view/firmwares-admin-view.component';
import { FirmwareFormComponent } from '../../../shared/component/forms/firmwares/firmware-form/firmware-form.component';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-firmwares-admin',
  templateUrl: './firmwares.component.html',
  styleUrls: ['./firmwares.component.scss'],
})
export class FirmwaresAdminComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(FirmwareFormComponent) public firmwareForm: FirmwareFormComponent;
  @ViewChild(FirmwaresAdminViewComponent) public firmwareView: FirmwaresAdminViewComponent;
  //@ViewChild("btnDevWiFi") btnDevWiFi: ElementRef;

  userTypes = userTypes;
  userRoles = userRoles;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  private paramSub: any;
  public showForm: boolean = false;

  private _firmwareUuid: string = "";
  get firmwareUuid(): string {
    return this._firmwareUuid;
  }
  set firmwareUuid(newDeviceUuid: string) {
   
      if (newDeviceUuid && newDeviceUuid.toString().toLowerCase() == 'add' || this.librariesService.guidValidate(newDeviceUuid)) {
        this._firmwareUuid = newDeviceUuid;
        this.showForm = true;
      }
      else {
        this._firmwareUuid = "";
        this.showForm = false;
      }
    
  }

  ngOnInit() {

    this.paramSub = this.route.params.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.firmwareUuid = params['uuid'];

    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {

  }

  onFormEvent(event) {

    switch (event.toString()) {
      case "saved":
        {
          //alert(this.firmwareUuid)
          break;
        }
      case "cancelled":
        {
          // //this.showForm = false;
          // this.firmwareUuid = "";
          // //this.location.go(this.router.createUrlTree(['/administration/users/all'], { relativeTo: this.route }).toString())
          // this.router.navigate(['/administration/users/all'])
          break;
        }
    }

  }

  onViewSelected(event) {

    if (event && event.uuid && event.uuid.toString().length > 10) {
      //var url = this.router.createUrlTree(['.',event.toString()], {relativeTo: this.route})
      //this.location.go(url.toString());
      this.router.navigate(['/administration/firmwares', event.uuid.toString()], { replaceUrl: false })
      //this.router.navigateByUrl('/administration/users/'+event.toString())
    }
  }

  buttonAdd(event) {
    //alert(this.firmwareForm.formState)
    //this.showForm = true;
    //this.firmwareForm.formState = this.firmwareForm.formStates.New
    this.router.navigate(['/administration/firmwares', 'add'], { replaceUrl: false })
  }

  buttonBack(event) {
    this.router.navigate(['/administration/firmwares/all'])
  }

  buttonEdit(event) {
    this.firmwareForm.formState = this.firmwareForm.formStates.Edit
  }

  buttonSave(event) {
    this.firmwareForm.formState = this.firmwareForm.formStates.Save
  }

  buttonCancel(event) {
    this.firmwareForm.formState = this.firmwareForm.formStates.Cancelled
  }

}
