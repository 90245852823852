import { themeBalham, iconSetMaterial } from "ag-grid-community";


export const customAgTheme = themeBalham
.withPart(iconSetMaterial)
.withParams({
  accentColor: "#5D9CEC",
  backgroundColor: "#FFFFFF",
  borderColor: "#D7E2E6",
  borderRadius: 3,
  browserColorScheme: "inherit",
  cellHorizontalPaddingScale: 0.7,
  columnBorder: false,
  focusShadow: "#6c757d80 0px 0px 0px 3px",
  fontFamily: "inherit",
  fontSize: 14,
  foregroundColor: "#555B62",
  headerBackgroundColor: "#F5F5F5",
  headerFontSize: 14,
  headerFontWeight: 600,
  headerTextColor: "#6C757D",
  oddRowBackgroundColor: "#F5F5F5",
  rowBorder: true,
  rowVerticalPaddingScale: 0.7,
  headerVerticalPaddingScale: 0.7,
  sidePanelBorder: true,
  spacing: 10,
  wrapperBorder: false,
  wrapperBorderRadius: 2,
  cellTextColor: "#6C757D",
})