import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { AlertsService } from '../../../core/alerts/alerts.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { SettingsService, userTypes, userRoles } from '../../../core/settings/settings.service';
import { UsersService } from '../../../core/api/users.service';
import { PasswordValidator } from '../../../core/validators/password.validator';
import { LibrariesService } from '../../../core/libraries/libraries.service';

@Component({
    templateUrl: 'newpassword.component.html',
    styleUrls: ['./newpassword.component.scss'],
    providers: [AuthenticationService, AlertsService, RouterModule, SettingsService]
})

export class NewPasswordComponent implements OnInit {
    model: any = {};
    returnUrl: string;
    private paramSub: any;
    private clientUuid: string = "";

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public authenticationService: AuthenticationService,
        private alertService: AlertsService,
        public settingsService: SettingsService,
        public usersService: UsersService,
        public librariesService: LibrariesService,
        private formBuilder: UntypedFormBuilder,
        private toastrService: ToastrService) { }

    public fgNewPassword: UntypedFormGroup = this.formBuilder.group({
        newpassword: ['', [PasswordValidator.strong(8, true, true, true, "#@$!%*?()>,:/=^_-{}~|")]],
        confirmpassword: ['', [
          function (control: AbstractControl) {
            if (!control.parent) return null
            if (control.parent.value['newpassword'] != '' &&
            control.value == control.parent.value['newpassword'])
            {
                return null;
            }
            else 
            {
                return { confirmpassword: false }
            }
          }]
        ]
    });

    ngOnInit() {
        // reset login status
        this.authenticationService.clear();
        this.settingsService.clearClients();

        this.paramSub = this.route.params.subscribe(params => {
            this.clientUuid = params['uuid'];
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    back(e) {
        e.preventDefault();
        this.router.navigateByUrl('/');
    }

    reset() {
        this.librariesService.validateFormGroup(this.fgNewPassword)
        this.settingsService.clearClients();
        
        this.usersService.setNewPassword(this.fgNewPassword.value.newpassword, this.clientUuid)
                .subscribe(
                    data => {
                        this.toastrService.success("Succcess", "Password Reset Was Successful", {tapToDismiss: true});
                        this.router.navigateByUrl('/');
                    },
                    error => {
                        if (error.status==401)
                        {
                            this.toastrService.error("Reset Password Error", "Email Address Incorrect", 
                            {
                                timeOut: 0,
                                tapToDismiss: true
                            });
                        }
                        else 
                        {
                            this.toastrService.error("Server Error: "+error.status, error.statusText, 
                            {
                                timeOut: 0,
                                progressBar: true,
                                tapToDismiss: true
                            });
                        }
                    });
    }
}
