import {
  Component, AfterViewInit, ElementRef, OnDestroy,
  Input, ChangeDetectorRef, Output, EventEmitter, ViewChild, ViewEncapsulation
} from '@angular/core';
import { BootstrapYearCalendarModel, BootstrapYearCalendarDataSource } from './model/bootstrap-year-calendar';
import '../../../libs/bootstrap-year-calendar'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import moment from 'moment';
declare var jQuery: any;

const TEMPLATE = '<div class="calendar"></div>';

@Component({
  selector: 'app-bootstrap-year-calendar',
  template: TEMPLATE,
  styleUrls: ['./bootstrap-year-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BootstrapYearCalendarComponent implements AfterViewInit {

  @Input() options: BootstrapYearCalendarModel = {};
  @Output() clickDay = new EventEmitter<any>();
  @Output() dayContextMenu = new EventEmitter<any>();
  @Output() mouseOnDay = new EventEmitter<any>();
  @Output() mouseOutDay = new EventEmitter<any>();
  @Output() renderEnd = new EventEmitter<any>();
  @Output() selectRange = new EventEmitter<any>();

  public CalendarRangeSelection(enable: boolean)
  {
    if(jQuery('.calendar').data('calendar'))
      jQuery('.calendar').data('calendar').setEnableRangeSelection(enable)
  }

  get CalendarDataSource(): BootstrapYearCalendarDataSource[] {
    return jQuery('.calendar').data('calendar').getDataSource();
  }
  @Input() set CalendarDataSource(newCalendarDataSource: BootstrapYearCalendarDataSource[]) {
    jQuery('.calendar').data('calendar').setDataSource(newCalendarDataSource);
  }
  
  private defaultOptions: BootstrapYearCalendarModel = {
    allowOverlap: true,
    alwaysHalfDay: false,
    contextMenuItems: [
      // {
      //   text: 'View',
      //   click: (e) => {
      //     this.selectedEvent = e.event;
      //     this.refreshView.next();
      //   },
      //   subMenu: []
      // },
      // {
      //   text: 'Edit',
      //   click: (e) => {
      //     this.selectedEvent = e.event;
      //     this.refreshEdit.next();
      //   },
      //   subMenu: []
      // },
      // {
      //   text: 'Delete',
      //   click: (e) => {
      //     this.selectedEvent = e.event;
      //     this.refreshDelete.next();
      //   },
      //   subMenu: []
      // }
    ],
    dataSource: [],
    disabledDays: [],
    displayWeekNumber: false,
    enableContextMenu: true,
    enableRangeSelection: false,
    language: 'en',
    maxDate: null,
    minDate: null,
    mouseOnDayPopUp: true,
    roundRangeLimits: false,
    startYear: moment(new Date).year(),
    style: 'border'
  };

  constructor() { }

  ngAfterViewInit() {
    
    if (this.options) {
      this.options = Object.assign(this.defaultOptions, this.options);
    };
    jQuery('.calendar').calendar(this.options);
    jQuery('.calendar').clickDay(e => {
      if (this.options.mouseOnDayPopUp && e.events.length > 0) jQuery(e.element).popover('hide');
      setTimeout(() => {
        this.clickDay.emit(e)  
      }, 100);
      
    });
    jQuery('.calendar').mouseOnDay(e => {
      if (this.options.mouseOnDayPopUp && e.events.length > 0) {
        let content = '';

        // tslint:disable-next-line:forin
        for (const i in e.events) {
          content += '<div class="event-tooltip-content">'
            + '<div class="event-name" style="color:' + e.events[i].color + '">' + e.events[i].name + '</div>'
            + '</div>';
        }

        jQuery(e.element).popover({
          trigger: 'manual',
          container: 'body',
          html: true,
          content: content,
          placement: 'bottom' 
        });

        jQuery(e.element).popover('show');
      }
      this.mouseOnDay.emit(e);
    });
    jQuery('.calendar').mouseOutDay(e => {
      if (this.options.mouseOnDayPopUp && e.events.length > 0) {
        jQuery(e.element).popover('hide');
      }
      this.mouseOutDay.emit(e);
    });
    jQuery('.calendar').renderEnd(e => { this.renderEnd.emit(e); });
    jQuery('.calendar').selectRange(e => { 
      if (this.options.mouseOnDayPopUp && e.events.length > 0) jQuery(e.element).popover('hide');
      setTimeout(() => {
        this.selectRange.emit(e); 
      }, 100);
    });
  }

}
