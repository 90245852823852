<div class="card-body">
  <div class="form-group row">
    <div class="col-lg-12">
      <form [formGroup]="fgUser">
        <div class="row">
          <div class="col-sm-6 col-12 m-0 p-0">
            <div class="form-row">
              <div class="col-sm-6 col-12 mb-2">
                <label>First Name</label>
                <div *ngIf="!isEditMode">
                  <app-popoverControl-component [dataText]="user?.first ? user.first : '&nbsp;'"
                    (PopoverEvent)="popoverEvent($event)"></app-popoverControl-component>
                </div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgUser.get('first'))"
                  id="first" name="first" formControlName="first" type="text" placeholder="User First Name" />
                <div *ngIf="librariesService.controlInvalidFlag(fgUser.get('first'))" class="invalid-feedback">Please
                  provide a First Name</div>
              </div>
              <div class="col-sm-6 col-12 mb-2">
                <label>Last Name</label>
                <div *ngIf="!isEditMode">
                  <app-popoverControl-component [dataText]="user?.last ? user.last : '---last---'"
                    (PopoverEvent)="popoverEvent($event)"></app-popoverControl-component>
                </div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgUser.get('last'))"
                  id="last" name="last" formControlName="last" type="text" placeholder="User Last Name" />
                <div *ngIf="librariesService.controlInvalidFlag(fgUser.get('last'))" class="invalid-feedback">Please
                  provide a Last Name</div>
              </div>
              <div class="col-sm-12 col-12 mb-2 mt-0">
                <label>Email</label>
                <div *ngIf="!isEditMode">
                  <app-popoverControl-component [dataText]="user?.email ? user.email : '---email---'"
                    (PopoverEvent)="popoverEvent($event)"></app-popoverControl-component>
                </div>
                <div *ngIf="isEditMode && formState == formStates.New" class="form-control">
                  {{fgUser.get('email').value || ''}}</div>
                <input *ngIf="isEditMode && formState != formStates.New"
                  [class]="librariesService.controlValidationClass(fgUser.get('email'))" id="email" name="email"
                  formControlName="email" type="text" placeholder="User Email Address" autocomplete="off" />
                <div *ngIf="librariesService.controlInvalidFlag(fgUser.get('email'))" class="invalid-feedback">Please
                  provide a valid
                  Email</div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-12 mt-3">
            <div class="form-row">
              <div class="col-sm-12 col-12 mt-2">
                <div class="checkbox c-checkbox mt-2">
                  <label>
                    <input #passwordChange type="checkbox" [attr.disabled]="!isEditMode ? '' : null" id="passwordChange"
                      name="passwordChange" formControlName="passwordChange" />
                    <span class="fas fa-check"></span>Change Password</label>
                  <!-- [attr.disabled]="!isEditMode ? '' : null"  -->
                </div>
              </div>
              <div class="col-sm-12 col-12 mt-2">
                <div class="form-row">
                  <div class="col-sm-6 col-12 mt-1">
                    <label>Password</label>
                    <input [class]="librariesService.controlValidationClass(fgUser.get('password'))" id="password"
                      name="password" formControlName="password" type="password" placeholder="User Password"
                      autocomplete="new-password" />
                    <div *ngIf="librariesService.controlInvalidFlag(fgUser.get('password'))" class="invalid-feedback">
                      Minimum
                      8
                      characters with mixed-case, numbers, and special characters (#&#64;$!%*?()>,:/=^_-~|)</div>
                  </div>
                  <div class="col-sm-6 col-12 mt-1">
                    <label>Confirm Password</label>
                    <input [class]="librariesService.controlValidationClass(fgUser.get('passwordConfirm'))"
                      id="passwordConfirm" name="passwordConfirm" formControlName="passwordConfirm" type="password"
                      placeholder="Confirm User Password" autocomplete="new-password" />
                    <div *ngIf="librariesService.controlInvalidFlag(fgUser.get('passwordConfirm'))"
                      class="invalid-feedback">
                      Does
                      not match Password</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8 col-12 ml-0 mr-0 mb-0 mt-2 p-0">
            <div class="form-row">
              <div class="col-7 mb-2">
                <label>Phone Number</label>
                <div *ngIf="!isEditMode">
                  <app-popoverControl-component [dataText]="user?.phoneMobile ? user.phoneMobile : 'Enter a Phone #'"
                    (PopoverEvent)="popoverEvent($event)"></app-popoverControl-component>
                </div>
                <div *ngIf="isEditMode && formState == formStates.New" class="form-control">
                  {{fgUser.get('phoneMobile').value || ''}}</div>
                <input *ngIf="isEditMode && formState != formStates.New"
                  [class]="librariesService.controlValidationClass(fgUser.get('phoneMobile'))" id="phoneMobile"
                  name="phoneMobile" formControlName="phoneMobile" type="text" placeholder="Enter a phone Number"
                  autocomplete="off" />
                <div *ngIf="librariesService.controlInvalidFlag(fgUser.get('phoneMobile'))" class="invalid-feedback">
                  Please
                  provide a valid Phone Number</div>
              </div>
              <div class="col-4 mt-4">
                <div *ngIf="phoneVerified && !isEditMode">
                  <i class="fas fa-badge-check fa-2x pr-2 pl-2 mt-2" title="Phone Verified"></i>
                </div>
                <div *ngIf="!modalHasBeenOpened && isEditMode && !phoneVerified || !isEditMode && !phoneVerified" class="checkbox c-checkbox mt-2 pl-2">
                  <label>
                    <input [attr.disabled]="!isEditMode || phoneMobile.invalid ? '' : null" #verifyNumber
                      type="checkbox" (change)="onCheckboxChange($event)" name="verifiedNumber" checked="verifiedNumber"
                      formControlName="verifiedNumber" id="verifiedNumber" />
                    <span class="fas fa-check ml-1"></span>Opt-in</label>
                </div>
                <button *ngIf="fgUser.get('verifiedNumber').value == true && modalHasBeenOpened && isEditMode && !phoneVerified" type="button" [attr.disabled]="!isEditMode || phoneMobile.invalid ? '' : null"
                  class="btn btn-primary mr-2" (click)="openPhoneVerifyModal(phoneVerifyModal)">Opt-in</button>
              </div>
            </div>
            <form [formGroup]="fgUserNotifications">
              <div class="col-8 mt-2 pt-2 pl-0">
                <div class="card">
                  <div class="card-header card-header-gray">
                    <h4 class="mb-0 mt-0">Notification Settings</h4>
                  </div>
                  <div class="card-body">
                    <div class="form-row">
                      <div class="col-md-6 col-sm-12 mb-2">
                        <label class="font-weight-bold"><i class="fas fa-exclamation-triangle pr-1"></i>Alert
                          Types</label>
                        <div class="mt-2"><i class="fa fa-battery-quarter pr-1"></i>Critical Battery</div>
                        <div class="mt-2"><i class="fa fa-signal-alt-slash pr-1"></i>Device Offline</div>
                        <div class="mt-2"><i class="fa fa-comment-slash pr-1"></i>Disrupted Communications</div>
                        <div class="mt-2"><i class="fa fa-map-marked pr-1"></i>New Location</div>
                      </div>
                      <div *ngIf="fgUserNotifications" class="col-md-6 col-sm-12 mb-2">
                        <label class="font-weight-bold pl-1 mr-3"><i class="fas fa-bell-on pr-1"></i>Notification
                          Method</label>
                        <div class="form-row">
                          <div class="checkbox c-checkbox mt-2 pl-2">
                            <label>
                              <input #EmailBattery type="checkbox"
                                [attr.disabled]="!isEditMode ? '' : null" [checked]='EmailBattery == true'
                                formControlName="EmailBattery" />
                              <span class="fas fa-check ml-1"></span>Email
                            </label>
                          </div>
                          <div class="checkbox c-checkbox mt-2 pl-2">
                            <label>
                              <input #MobileBattery type="checkbox"
                                [attr.disabled]="!isEditMode || phoneMobile.invalid ||!phoneVerified ? '' : null"
                                [checked]='MobileBattery == true' formControlName="MobileBattery" />
                              <span class="fas fa-check ml-1 pl-2"></span>Mobile
                            </label>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="checkbox c-checkbox mt-1 pl-2">
                            <label>
                              <input #EmailOffline type="checkbox" 
                              [attr.disabled]="!isEditMode ? '' : null"
                              [checked]='EmailOffline == true' formControlName="EmailOffline" />
                              <span class="fas fa-check ml-1"></span>Email
                            </label>
                          </div>
                          <div class="checkbox c-checkbox mt-1 pl-2">
                            <label>
                              <input #MobileOffline type="checkbox" 
                                [attr.disabled]="!isEditMode || phoneMobile.invalid || !phoneVerified ? '' : null"
                                [checked]='MobileOffline == true' formControlName="MobileOffline" />
                              <span class="fas fa-check ml-1 pl-2"></span>Mobile
                            </label>
                          </div>
                        </div>

                        <div class="form-row">
                          <div class="checkbox c-checkbox mt-1 pl-2">
                            <label>
                              <input #EmailDisrupted type="checkbox" [attr.disabled]="!isEditMode ? '' : null"            
                                [checked]='EmailDisrupted == true' formControlName="EmailDisrupted" />
                              <span class="fas fa-check ml-1"></span>Email
                            </label>
                          </div>
                          <div class="checkbox c-checkbox mt-1 pl-2">
                            <label>
                              <input #MobileDisrupted type="checkbox"
                                [attr.disabled]="!isEditMode || phoneMobile.invalid || !phoneVerified ? '' : null"
                                [checked]='MobileDisrupted == true' formControlName="MobileDisrupted" />
                              <span class="fas fa-check ml-1 pl-2"></span>Mobile
                            </label>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="checkbox c-checkbox mt-1 pl-2">
                            <label>
                              <input #EmailLocation type="checkbox" [attr.disabled]="!isEditMode ? '' : null"
                                [checked]='EmailLocation == true' formControlName="EmailLocation"/>
                              <span class="fas fa-check ml-1"></span>Email
                            </label>
                          </div>
                          <div class="checkbox c-checkbox mt-1 pl-2">
                            <label>
                              <input #MobileLocation type="checkbox"
                                [attr.disabled]="!isEditMode || phoneMobile.invalid || !phoneVerified ? '' : null"
                                [checked]='MobileLocation == true' formControlName="MobileLocation"/>
                              <span class="fas fa-check ml-1 pl-2"></span>Mobile
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="pt-0 text-right uuid-opaque">{{ (this.userUuid && this.userUuid.toString() !="") ?
    this.userUuid.toString() : "&nbsp;"}}</div>
</div>

<ng-template #phoneVerifyModal >
  <div class="modal-header" *ngIf="!optIn">
    <h3 class="m-0">Terms Agreement</h3>
  </div>
  <div *ngIf="!optIn" class="modal-body text-center" >
      <div class="terms mb-2 mt-3">
        <div class="checkbox c-checkbox mb-4 mt-1 pl-3 pr-0">
          <input type="checkbox" #consentCheckbox />
          <span class="fas fa-check ml-1" (click)="consentCheckbox.click()" ></span>
        </div>
        <label class="pr-3 pl-0" >
          By checking the box, you consent to receive email and/or text message alerts related to the selected notification types. 
          You may opt-out of email and/or text message alerts at any time. Refer to Radarsign’s 
          <a href="https://www.radarsign.com/privacy-policy/" target="_blank" rel="noopener noreferrer" >Privacy Policy </a>and 
          <a href="https://www.radarsign.com/terms/" target="_blank" rel="noopener noreferrer" >Terms of Use</a>
            for more information.
        </label>
      </div>
        <button type="button" class="btn btn-success mr-3 mt-2 pt-2"
          [disabled]="!consentCheckbox.checked"
          (click)="confirmTermsAcceptance()">Agree</button>
        <button type="button" class="btn btn-primary mt-2 pt-2" (click)="cancelTermsAcceptance()">Close</button>
  </div>
  <div *ngIf="optIn" >
      <div class="modal-body text-center" >
        <i class="fa fa-5x fa-mobile-alt mb-2"></i>
        <h4 class="mt-2"><b>Enter Verification Code</b></h4>
        <p class="text-secondary text-bold">Radarsign will send you a SMS verification code</p>
        <form #phoneForm>
          <div class="flex-row my-3">
            <input #firstField [(ngModel)]="code[0]" maxLength="1" [ngModelOptions]="{standalone: true}" type="text"
              (keydown)="(onKeyDown($event) === true && firstField.value.length == 1) ? 
              secondField.focus() : (onKeyDown($event) === false && firstField.value.length == 0) ? 
              firstField.focus(): ''"
              nplInputDefault class="only-digit-input flex-column">
            <input [(ngModel)]="code[1]" maxLength="1"   type="text" selectOnFocus [ngModelOptions]="{standalone: true}" #secondField 
              (keydown)="(onKeyDown($event) === true && secondField.value.length == 1) ? 
              thirdField.focus() : (onKeyDown($event) === false && secondField.value.length == 0) ? 
              firstField.focus(): ''" 
              nplInputDefault class="only-digit-input flex-column">
            <input [(ngModel)]="code[2]" maxLength="1" type="text" [ngModelOptions]="{standalone: true}" selectOnFocus   #thirdField 
              (keydown)="(onKeyDown($event) === true && thirdField.value.length == 1) ? 
              fourthField.focus() : (onKeyDown($event) === false && thirdField.value.length == 0) ? 
              secondField.focus(): ''" 
              nplInputDefault class="only-digit-input flex-column">
            <input [(ngModel)]="code[3]" maxLength="1"  type="text" [ngModelOptions]="{standalone: true}" selectOnFocus #fourthField
              (keydown)="(onKeyDown($event) === false && fourthField.value.length == 0) ? thirdField.focus() : ''"  
              nplInputDefault class="only-digit-input flex-column">
          </div>
        </form>
        
        <button type="button" class="btn btn-success mr-3 mt-2 pt-2"
          (click)="confirmPhoneNumber(firstField.value + secondField.value + thirdField.value + fourthField.value)">Verify</button>
        <button type="button" class="btn btn-primary mt-2 pt-2" [disabled]="resendCount >= 3" (click)="resendNumber()">Resend</button>
      </div>
  </div>
</ng-template>
<!-- {{fgUser.value | json}}<br> -->