
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, OnInit, AfterViewInit, Input, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { SettingsService } from '../../../../../core/settings/settings.service';
import { DevicesService, FirmwaresService, ModelsService, ModemsService, ClientsService, ConfigMapsService, SubscriptionsService, SubscriptionTypesService, SubscriptionScopesService } from '../../../../../core/api/api.services';
import { EventsService } from '../../../../../core/events/events.service';
import { ToastrService } from 'ngx-toastr';
import { DeviceLocationSettingsViewComponent } from '../../../views/devicelocationsettingsview/devicelocationsettingsview.component';
import { LibrariesService } from '../../../../../core/libraries/libraries.service';
import { ErrorsService } from '../../../../../core/errors/errors.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { SubscriptionsAdminViewComponent } from '../../../views/subscriptions-admin-view/subscriptions-admin-view.component';

export enum SubscriptionFormState {
	Initializing = 1,
	Read = 2,
	New = 3,
	Edit = 4,
	Save = 5,
	Saving = 6,
	Saved = 7,
	Cancelled = 8,
}



//selectors
@Component({
	selector: 'app-subscriptions-form',
	templateUrl: './subscriptions-form.component.html',
	styleUrls: ['./subscriptions-form.component.scss'],
	encapsulation: ViewEncapsulation.None


})


//class 
export class SubscriptionsFormComponent implements OnInit, OnDestroy, AfterViewInit {
	@Output() onFormEventSubscription: EventEmitter<any> = new EventEmitter<any>();
	@Output() onDataLoading: EventEmitter<boolean> = new EventEmitter();

	@ViewChild('agGrid') grid!: AgGridAngular;
	@ViewChild('agGrid2') grid2!: AgGridAngular;
	@ViewChild('agGrid4') grid4!: AgGridAngular;



	//Observables
	private onDestroy$: Subject<void> = new Subject<void>();
	private paramSub: any;
	public dataLoading: boolean = false;
	public devicesTemp: any[] = [];
	public devices: any[] = [];
	public myDeviceTableSelections: any[] = [];

	public subscriptionDevicesData: any[] = [];


	get isValidSubscription() {
		return (this.SubscriptionUuid && this.SubscriptionUuid.toString() != "" && this.librariesService.guidValidate(this.SubscriptionUuid.toString()))
	}

	public formLoaded: boolean = false;
	public subscription: any = null;
	public scope: any = null;
	public subTypes: any = null;
	public scopes: any = null;

	//ag grid variables

	public gridOptions: GridOptions;
	public gridOptions2: GridOptions;
	public gridOptions4: GridOptions;
	public chartThemes;
	public rowStyle;
	public isSelected: boolean = false;

	private _gridData: any = null;
	get gridData(): any {
		return this._gridData;
	}
	@Input() set gridData(newGridData: any) {
		this._gridData = newGridData;
	}

	//formstate setup


	public formStates = SubscriptionFormState
	private _formState: SubscriptionFormState = SubscriptionFormState.Initializing
	get formState(): SubscriptionFormState {
		return this._formState
	}
	@Input() set formState(newFormState: SubscriptionFormState) {
		var updateFormState: SubscriptionFormState = null

		this.fgSubscription.disable()

		switch (newFormState) {
			case this.formStates.Edit: {
				updateFormState = newFormState
				this.fgSubscription.enable()
				this.subscriptionEdit()
				break
			}
			case this.formStates.Save: {
				this._formState = newFormState
				this.fgSubscription.enable()
				this.subscriptionSave()
				break
			}
			case this.formStates.Cancelled: {
				this.subscriptionCancel()
				break
			}
			default: {
				updateFormState = newFormState
			}
		}

		if (updateFormState != null) this._formState = updateFormState
	}



	private _subscriptionUuid: string = "";
	get SubscriptionUuid(): string {
		return this._subscriptionUuid;
	}
	@Input() set SubscriptionUuid(newSubscriptionUuid: string) {
		if (newSubscriptionUuid && newSubscriptionUuid.toString().toLowerCase() == 'add' || this.librariesService.guidValidate(newSubscriptionUuid)) {
			this._subscriptionUuid = newSubscriptionUuid;

		}
		else {
			this._subscriptionUuid = "";

		}
	}


	get isEditMode(): boolean {
		switch (this.formState) {
			case this.formStates.New:
			case this.formStates.Edit:
			case this.formStates.Save:
			case this.formStates.Saving:
			case this.formStates.Saved:
				//case this.formStates.Saved:
				{
					return true
				}
			default:
				{
					return false
				}
		}
	}


	constructor(
		public eventsService: EventsService,
		public toastrService: ToastrService,
		public settingsService: SettingsService,
		public clientsService: ClientsService,
		public devicesService: DevicesService,
		public firmwaresService: FirmwaresService,
		public configMapsService: ConfigMapsService,
		public modelsService: ModelsService,
		public modemsService: ModemsService,
		public errorsService: ErrorsService,
		public subscriptionsService: SubscriptionsService,
		public subscriptionTypesService: SubscriptionTypesService,
		public subscriptionScopesService: SubscriptionScopesService,
		private router: Router,
		public librariesService: LibrariesService,
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute
	) {
		this.chartThemes = [
			'ag-pastel',
			'ag-default',
			'ag-material-dark',
			'ag-vivid-dark',
			'ag-solar',
		];
		this.rowStyle = {
			cursor: 'pointer'
		}
		this.gridOptions = <GridOptions>{
			rowData: this.gridData,
			columnDefs: this.columnDefs,
			rowStyle: this.rowStyle,
			rowSelection: 'multiple',
			suppressRowClickSelection: true,
			//chartThemeOverrides: this.chartThemeOverrides,
			enableCharts: false,
			enableRangeSelection: false
			// pagination: true,
			// rowSelection: 'single'
		}
		this.gridOptions2 = <GridOptions>{
			rowData: this.gridData,
			columnDefs: this.columnDefs2,
			rowStyle: this.rowStyle,
			rowSelection: 'multiple',
			cursor: "pointer",
			suppressRowClickSelection: true,
			onCellClicked: (e) => {
				if (e.colDef.field != 'serial') { // cell is from non-select column
					e.node.setSelected(true);
					this.isSelected = true;
				}
			},
			onGridReady: (params) => {
				var mySerials = this.subscriptionDevicesData;
				console.log(this.gridOptions2)

				for (var i = 0; i < this.subscriptionDevicesData.length; i++) {

					this.grid2.api.forEachNode(function (node) {
						if (node.data!.serial == mySerials[i].serial) {
							node.setSelected(true);
						}
					});
				}
			},


			//chartThemeOverrides: this.chartThemeOverrides,
			enableCharts: false,
			enableRangeSelection: false
			// pagination: true,
			// rowSelection: 'single'
		}
		this.gridOptions4 = <GridOptions>{
			rowData: this.gridData,
			columnDefs: this.columnDefs4,
			rowStyle: this.rowStyle,
			rowSelection: 'multiple',
			cursor: "pointer",
			suppressRowClickSelection: true,
			onCellClicked: (e) => {
				if (e.colDef.field != 'serial') { // cell is from non-select column
					e.node.setSelected(true);
					this.isSelected = true;
				}
			},


			//chartThemeOverrides: this.chartThemeOverrides,
			enableCharts: false,
			enableRangeSelection: false
			// pagination: true,
			// rowSelection: 'single'
		}
	}



	//subscription devices table setup

	columnDefs = [
		{
			headerName: "Serial", field: "serial", sortable: true, suppressRowClickSelection: true, checkboxSelection: false, resizable: true, suppressMenu: true,

		},
		{ headerName: "Uuid", field: "deviceUuid", sortable: true, resizable: true, flex: 1, suppressMenu: true },
	]


	columnDefs2 = [
		{
			headerName: "Serial", field: "serial", sortable: true, suppressRowClickSelection: true, headerCheckboxSelection: true, checkboxSelection: true, resizable: true, suppressMenu: true,

		},
		{ headerName: "Name/Label", field: "label", sortable: true, resizable: true, suppressMenu: true },
		{ headerName: "Model", field: "model.code", sortable: true, resizable: true, suppressMenu: true },
		{ headerName: "Status", field: "deviceStatus.code", sortable: true, resizable: true, suppressMenu: true },
		{ headerName: "Schedule", field: "deviceSchedule.name", sortable: true, resizable: true, suppressMenu: true },
		{ headerName: "Location Name", field: "deviceLocation.name", sortable: true, resizable: true, suppressMenu: true },
		{
			headerName: "Last Communication", field: "lastActivity", sortable: true, resizable: true, flex: 1, suppressMenu: true,
			cellRenderer: (data) => {
				return data.value ? (data.value != null
					? (this.librariesService.datePipe.transform(new Date(data.value), 'MM-dd-yyyy HH:mm:ss'))
					: '') : '';
			}
		}
	]

	columnDefs4 = [
		{
			headerName: "Serial", field: "serial", sortable: true, suppressRowClickSelection: true, checkboxSelection: false, resizable: true, suppressMenu: true,

		},
		{ headerName: "Name/Label", field: "label", sortable: true, resizable: true, suppressMenu: true },
		{ headerName: "Model", field: "model.code", sortable: true, resizable: true, suppressMenu: true },
		{ headerName: "Status", field: "deviceStatus.code", sortable: true, resizable: true, suppressMenu: true },
		{ headerName: "Schedule", field: "deviceSchedule.name", sortable: true, resizable: true, suppressMenu: true },
		{ headerName: "Location Name", field: "deviceLocation.name", sortable: true, resizable: true, suppressMenu: true },
		{
			headerName: "Last Communication", field: "lastActivity", sortable: true, resizable: true, flex: 1, suppressMenu: true,
			cellRenderer: (data) => {
				return data.value ? (data.value != null
					? (this.librariesService.datePipe.transform(new Date(data.value), 'MM-dd-yyyy HH:mm:ss'))
					: '') : '';
			}
		}
	]


	public fgSubscription: UntypedFormGroup = this.formBuilder.group({
		notes: ["", Validators.required],
		scopeId: [1, [Validators.required, Validators.min(0)]],
		typeId: [3, [Validators.required, Validators.min(0)]],
		expirationDate: [new Date()],
		clientUuid: [""],
		subscriptionDevices: []

	});



	ngOnInit() {

		this.paramSub = this.route.params.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
			this.SubscriptionUuid = params['uuid'];
		});

		console.log('onInit: ' + this.formState);

	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
	}

	ngAfterViewInit(): void {
		let self = this;


		setTimeout(() => {
			self.getSubscriptionScopes();
			self.getSubscriptionTypes();
			self.getSubscription();
			self.getDevices();

			console.log(this.columnDefs)
			this.formLoaded = true;
			this.settingsService.showSpinner("subscriptionsAdminForm", false, 250);
		}, 500);

		console.log('afterInit: ' + this.formState);
	}

	getSubscriptionTypes() {
		this.subTypes = null;

		return this.subscriptionTypesService.Read()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(result: any) => {

					this.subTypes = result;

				},
				error => {
					this.settingsService.hideSpinner("subscriptionsAdminForm");
					this.toastrService.error("Server Error (Getting Subscription Scopes)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
					this.formState = SubscriptionFormState.Read;
				});



	}

	getSubscriptionScopes() {

		this.scopes = null;

		return this.subscriptionScopesService.Read()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(result: any) => {

					this.scopes = result;

				},
				error => {
					this.settingsService.hideSpinner("subscriptionsAdminForm");
					this.toastrService.error("Server Error (Getting Subscription Types)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
					this.formState = SubscriptionFormState.Read;
				});

	}


	getSubscription(): any {
		this.subscription = null;


		if (!this.settingsService.client || this.SubscriptionUuid == 'add') {
			this.formState = this.formStates.Edit
			return
		};

		this.settingsService.showSpinner("subscriptionsAdminForm", false, 250);

		return this.subscriptionsService.readByUuid(this.SubscriptionUuid)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(result: any) => {
					this.subscription = result;
					this.subscriptionDevicesData = result.subscriptionDevices;


					this.fgSubscription.patchValue(
						{
							uuid: result.uuid,
							clientUuid: result.clientUuid,
							typeId: result.typeId,
							scopeId: result.scopeId,
							statusId: result.statusId,
							alertStatusId: result.alertStatusId,
							expirationDate: result.expirationDate,
							notes: result.notes,

						}
					)

					console.log(this.fgSubscription);
					this.formState = this.formStates.Read;
				},
				error => {
					this.settingsService.hideSpinner("subscriptionsAdminForm");
					this.toastrService.error("Server Error (Getting Device)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
					this.formState = SubscriptionFormState.Read;
				});

	}

	getDevices() {
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 25);

		if (!this.settingsService.client) return;
		this.dataLoading = true;
		this.onDataLoading.emit(this.dataLoading)


		return this.devicesService.read(true)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(result: any) => {
					this.devices = result;
					this.devicesTemp = result;

					this.dataLoading = false;
					this.onDataLoading.emit(this.dataLoading)
					this.settingsService.hideSpinner("subscriptionsAdminForm");

				},
				error => {
					this.toastrService.error("Server Error (getDevices)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
					this.dataLoading = false;
					this.onDataLoading.emit(this.dataLoading)
				});
	}

	subscriptionEdit() {
		//do setup
		// this.modelDropdown.setValue(this.fgDevice.get('modelId').value)
		// this.firmwareDropdown.setValue(this.fgDevice.get('firmwareUuidTarget').value)
		console.log(this.subscriptionDevicesData);
		console.log("its logging it in edit: ", this.gridOptions2);

		this.columnDefs[0].checkboxSelection == true;
		//Send Event
		this.onFormEventSubscription.emit("edit")

	}




	//function to add subscription devices from table to fgSubscription

	subscriptionSave() {

		this.fgSubscription.value.subscriptionDevices = [];

		if (this.fgSubscription.value.scopeId == 1) {
			this.getSelectedNodes();
		}


		if (this.myDeviceTableSelections.length <= 0 && this.fgSubscription.value.scopeId == 1) {
			this.toastrService.warning('Validation Warning (Subscription Update)',
				'Please Select Devices to Add in Specific Mode', { timeOut: 5000, tapToDismiss: true });
			this.formState = this.formStates.Edit;
			this.settingsService.hideSpinner("subscriptionsADminForm")
			return;
		}

		for (var i = 0; i < this.myDeviceTableSelections.length; i++) {
			this.fgSubscription.value.subscriptionDevices.push(
				{
					deviceUuid: this.myDeviceTableSelections[i].data.uuid,
					serial: this.myDeviceTableSelections[i].data.serial
				}
			)
		}

		this.fgSubscription.value.clientUuid = this.devices[0].clientUuid;

		this.settingsService.showSpinner("subscriptionsAdminForm", false, 250);

		//if the subscription is new, create 		
		if (this.SubscriptionUuid == 'add') {
			const subscription = {
				uuid: '',
				clientUuid: this.devices[0].clientUuid,
				notes: this.fgSubscription.value.notes,
				scopeId: this.fgSubscription.value.scopeId,
				typeId: this.fgSubscription.value.typeId,
				expirationDate: this.fgSubscription.value.expirationDate.toLocaleDateString(),
				subscriptionDevices: this.fgSubscription.value.subscriptionDevices,


			}

			if (!this.fgSubscription.valid) {
				console.log(this.fgSubscription)
				this.librariesService.validateFormGroup(this.fgSubscription)
				this.formState = this.formStates.Edit
				this.toastrService.warning("Validation Warning (Subscription)", "Verify Required Data", { timeOut: 5000, tapToDismiss: true });
				this.settingsService.hideSpinner("subscriptionsAdminForm");
				return
			}



			return this.subscriptionsService.Create(subscription)
				.pipe(takeUntil(this.onDestroy$))
				.subscribe(
					(result: any) => {

						this.SubscriptionUuid = result.uuid

						this.fgSubscription.patchValue(
							{
								scopeId: result.scopeId,
								typeId: result.typeId,
								subscriptionDevices: result.subscriptionDevices,
								expirationDate: result.expirationDate,
								notes: result.notes

							}
						)

						this.getSubscription();
						this.formState = this.formStates.Read
						this.toastrService.success("Success", "Subscription Saved", { timeOut: 2000, tapToDismiss: true });
						this.settingsService.hideSpinner('subscriptionsAdminForm')
					},
					error => {
						this.toastrService.error("Server Error (subscriptionSave)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
						this.formState = this.formStates.Edit
						this.settingsService.hideSpinner('subscriptionsAdminForm')
					});


		} //update 
		else {

			let subscription: any;
			this.fgSubscription.value.subscriptionDevices = [];


			for (var i = 0; i < this.myDeviceTableSelections.length; i++) {
				this.fgSubscription.value.subscriptionDevices.push(
					{
						deviceUuid: this.myDeviceTableSelections[i].data.uuid,
						serial: this.myDeviceTableSelections[i].data.serial
					}
				)
			}


			if (this.fgSubscription.value.expirationDate.toLocaleDateString) {
				subscription = {
					uuid: this.subscription.uuid,
					clientUuid: this.devices[0].clientUuid,
					notes: this.fgSubscription.value.notes,
					scopeId: this.fgSubscription.value.scopeId,
					typeId: this.fgSubscription.value.typeId,
					expirationDate: this.fgSubscription.value.expirationDate.toLocaleDateString(),
					subscriptionDevices: this.fgSubscription.value.subscriptionDevices,


				}

			}

			else {
				subscription = {
					uuid: this.subscription.uuid,
					clientUuid: this.devices[0].clientUuid,
					notes: this.fgSubscription.value.notes,
					scopeId: this.fgSubscription.value.scopeId,
					typeId: this.fgSubscription.value.typeId,
					expirationDate: this.fgSubscription.value.expirationDate,
					subscriptionDevices: this.fgSubscription.value.subscriptionDevices,


				}
			}

			return this.subscriptionsService.Update(subscription)
				.pipe(takeUntil(this.onDestroy$))
				.subscribe(
					(result: any) => {

						this.SubscriptionUuid = result.uuid

						this.fgSubscription.patchValue(
							{
								scopeId: result.scopeId,
								typeId: result.typeId,
								subscriptionDevices: result.subscriptionDevices,
								expirationDate: result.expirationDate,
								notes: result.notes

							}
						)
						this.getSubscription();

						this.formState = this.formStates.Read
						this.toastrService.success("PUT", "Subscription Saved", { timeOut: 2000, tapToDismiss: true });
						this.settingsService.hideSpinner('subscriptionsAdminForm')
					},
					error => {
						this.toastrService.error("Server Error (Update Subscription: Save err or Devices are already on another Subscription)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
						this.formState = this.formStates.Edit
						this.settingsService.hideSpinner('subscriptionsAdminForm')
					});

		}


	}

	subscriptionCancel() {

		if (this.fgSubscription.get('expirationDate').value == '' || this.fgSubscription.get('notes').value =='' || this.SubscriptionUuid == 'add') {
			this.router.navigate(['administration/subscriptions/all'])
		}

		this.formState = this.formStates.Read
		this.columnDefs[0].checkboxSelection == false;
		this.onFormEventSubscription.emit("cancelled")
		this.settingsService.hideSpinner("subscriptionsAdminForm");
	}


	controlValidationClass(control) {
		return "form-control" + (this.controlInvalidFlag(control) != null ? (this.controlInvalidFlag(control) ? " is-invalid" : " is-valid") : "")
	}
	controlInvalidFlag(control) {
		if (!(control.touched)) return null;
		return control.invalid
	}

	refreshGrid() {
		this.autoSizeColumns();
	}

	getSelectedNodes() {
		this.myDeviceTableSelections = [];
		this.myDeviceTableSelections = this.grid2.api.getSelectedNodes();

		/*for (var i = 0; i < this.myDeviceTableSelections.length; i++) {
		  this.myDeviceTableSelections[i] = this.myDeviceTableSelections[i].data.uuid;
		}*/
		return this.myDeviceTableSelections;
	}

	autoSizeColumns() {
		// if (this.gridOptions) return

		let allColumnIds = [];
		this.grid.api.getColumns().forEach(function (column) {
			allColumnIds.push(column);
		});
		this.grid.api.autoSizeColumns(allColumnIds);

	}

	formAdd() {
		this.onFormEventSubscription.emit("add")
	}


}