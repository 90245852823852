
import {filter,  takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, ViewChild, EventEmitter, Output, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MenuService } from '../../../../core/menu/menu.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { EventsService } from '../../../../core/events/events.service';
// import { DatatableComponent } from '@swimlane/ngx-datatable';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { DeviceSchedulesService } from '../../../../core/api/api.services';
import { RoundPipe } from '../../../pipes/round.pipe';
import { DevicesAvailableViewComponent } from '../../views/devicesavailable-view/devicesavailable-view.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-devicesselected-view',
  templateUrl: './devicesselected-view.component.html',
  styleUrls: ['./devicesselected-view.component.scss'],
})
export class DevicesSelectedViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() removingSelectedDeviceEvent = new EventEmitter<any>();
  @Output() onDeviceSelectedSelected: EventEmitter<any> = new EventEmitter()
  // @ViewChild('devicesSelectedTable') dataView: DatatableComponent;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public deviceSelectedTemp: any[] = [];
  public deviceSelected: any[] = [];
  public allSelectedDevices: any[] = [];
  public deviceSelectedSelected: any[] = [];
  public isEditMode: boolean = false;
  public isRemoving: boolean = false;
  private paramSub: any;

  datePipe = new DatePipe('en-US');
  decimalPipe = new DecimalPipe('en-US');

  private _devicesSelected: any[];
  set DevicesSelected(newDevicesSelected: any[]) {
    this._devicesSelected = newDevicesSelected;
    this.getDevicesSelected();
    //console.log(this.deviceSelected)
  }

  constructor(
    public menu: MenuService,
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    public deviceSchedulesService: DeviceSchedulesService,
    public round: RoundPipe,
    private route: ActivatedRoute) {
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    let self = this;
    setTimeout(() => {
      self.getDevicesSelected();
    }, 500); 
  }

  getDevicesSelected(): any {
    this.deviceSelectedTemp = [];
    this.deviceSelected = [];
    this.deviceSelectedSelected = [];

    if (!this.settingsService.client ) { 
      return;
    }

    if (this._devicesSelected) {
      this.deviceSelected = this._devicesSelected;
      this.deviceSelectedTemp = this._devicesSelected;

      // if (this.dataView) this.dataView.rows = this.deviceSelected;
    }
  }

  onSelect({ selected }) {
    // this.allSelectedDevices = [...this.deviceSelected];
    if (selected && selected[0].uuid) {
      this.onDeviceSelectedSelected.emit(selected[0].id);
    }

    if (this.isRemoving) {
      const deviceSelectedSelected = this.deviceSelectedSelected;
      // const removingUuid = this.deviceSelectedSelected[0].uuid;
      // this.dataView.rows = this.dataView.rows.filter(r => r.uuid !== removingUuid);
      this.removingSelectedDeviceEvent.emit(deviceSelectedSelected);
      this.isRemoving = false;
    }
  }

  buttonSave(event) {
  }

  buttonCancel(event) {
  }

  buttonRefresh(event) {
    this.getDevicesSelected();
  }

  removeSelectedDevice() {
    this.isRemoving = true;
  }

}
