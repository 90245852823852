
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, ViewChild, EventEmitter, Input, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MenuService } from '../../../../core/menu/menu.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { EventsService } from '../../../../core/events/events.service';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions, ChartType } from 'ag-grid-community';
// import { DatatableComponent } from '@swimlane/ngx-datatable';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { DeviceDailySummariesService } from '../../../../core/api/devicedailysummaries.service';
import { RoundPipe } from '../../../pipes/round.pipe';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-devicedataview',
  templateUrl: './devicedataview.component.html',
  styleUrls: ['./devicedataview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeviceDataViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onDeviceDataSelected: EventEmitter<any> = new EventEmitter()
  // @ViewChild('deviceDataTable') dataView: DatatableComponent;
  @ViewChild('agGrid') grid!: AgGridAngular;

  @Output() PopoverEvent: EventEmitter<string> = new EventEmitter();

  @Input() public dateBegin: Date = null;
  @Input() public dateEnd: Date = null;

  @Output() onDataLoading: EventEmitter<boolean> = new EventEmitter()
  public dataLoading: boolean = false;

  //Observables
  public gridOptions: GridOptions;
  public icons;
  private onDestroy$: Subject<void> = new Subject<void>();
  public getTimeNow: boolean = true;

  public deviceDataTemp: any[] = [];
  public deviceData: any[] = [];
  public deviceDataSelected: any[] = [];
  private paramSub: any;
  public chartThemes;
  datePipe = new DatePipe('en-US');
  decimalPipe = new DecimalPipe('en-US');

  private _gridData: any = null;
  get gridData(): any {
    return this._gridData;
  }
  @Input() set gridData(newGridData: any) {
    this._gridData = newGridData;
  }


  private _deviceUuid = '';
  get deviceUuid(): string {
    return this._deviceUuid;
  }
  set deviceUuid(newDeviceUuid: string) {
    if (this.librariesService.guidValidate(newDeviceUuid)) {
      this._deviceUuid = newDeviceUuid;
      this.getDeviceData();
    } else {
      this._deviceUuid = '';
      this.deviceDataTemp = [];
      this.deviceData = [];
      this.deviceDataSelected = [];
    }
  }

  public fgData: UntypedFormGroup = this.formBuilder.group({
    dateRange: [new Date(), new Date()]
  });

  sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',

        toolPanelParams: {
          suppressRowGroups: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressValues: true

        }
      }
    ]
  }

  chartThemeOverrides = {
    line: {
      title: {
        enabled: true,
        text: 'Vehicle Data',
        fontFamily: 'Source Sans Pro',
        fontWeight: 'normal',
      },
      navigator: {
        enabled: true,
        height: 20,
        width: 100,
        margin: 20,
        min: 0,
        max: 1,
        mask: {
          fill: '#046b32'
        }
      },
      axes: {
        category: {
          position: 'bottom',
          label: {
            formatter: params => {
              return this.datePipe.transform(new Date(params.value), "MM/dd HH:mm")
            },
            rotation: -70,
            fontSize: 10,
          },
          tick: {
            size: 10,
          },
        },
        time: {
          position: 'bottom',
          label: {
            formatter: params => {
              return this.datePipe.transform(new Date(params.value), "MM-dd-yy")
            },
            fontSize: 12,
          },
        },
        number: {
          type: 'number',
          position: 'left',
          min: 0,
          label: {
            padding: 8,
          },
        },
      },
      series: {
        lineDash: [1, 1],
        tooltip: {
          title: {
            enabled: true,

          },
          enabled: true,
          renderer: function (params) {
            let date = new Date(params.xValue);
            return (
              '<div class= "ag-chart-tooltip-title" style="background-color:' + params.color + '">' +
              params.yName +
              '</div>' +
              '<div class= "ag-chart-tooltip-content">' +
              date.toLocaleDateString() + "-" + date.toLocaleTimeString() + "<br>" +
              "Value : " + "&nbsp" + params.yValue +
              '</div>'
            );
          },
        },
        marker: {
          enabled: true,
          size: 4,
          opacity: 1
        },
      },
    },
  }



  columnDefs = [
    {
      headerName: 'Record Date', field: 'recordDate', chartDataType: 'time', sortable: true, resizable: true, suppressMenu: true,
      cellRenderer: (data) => {
        return data.value ? (data.value != null
          ? (new Date(data.value)).toLocaleDateString()
          : '') : '';
      }

    },
    // { headerName: 'Unit Speed', field: 'unitIdSpeed', sortable: true, resizable: true },
    { headerName: 'Vehicles', field: 'vehicles', sortable: true, resizable: true, suppressMenu: true },
    { headerName: 'Violators', field: 'violators', sortable: true, resizable: true, suppressMenu: true },
    { headerName: 'Average Speed', field: 'averageSpeed', sortable: true, resizable: true, valueGetter: this.divisionValueGetter, suppressMenu: true },
    { headerName: 'Speed Peak', field: 'speedPeak', sortable: true, resizable: true, suppressMenu: true },
    { headerName: 'Percentile 50', field: 'percentile50', sortable: true, resizable: true, suppressMenu: true },
    { headerName: 'Percentile 85', field: 'percentile85', sortable: true, resizable: true, flex: 1, suppressMenu: true },
    { headerName: "", sortable: false, resizable: false, suppressMenu: true, suppressColumnsToolPanel: true, flex: 2, chartDataType: 'excluded' },
  ];

  valueGetter = function (params) {

    return params.node.rowIndex

  }

  divisionValueGetter(params) {

    return Math.round(params.data.speedAverageSum / params.data.vehicles);

  }


  constructor(
    public menu: MenuService,
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    public deviceDailySummariesService: DeviceDailySummariesService,
    public round: RoundPipe,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder) {
    this.chartThemes = [
      'ag-pastel',
      'ag-default',
      'ag-material-dark',
      'ag-vivid-dark',
      'ag-solar',
    ];
    if (sessionStorage.getItem('dateBegin') && sessionStorage.getItem('dateEnd')) {
      this.dateBegin = new Date(sessionStorage.getItem('dateBegin'));
      this.dateEnd = new Date(sessionStorage.getItem('dateEnd'));
      this.dateBegin.toLocaleString();
      this.dateEnd.toLocaleString();
      this.fgData.patchValue({
        dateRange: [this.dateBegin, this.dateEnd]
      })
    }
    else {
      this.setLastDates(30);
    }
    this.gridOptions = <GridOptions>{
      rowData: this.gridData,
      columnDefs: this.columnDefs,
      rowSelection: "single",
      sideBar: this.sideBar,
      chartThemeOverrides: this.chartThemeOverrides,
      enableCharts: true,
      enableRangeSelection: true
      // pagination: true,
      // rowSelection: 'single'
    }
  }

  ngOnInit() {
    this.paramSub = this.route.params.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.deviceUuid = params['uuid'];
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  dateFormatter(params) {
    var myNewDate = params.value
    myNewDate = new Date(myNewDate).toLocaleDateString();
    return myNewDate;
  }

  ngAfterViewInit() {
    let self = this;
    self.fgData.get('dateRange').valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(val => {
        sessionStorage.setItem('dateBegin', this.fgData.get('dateRange').value[0]);
        sessionStorage.setItem('dateEnd', this.fgData.get('dateRange').value[1]);
        self.getDeviceData();
      });
    setTimeout(() => {
      self.getDeviceData();
    }, 500);
  }

  refreshGrid() {
    this.autoSizeColumns();
  }


  autoSizeColumns() {
    // if (this.gridOptions) return

    let allColumnIds = [];
    this.grid.api.getColumns().forEach(function (column) {
      allColumnIds.push(column);
    });
    this.grid.api.autoSizeColumns(allColumnIds);

  }

  getDeviceData(): any {
    this.deviceDataTemp = [];
    this.deviceData = [];
    this.deviceDataSelected = [];

    this.dataLoading = true;
    this.onDataLoading.emit(this.dataLoading)
    if (this.dataLoading) this.settingsService.showSpinner("deviceData", false, 250);

    if (sessionStorage.getItem('dateBegin') && sessionStorage.getItem('dateEnd')) {
      this.dateBegin = new Date(sessionStorage.getItem('dateBegin'));
      this.dateEnd = new Date(sessionStorage.getItem('dateEnd'));
      this.dateBegin.toLocaleString();
      this.dateEnd.toLocaleString();
      this.fgData.get('dateRange').value[0] = this.dateBegin;
      this.fgData.get('dateRange').value[1] = this.dateEnd;
      let dateTestEnd: Date = new Date();
      if (dateTestEnd.getMonth() == this.dateEnd.getMonth() && (dateTestEnd.getDay() == this.dateEnd.getDay() || this.dateEnd.getDay() > dateTestEnd.getDay())) {
        this.getTimeNow = true;
      }
      else {
        this.getTimeNow = false;
      }

    }
    else {
      this.dateBegin = this.fgData.get('dateRange').value[0];
      this.dateEnd = this.fgData.get('dateRange').value[1];
      this.getTimeNow = true;
    }

    let timeDiff = this.dateEnd.getTime() - this.dateBegin.getTime();
    let daysDiff = (timeDiff / (1000 * 3600 * 24)).round(0)

    if (daysDiff > 30) {
      this.toastrService.warning("", "Date Period should be less or equal 30 days", { timeOut: 15000, tapToDismiss: true });

      const dateEndNew = new Date(this.dateEnd);
      const dateStartNew = new Date(this.dateEnd.setDate(this.dateEnd.getDate() - 30));

      this.fgData.get('dateRange').value[1] = dateEndNew;
      this.fgData.get('dateRange').value[0] = dateStartNew;

      this.fgData.patchValue({
        dateRange: [dateStartNew, dateEndNew]
      });
    }

    if (!this.settingsService.client || !this.deviceUuid) {
      return;
    }

    return this.deviceDailySummariesService.readDeviceDailySummariesSummaryByDeviceDateRange(this.deviceUuid, this.dateBegin, this.dateEnd)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.deviceData = result;
          this.deviceDataTemp = result;
          if (this.deviceData.length > 0) {
            for (var i = 0; i < this.deviceData.length; i++) {
              if (this.deviceData[i].recordDate == "Invalid or Missing JSON") {
                console.log('Missing or invalid JSON @index ' + i)
              }
              else {
                // this.deviceTelemetries[i].recordDateTime= JSON.parse(this.deviceTelemetries[i].recordDateTime);
                this.deviceData[i].recordDate = Date.parse(this.deviceData[i].recordDate)
              }
            }
          }
          this.dataLoading = false;
          this.onDataLoading.emit(this.dataLoading)
          if (!this.dataLoading) this.settingsService.hideSpinner("deviceData");
        },
        error => {
          this.toastrService.error('Server Error (getDeviceData)',
            this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
          this.dataLoading = false;
          this.onDataLoading.emit(this.dataLoading)
        });

  }


  createLineGraph() {
    let params = {
      cellRange: {
        columns: ['recordDate', 'vehicles', 'violators', 'averageSpeed', 'speedPeak', 'percentile50', 'percentile85']
      },
      chartType: 'line' as ChartType,
      chartThemeOverrides: {
        common: {
          title: {
            enabled: true,
            text: "Vechile Data"
          },
        },
      },
    }
    this.grid.api.createRangeChart(params);
  }

  downloadExcel() {
    let today = new Date();
    let dateString = this.datePipe.transform(new Date(today), "MM-dd-yy");
    let params = {
      columnKeys: ['recordDate', 'vehicles', 'violators', 'averageSpeed', 'speedPeak', 'percentile50', 'percentile85'],
      fileName: 'Vehicle Data ' + dateString,
      processCellCallback: this.myCellCallback,
    }
    this.grid.api.exportDataAsExcel(params)
  }

  myCellCallback(params) {
    if (params.value > 10000) {
      let formattedCell = new Date(params.value).toLocaleString();
      params = formattedCell;
      return params;
    }
    return params.value
  }

  getChartToolbarItems() {
    return ['chartDownload', 'chartData', 'chartFormat']

  }

  onSelect({ selected }) {
    if (selected && selected[0].uuid) {
      this.onDeviceDataSelected.emit(selected[0].id);
    }
  }

  buttonSave(event) {
  }

  buttonCancel(event) {
  }

  buttonRefresh(event) {
    if (this.getTimeNow === true) {
      this.setLastDates(30);
      this.getDeviceData();
    }
    else if (this.getTimeNow === false) {
      this.getDeviceData();
    }
  }

  getDataForLastWeek() {
    this.setLastDates(7);
    this.getDeviceData();
  }

  getDataForLastMonth() {
    this.setLastDates(30);
    this.getDeviceData();
  }

  setLastDates(days: number) {
    let dateBegin: Date = new Date();
    dateBegin.setDate(dateBegin.getDate() - days);

    this.fgData.patchValue({
      dateRange: [dateBegin, new Date()]
    });
  }

}
