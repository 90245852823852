
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, ElementRef, ViewEncapsulation, ViewChild, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuService } from '../../../../core/menu/menu.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { UsersService, ClientsService, RolesService } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
// import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { Subject } from 'rxjs';
import { repeat } from 'lodash';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions, ChartType, SizeColumnsToContentStrategy } from 'ag-grid-community';
import { customAgTheme } from 'app/shared/styles/themes/theme-ag-custom';
import { LibrariesService } from '../../../../core/libraries/libraries.service';

@Component({
  selector: 'app-users-view',
  templateUrl: './users-view.component.html',
  styleUrls: ['./users-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsersViewComponent implements OnInit, AfterViewInit, OnDestroy {

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public userRoles = userRoles;

  public usersTemp: any[] = [];
  public users: any[] = [];
  public usersSelected: any[] = [];

  private _connectivityTypeId: number = -1;
  userUuid: string;
  get connectivityTypeId(): number {
    return this._connectivityTypeId;
  }
  @Input() set connectivityTypeId(Id: number) {
    this._connectivityTypeId = Id
    if (this.datatableFilter) this.updateFilter(this.datatableFilter.nativeElement.value)

  }

  @Output() onViewSelected: EventEmitter<any> = new EventEmitter()
  // @ViewChild(DatatableComponent) dataView: DatatableComponent;
  @ViewChild('datatableFilter') datatableFilter: ElementRef;

  //ag-grid variables start

  public theme = customAgTheme;

  public isSelected: boolean = false;
  public rowStyle;
  public rowSelection: 'single' | 'multiple' = 'multiple';

  public gridOptions: GridOptions;
  public chartThemes;

  private _gridData: any = null;
  get gridData(): any {
    return this._gridData;
  }
  @Input() set gridData(newGridData: any) {
    this._gridData = newGridData;
  }
  

  constructor(
    public menu: MenuService,
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public errorsService: ErrorsService,
    public rolesService: RolesService,
    public librariesService: LibrariesService,
    private router: Router,
    public usersService: UsersService) {
    this.chartThemes = [
      'ag-pastel',
      'ag-default',
      'ag-material-dark',
      'ag-vivid-dark',
      'ag-solar',
    ];
    this.rowStyle = {
      cursor: 'pointer'
    }
    this.gridOptions = <GridOptions>{
      rowData: this.gridData,
      columnDefs: this.columnDefs,
      rowStyle: this.rowStyle,
      rowSelection: 'multiple',
      cursor: "pointer",
      suppressRowClickSelection: true,
      onCellClicked: (e) => {
        if (e.colDef.field != 'serial') { // cell is from non-select column
            e.node.setSelected(true);
            this.isSelected = true;
        }
    },

      enableCharts: false,
      enableRangeSelection: false
    }
  }

  columnDefs = [
    { headerName: "Email", field: "email", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "First", field: "first", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Last", field: "last", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Activity", field: "activityDate", sortable: true, resizable: true, suppressMenu: true,
      cellRenderer: (data) => {
        return data.value ? (data.value != null
          ? (this.librariesService.datePipe.transform(new Date(data.value), 'medium'))
          : '') : '';
      } 
    },
    { headerName: "Login/Token", field: "loginDate", sortable: true, resizable: true, suppressMenu: true,
      cellRenderer: (data) => {
        return data.value ? (data.value != null
          ? (this.librariesService.datePipe.transform(new Date(data.value), 'medium'))
          : '') : '';
      } 
    },
    { headerName: "Created", field: "createdDate", sortable: true, resizable: true, suppressMenu: true,
      cellRenderer: (data) => {
        return data.value ? (data.value != null
          ? (this.librariesService.datePipe.transform(new Date(data.value), 'medium'))
          : '') : '';
      } 
    },
    { 
      headerName: "Role", field: "role.id", sortable: true, resizable: true, suppressMenu: true, flex: 1,
      cellRenderer: (data) => {
        let myType = '';

        if (data.data.userClientRoles[0].role.id == userRoles.Administrator) {
          myType = '<div class="text-danger">' + data.data.userClientRoles[0].role.name + '</div>'
        }
        if (data.data.userClientRoles[0].role.id == userRoles.Manager) {
          myType = '<div class="text-primary">' + data.data.userClientRoles[0].role.name + '</div>'
        }
        if(data.data.userClientRoles[0].role.id == userRoles.Reader) {
          myType = '<div class="text-success">' + data.data.userClientRoles[0].role.name + '</div>'
        }
        return myType;
      }
    }
  ]

  autoSizeStrategy:
    SizeColumnsToContentStrategy = {
      type: "fitCellContents",
      colIds: [ "email", "first", "last", "activityData", "loginDate", "createdDate" ]
    };

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    let self = this
    setTimeout(function () {
      self.getUsersClient()
    }, 500);

  }


  matchRole(row): string {
    let roleName = this.users.find(d => d.userUuid == row.uuid);
    if (roleName) {
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].userUuid == row.uuid) {
          this.users[i].roleName = roleName.roleId;
        }

      }

    }
    else {
      return "No Data";
    }

    return roleName.roleName;

  }


  getUsersClient(): any {
    if (!this.settingsService.client) return;
    return this.usersService.readUsersClient()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.users = result;

          this.usersTemp = result;
          if (this.datatableFilter) this.updateFilter(this.datatableFilter.nativeElement.value)

        },
        error => {
          this.toastrService.error("Server Error (getUsersClient)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        },

      );

  }

  onRowClicked(event) {
    console.log(event)
    this.router.navigate(['/users/', event.node.data.uuid]);
  }


  getChartToolbarItems() {
    return ['chartDownload', 'chartData', 'chartFormat']

  }

  updateFilter(event) {
    const val = event.toLowerCase();

    const temp = this.usersTemp.filter(function (d) {
      return (
        (
          (d.first != null && d.first.toLowerCase().indexOf(val) !== -1) ||
          (d.last != null && d.last.toLowerCase().indexOf(val) !== -1) ||
          (d.email != null && d.email.toLowerCase().indexOf(val) !== -1) ||
          (d.userClientRoles[0].role.name != null && d.userClientRoles[0].role.name.toLowerCase().indexOf(val) !== -1) ||
          val.toString().trim() == ''
        )
      );
    });

    // update the rows
    this.users = temp;

  }

  onSelect({ selected }) {
    this.usersSelected.splice(0, this.usersSelected.length);
    if (selected && selected[0].uuid) {
      console.log('onSelect Event', selected);
      this.onViewSelected.emit(selected[0]);
    }
  }

}
