import { Component, OnInit, Input, ViewChild, ElementRef, Directive, AfterContentInit, AfterViewInit, OnDestroy, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { EventsService } from '../../core/events/events.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsService, userTypes, userRoles } from '../../core/settings/settings.service';
import { ConfigurationsFormComponent } from '../../shared/component/forms/configurations/configurations-form.component';
import { ConfigurationViewComponent } from '../../shared/component/views/deviceconfigurationview/configurationview.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LibrariesService } from '../../core/libraries/libraries.service';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DeviceSchedulesService } from '../../core/api/api.services';
import { ErrorsService } from '../../core/errors/errors.service';



export enum ConfigurationFormState {
  Initializing = 1,
  Upload = 2,
  ConfigurationListing = 3,
  SchedulesForm = 4,
}

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss'],
})
export class ConfigurationsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ConfigurationsFormComponent) public configurationsForm: ConfigurationsFormComponent;
  @ViewChild(ConfigurationViewComponent) public configurationView: ConfigurationViewComponent;
  modalRef: BsModalRef;

  userTypes = userTypes;
  userRoles = userRoles;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private modalService: BsModalService,
    public deviceSchedulesService: DeviceSchedulesService,
    public errorsService: ErrorsService,
  ) { }

  public formStates = ConfigurationFormState;
  private _formState: ConfigurationFormState = ConfigurationFormState.Initializing;
  get formState(): ConfigurationFormState {
    return this._formState;
  }

  @Input() set formState(newFormState: ConfigurationFormState) {
    let updateFormState: ConfigurationFormState = null;

    switch (newFormState) {
      case this.formStates.Upload: {
        updateFormState = newFormState;
        break;
      }
      case this.formStates.ConfigurationListing: {
        updateFormState = newFormState;
        break;
      }
      case this.formStates.SchedulesForm: {
        updateFormState = newFormState;
        break;
      }
      default: {
        updateFormState = newFormState;
      }
    }

    if (updateFormState != null) {
      this._formState = updateFormState;
    }
  }

  private paramSub: any;
  public viewCurrent: string = 'All';
  public showDeviceSchedulesForm: boolean = false;
  private _deviceScheduleUuid: string = '';

  get DeviceScheduleUuid(): string {
    return this._deviceScheduleUuid;
  }

  set DeviceScheduleUuid(newDeviceScheduleUuid: string) {
    if ((newDeviceScheduleUuid && newDeviceScheduleUuid.toString().toLowerCase() === 'add')
      || this.librariesService.guidValidate(newDeviceScheduleUuid)) {
      this._deviceScheduleUuid = newDeviceScheduleUuid;
      this.showDeviceSchedulesForm = true;
    }
    else {
      this._deviceScheduleUuid = '';
      this.showDeviceSchedulesForm = false;
    }
  }

  ngOnInit() {

    this.paramSub = this.route.params.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      console.log('params[uuid]', params['uuid']);
      this.DeviceScheduleUuid = params['uuid'];
    });

    this.paramSub = this.route.queryParams.subscribe(params => {

      if (params['view']) {
        switch ((params['view']).toString().toLowerCase()) {
          case 'all': {
            this.viewCurrent = 'All';
            break;
          }
          default: {
            this.viewCurrent = 'All';
            break;
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {

  }

  onFormEventDeviceSchedule(event) {
    switch (event.toString()) {
      case 'saved': {
        break;
      }
      case 'cancelled': {
        break;
      }
    }
  }

  onDeviceScheduleSelected(event) {
    if (event && event.uuid && event.uuid.toString().length > 10) {
      this.router.navigate(['/configurations', event.uuid.toString()], { replaceUrl: false });
    }
  }
  buttonListing(event) {
    this.formState = this.formStates.ConfigurationListing;
    this.router.navigate(['/configurations'], { queryParams: { view: this.viewCurrent.toLocaleLowerCase() } });
  }

  buttonAdd(event) {
    this.router.navigate(['/configurations', 'add'], { replaceUrl: false });
  }

  buttonBack(event) {
    this.router.navigate(['/configurations/all']);
  }

  buttonEdit(event) {
    this.configurationsForm.formState = this.configurationsForm.formStates.Edit;
    var activeTabDS = this.configurationsForm.configurationTabs.tabs.filter(f => f.active == true && f.title.toLocaleLowerCase() == "day schedules")
    if (!this.configurationsForm.isDaySchedulesTabDisabled &&
      activeTabDS.length > 0 &&
      this.configurationsForm.eventSchedulesForm &&
      (
        this.configurationsForm.eventSchedulesForm.eventScheduleForm.showDailyEventScheduleTimeForm)
    ) {
      //debugger
      this.configurationsForm.eventSchedulesForm.formState = this.configurationsForm.eventSchedulesForm.formStates.Edit

      if (this.configurationsForm.eventSchedulesForm.eventScheduleForm.showDailyEventScheduleForm ||
        this.configurationsForm.eventSchedulesForm.eventScheduleForm.showDailyEventScheduleTimeForm) {
        this.configurationsForm.eventSchedulesForm.eventScheduleForm.formState = this.configurationsForm.eventSchedulesForm.eventScheduleForm.formStates.Edit
      }
    }
  }

  buttonSave(event) {
    this.configurationsForm.formState = this.configurationsForm.formStates.Save;
  }

  buttonCancel(event) {
    if (this._deviceScheduleUuid.toString().toLowerCase() === 'add') {
      this.buttonBack(null);
      return;
    }
    this.configurationsForm.formState = this.configurationsForm.formStates.Cancelled;
  }

  openLocationDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
  }

  LocationDeleteConfirm() {
    //alert(this.fgDeviceLocation.value.uuid)
    console.log('deleteConfirmed');

    if (this.DeviceScheduleUuid) {

      return this.deviceSchedulesService.deleteById(this.DeviceScheduleUuid)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {

            this.toastrService.success('Success', 'Configuration Deleted', { timeOut: 2000, tapToDismiss: true });
            this.modalRef.hide();
            this.router.navigate(['/configurations/all']);
          },
          error => {
            this.toastrService.error("Server Error (ConfigurationDeleteConfirm)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
            this.modalRef.hide();
          });
    }
    this.modalRef.hide();

  }

  LocationDeleteCancel(): void {
    this.modalRef.hide();
  }



}