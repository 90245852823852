import { Component, EventEmitter, OnInit, AfterViewInit, Input, OnDestroy, Output, ViewChild, getModuleFactory } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConfigMapsService } from '../../../../core/api/api.services';
import { SettingsService } from '../../../../core/settings/settings.service';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { ModelsDto } from '../../../../models/models';

@Component({
	selector: 'app-configOptionControl-component',
	templateUrl: './configOptionControl.component.html',
	styleUrls: ['./configOptionControl.component.scss']
})


export class ConfigOptionControlComponent implements OnInit, AfterViewInit, OnDestroy {
	ngOnInit() { }

	private onDestroy$: Subject<void> = new Subject<void>();
	public configOptions: any[] = [];
	public configOptionsSelected: any[] = [];
	public availableConfigMap: any[] = [];

	private _isNewModel: boolean = false;
	@Input() modelView: boolean = false;
	@Input() deviceView: boolean = false;
	@Input() device: any;

	@Input() set isModelNew(isNewModel: any) {
		this._isNewModel = isNewModel;
	}

	get isModelNew(): any {
		return this._isNewModel;
	}

	private _model: any = null;

	@Input() set model(newModel: any) {
		this._model = newModel;
		if (newModel[0] != null) {
			this.refreshConfigOptionsSelected(this._model);
		}
	}

	get model(): any {
		return this._model;
	}


	private _isEditMode: boolean = false;
	get IsEditMode(): boolean {
		return this._isEditMode;
	}
	@Input() set IsEditMode(newIsEditMode: boolean) {
		this._isEditMode = newIsEditMode;
	}



	constructor(

		public configMapsService: ConfigMapsService,
		public settingsService: SettingsService,
		public toastrService: ToastrService,
		public errorsService: ErrorsService,

	) { }


	ngAfterViewInit() {

		this.getConfigMaps();

	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
	}

	getConfigMaps() {
		return this.configMapsService.read()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(result: any) => {
					this.availableConfigMap = result;
					this.getConfigOptions(result[0].id);


				},
				error => {
					this.toastrService.error("Server Error (getFirmwares)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
				});

	}


	getConfigOptions(configMapId: number): any {
		this.configOptions = [];
		this.configOptionsSelected = [];

		if (!this.settingsService.client || configMapId == null) return;

		return this.configMapsService.readConfigOptions(configMapId)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(result: any) => {
					this.configOptions = result;
					this.configOptions.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1)
					this.refreshConfigOptionsSelected(this.device);
				},
				error => {
					this.toastrService.error("Server Error (Getting Config Options)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
				});
	}


	configOptionAdd(id: number) {
		if (this.modelView == true) {
			let option = this.configOptions.find(x => x.id == id);
			if (option) this.configOptionsSelected.push(option);
			if (this.model.modelOptionValues) {
				if (!(this.model.modelOptionValues.find(v => v.optionId == option.id))) {
					this.model.modelOptionValues.push(
						{
							id: option.configOptionValues[0].id,
							code: option.configOptionValues[0].code,
							description: option.description,
							optionId: option.id,
							optionCode: option.code,
							optionValueText: option.configOptionValues[0].code,
							required: option.required,
							sequence: option.sequence,
							typeId: option.typeId,
							value: null
						}
					)
					console.log(this.model);

				}

			}

			else if (!(this.model[0].modelOptionValues.find(v => v.optionId == option.id))) {
				this.model[0].modelOptionValues.push(
					{
						id: option.configOptionValues.id,
						code: option.configOptionValues.code,
						description: option.description,
						optionId: option.id,
						optionCode: option.code,
						optionValueText: option.configOptionValues.code,
						required: option.required,
						sequence: option.sequence,
						typeId: option.typeId,
						value: null
					}
				)
				console.log(this.model);
			}
		}
		else if (this.deviceView == true) {
			let option = this.configOptions.find(x => x.id == id);
			if (option) this.configOptionsSelected.push(option);

			if (!(this.device.configOptionValues.find(v => v.optionId == option.id))) {
				this.device.configOptionValues.push(
					{
						id: option.configOptionValues[0].id,
						code: option.configOptionValues[0].code,
						description: option.description,
						optionId: option.id,
						optionCode: option.code,
						optionValueText: option.configOptionValues[0].code,
						required: option.required,
						sequence: option.sequence,
						typeId: option.typeId,
						value: null
					}
				)
			}

		}

	}


	configOptionRemove(id: number) {
		if (this.modelView == true) {
			let option = this.configOptionsSelected.find(x => x.id == id);
			let indexSelected = this.configOptionsSelected.findIndex(x => x.id == id);
			if (indexSelected > -1) this.configOptionsSelected.splice(indexSelected, 1);

			if (this.model[0]) {
				let indexOptionValue = this.model[0].modelOptionValues.findIndex(v => v.optionId == option.id);
				if (indexOptionValue > -1) this.model[0].modelOptionValues.splice(indexOptionValue, 1);
			}
			else {
				let indexOptionValue = this.model.modelOptionValues.findIndex(v => v.optionId == option.id);
				if (indexOptionValue > -1) this.model.modelOptionValues.splice(indexOptionValue, 1);
			}

		}

		if (this.deviceView == true) {
			let option = this.configOptionsSelected.find(x => x.id == id);
			let indexSelected = this.configOptionsSelected.findIndex(x => x.id == id);
			if (indexSelected > -1) this.configOptionsSelected.splice(indexSelected, 1);

			let indexOptionValue = this.device.configOptionValues.findIndex(v => v.optionId == option.id);
			if (indexOptionValue > -1) this.device.configOptionValues.splice(indexOptionValue, 1);

		}

	}

	configOptionDropDownSelect(optionObject: any) {
		if (this.modelView == true) {
			if (this.model.modelOptionValues) {
				if (!(optionObject.optionId && optionObject.id && optionObject.code && optionObject.valueText)) return;

				let optionValue = this.model.modelOptionValues.find(v => v.optionId == optionObject.optionId);
				optionValue.id = optionObject.id;
				optionValue.code = optionObject.valueText;
				optionValue.optionValueText = optionObject.valueText;
			}

			else {
				if (!(optionObject.optionId && optionObject.id && optionObject.code && optionObject.valueText)) return;

				let optionValue = this.model[0].modelOptionValues.find(v => v.optionId == optionObject.optionId);
				optionValue.id = optionObject.id;
				optionValue.code = optionObject.valueText;
				optionValue.optionValueText = optionObject.valueText;
			}
		}

		if (this.deviceView == true) {
			if (!(optionObject.optionId && optionObject.id && optionObject.code && optionObject.valueText)) return;

			let optionValue = this.device.configOptionValues.find(v => v.optionId == optionObject.optionId);
			optionValue.id = optionObject.id;
			optionValue.code = optionObject.valueText;
			optionValue.optionValueText = optionObject.valueText;
		}
	}

	refreshConfigOptionsSelected(optionObj) {
		this.configOptionsSelected = [];
		if (this.modelView == true) {
			if (this.model[0] == null && this.IsEditMode == true) {

				this.model = new ModelsDto();
				return;
			}
			else if (this.model[0] == null && this.IsEditMode == false) {
				return;
			}
			if (this.model && this.model[0].modelOptionValues && this.model[0].modelOptionValues.length > 0) {
				for (let entry of this.configOptions) {
					let option = this.model[0].modelOptionValues.find(opt => opt.optionId == entry.id);
					let selected = this.configOptionsSelected.find(sel => sel.id == entry.id);
					if (option && !selected) {
						this.configOptionsSelected.push(entry)
					}
				}
			}
			if (this.configOptionsSelected.length > 0) this.configOptionsSelected.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1)
		}
		if (this.deviceView == true) {
			this.device = optionObj;
			if (this.device && this.device.configOptionValues && this.device.configOptionValues.length > 0) {
				for (let entry of this.configOptions) {
					let option = this.device.configOptionValues.find(opt => opt.optionId == entry.id);
					let selected = this.configOptionsSelected.find(sel => sel.id == entry.id);
					if (option && !selected) {
						this.configOptionsSelected.push(entry)
					}
				}
			}
			if (this.configOptionsSelected.length > 0) this.configOptionsSelected.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1)
		}

	}


	isSelctedConfigOption(argConfigOptionId: number): boolean {
		let selected = this.configOptionsSelected.find(x => x.id == argConfigOptionId)
		if (selected) return true;
		return false;
	}

	getConfigOptionValueText(argConfigOptionId: number): string {
		if (this.deviceView) {
			if (this.device) {
				let deviceOption = this.device.configOptionValues.find(opt => opt.optionId == argConfigOptionId);
				if (deviceOption) return deviceOption.optionValueText;
			}
			else{
				return;
			}
		}
		else {
			if (this.model[0]) {
				let modelOption = this.model[0].modelOptionValues.find(opt => opt.optionId == argConfigOptionId);
				if (modelOption) return modelOption.optionValueText;
			}
			else if (this.model) {
				let modelOption = this.model.modelOptionValues.find(opt => opt.optionId == argConfigOptionId);
				if (modelOption) return modelOption.optionValueText;
			}
			else {
				return;
			}
		}

		// 	let modelOption = this.model.modelOptionValues.find(opt => opt.optionId == argConfigOptionId);
		// 	if (modelOption) return modelOption.optionValueText;
		// 	return "";

		// }

		// return "";
	}



	controlValidationClass(control) {
		return "form-control" + (this.controlInvalidFlag(control) != null ? (this.controlInvalidFlag(control) ? " is-invalid" : " is-valid") : "")
	}
	controlInvalidFlag(control) {
		if (!(control.touched)) return null;
		return control.invalid
	}
}
