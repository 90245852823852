import { Component, ElementRef, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Location, DatePipe } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { EventsService } from '../../core/events/events.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsService, userTypes, userRoles } from '../../core/settings/settings.service';
import { LibrariesService } from '../../core/libraries/libraries.service';
import { DeviceDailySummariesService, DeviceLocationsService, DeviceLocationSettingsService, CountriesService, BearingsService, DevicesService } from '../../core/api/api.services';
import { ErrorsService } from '../../core/errors/errors.service';
import * as _ from "lodash";
import { Subscription, Subject } from 'rxjs';
import { takeUntil, filter, distinctUntilChanged } from 'rxjs/operators';
import { DevicesViewComponent } from '../../shared/component/views/devicesview/devicesview.component';
import moment from 'moment';

export enum DeviceLocationsFormState {
  Initializing = 1,
  List = 2,
  Read = 3,
  New = 4,
  Edit = 5,
  Save = 6,
  Saving = 7,
  Saved = 8,
  Cancel = 9,
  Cancelled = 10,
}
@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChartsComponent implements OnInit, OnDestroy {
  @Output() onFormEventDevice: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeviceLocationSelected: EventEmitter<any> = new EventEmitter()
  @Output() onButtonClick: EventEmitter<any> = new EventEmitter()
  @ViewChild(DevicesViewComponent) devicesView: DevicesViewComponent;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  private firstLoad: boolean = true;
  public datePipe: DatePipe = new DatePipe('en-US');

  public get dataLoading(): boolean {
    if (!this.devicesView) return false;
    return this.devicesView.dataLoading;
  }

  constructor(
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    public devicesService: DevicesService,
    public deviceDailySummariesService: DeviceDailySummariesService,
    public deviceLocationsService: DeviceLocationsService,
    public DeviceLocationSettingsService: DeviceLocationSettingsService,
    public bearingsService: BearingsService,
    public countriesService: CountriesService,

    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private formBuilder: UntypedFormBuilder
  ) {

  }

  private paramSub: any;

  private _deviceLocationUuid: string = "";
  get deviceLocationUuid(): string {
    return this._deviceLocationUuid;
  }
  set deviceLocationUuid(newDeviceLocationUuid: string) {
    if (this.librariesService.guidValidate(newDeviceLocationUuid)) {
      this._deviceLocationUuid = newDeviceLocationUuid;
      // this.getDeviceLocation()
    }
    else {
      this._deviceLocationUuid = "";
      this.deviceLocation = null;

    }
  }

  get currentDate(): Date {
    return new Date();
  }

  get isEditMode(): boolean {
    // if (this.formState == DeviceWifiFormState.New) return true
    // if (this.formState == DeviceWifiFormState.Edit) return true

    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving:
      case this.formStates.Saved:
        {
          return true
        }
      default:
        {
          return false
        }
    }
  }
  get isMapEnabled(): boolean {
    // if (this.formState == DeviceWifiFormState.New) return true
    // if (this.formState == DeviceWifiFormState.Edit) return true

    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
        {
          return true
        }
      default:
        {
          return false
        }
    }
  }

  public formEnabled = false;
  userTypes = userTypes;
  userRoles = userRoles;
  public formStates = DeviceLocationsFormState
  private _formState: DeviceLocationsFormState = DeviceLocationsFormState.Initializing
  get formState(): DeviceLocationsFormState {
    return this._formState
  }
  @Input() set formState(newFormState: DeviceLocationsFormState) {

    // var updateFormState: DeviceLocationsFormState = null
    // this.fgDeviceLocation.disable()
    // this.fgDeviceLocationSetting.disable()

    // switch (newFormState) {
    //   case this.formStates.Read: {
    //     updateFormState = newFormState
    //     this.deviceLocationSelect()
    //     break
    //   }
    //   case this.formStates.New: {
    //     this.fgDeviceLocation.enable()
    //     this.fgDeviceLocationSetting.enable()
    //     this.getPrevLocsAll()
    //     updateFormState = newFormState
    //     break
    //   }
    //   case this.formStates.Edit: {
    //     updateFormState = newFormState
    //     this.fgDeviceLocation.enable()
    //     this.fgDeviceLocationSetting.enable()
    //     this.deviceLocationSettingsEdit()
    //     break
    //   }
    //   case this.formStates.Save: {
    //     //presetting to address form state sequencing durring validation
    //     this.fgDeviceLocation.enable()
    //     this.fgDeviceLocationSetting.enable()
    //     this._formState = newFormState
    //     this.deviceLocationSave()
    //     //this.deviceLocationSettingsSave()
    //     break
    //   }
    //   case this.formStates.Saving: {
    //     this.fgDeviceLocation.enable()
    //     this.fgDeviceLocationSetting.enable()
    //     break
    //   }
    //   case this.formStates.Cancel: {
    //     this.deviceLocationSettingsCancel()
    //     updateFormState = this.formStates.Read
    //     break
    //   }
    //   case this.formStates.Cancelled: {
    //     this.deviceLocationSettingsBack()
    //     this.formState = this.formStates.List
    //     break
    //   }
    //   default:
    //     {
    //       updateFormState = newFormState
    //     }
    // }

    // if (updateFormState != null) this._formState = updateFormState
  }

  public fgCharts: UntypedFormGroup = this.formBuilder.group({
    deviceListingTypeId: "1"
  });

  public fgChartTypes: UntypedFormGroup = this.formBuilder.group({
    chartTypeId: "0"
  });


  ngOnInit() {

    this.paramSub = this.route.params
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(params => {
        this.deviceLocationUuid = this.librariesService.guidValidate(params['uuid']) ? params['uuid'] : ""
      });

    this.settingsService.userAccessCheck();

    if (this.router.url.toLowerCase().split("?")[0] == '/charts') {
      this.dataRefresh();
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log(this.route.routeConfig.component.name + ":NavigationEnd", this.router.url)
        if (this.router.url.toLowerCase().split("?")[0] == '/charts') {
          console.log(this.route.routeConfig.component.name + ":NavigationEnd")

        }
      }
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }


  ngAfterViewInit() {
    this.formValueChangesSetup()

    var self = this
    setTimeout(function () {
      self.getDeviceLocation()
    }, 500);
  }

  formValueChangesSetup() {
    var self = this
    this.fgCharts.get('deviceListingTypeId').valueChanges.pipe(distinctUntilChanged())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(val => {
        if (val.toString() == "0") {

          // setTimeout(function () {
          //   self.mapResize();
          // }, 500);
        }

      });
  }

  dataRefresh() {
    var self = this;
    if (!this.settingsService.client ||
      !this.devicesView) {
      setTimeout(() => {
        self.dataRefresh();
      }, 100);
    }

  }

  buttonRefresh() {
    this.devicesView.dataRefresh();
  }

  buttonListing(event) {
    // this.router.navigate(['/devices/wifi/all'])
    this.formState = this.formStates.List;
    this.router.navigate(['/charts']); // , { queryParams: { view: this.viewCurrent.toLocaleLowerCase() } });
  }

  onDataLoading(event) {
    if (this.dataLoading && this.firstLoad) this.settingsService.showSpinner("devices", false, 250);
    this.firstLoad = false;

    if (!this.dataLoading) this.settingsService.hideSpinner("devices");
  }

  public device: any;
  getDevice(): any {
    this.device = null;
    let now = moment();

    if (!this.settingsService.client || !this.deviceLocation) return;

    return this.deviceLocationsService.readDeviceByLocationUuid(this.deviceLocationUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: any) => {
        this.device = result;
        if (this.device.lastActivity != null && (moment(this.device.lastActivity).diff(now, 'minutes') >= -30)) {
          this.device.nextActivity = moment(this.device.lastActivity || '').add(15, 'm').toDate();
        }
      },
        error => {
          this.toastrService.error('Server Error (getDevice)',
            this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });
  }

  public deviceLocation: any;
  getDeviceLocation(): any {

    this.device = null
    this.deviceLocation = null
    if (this.deviceLocationUuid == "" || !this.settingsService.client) return;

    return this.deviceLocationsService.readByUuid(this.deviceLocationUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.deviceLocation = result;
          this.getDevice();
          console.log(this.deviceLocation)
        },
        error => {
          this.toastrService.error("Server Error (getDeviceLocation)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });
  }

  getDeviceLocationAddressDisplay(): string {
    if (!this.deviceLocation) return ''

    var display: string = this.deviceLocation.address || ''

    if (display.length > 0 && this.deviceLocation.city && this.deviceLocation.city.length > 0) display = display + ', '
    display = display + (this.deviceLocation.city || '')

    if (display.length > 0 && this.deviceLocation.stateProvinceCode && this.deviceLocation.stateProvinceCode.length > 0) display = display + ', '
    display = display + (this.deviceLocation.stateProvinceCode || '')

    if (display.length > 0 && this.deviceLocation.postalCode && this.deviceLocation.postalCode.length > 0) display = display + ', '
    display = display + (this.deviceLocation.postalCode || '')

    if (display.length > 0 && this.deviceLocation.country && this.deviceLocation.country.length > 0) display = display + ', '
    display = display + (this.deviceLocation.country || '')

    return display
  }

  requestRealTimeData() {
    if (this.device == null) return;
    let now = moment();

    console.log('Realtime Data Requested')

    return this.devicesService.queueRealtimeData(this.device.uuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.device.dataRequestRealTime = true;
          this.toastrService.success('Success', 'Data Download Requested', { timeOut: 2000, tapToDismiss: true });
          if (this.device.lastActivity != null && (moment(this.device.lastActivity).diff(now, 'minutes') >= -30)) {
            this.device.nextActivity = moment(this.device.lastActivity || '').add(15, 'm').toDate();
          }
        },
        error => {
          this.settingsService.hideSpinner(undefined);
          this.toastrService.error('Server Error (deviceRequest: queueRealtimeData)',
            this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });
  }


  onDeviceLocationSelect(event) {
    console.log("onDeviceLocationSelect", event)
    if (event) {
      var uuidLoc = ""

      //REMOVED to relax on the point tests
      // if (event.uuid && event.firmwareUuidTarget && event.modelId &&
      //   event.deviceLocation && event.deviceLocation.latitude && event.deviceLocation.longitude &&
      //   this.librariesService.guidValidate(event.deviceLocation.uuid)) {
      //   uuidLoc = event.deviceLocation.uuid.toString()
      // }
      // else if (event.uuid && event.bearingId &&
      //   event.latitude && event.longitude &&
      //   this.librariesService.guidValidate(event.uuid)) {
      //   uuidLoc = event.uuid.toString()
      // }

      if (event.uuid &&
        event.deviceLocation &&
        this.librariesService.guidValidate(event.deviceLocation.uuid)) {
        uuidLoc = event.deviceLocation.uuid.toString()
      }
      else if (event.uuid &&
        event.deviceLocation && event.deviceLocation.latitude && event.deviceLocation.longitude &&
        this.librariesService.guidValidate(event.deviceLocation.uuid)) {
        uuidLoc = event.deviceLocation.uuid.toString()
      }
      else if (event.uuid &&
        event.latitude && event.longitude &&
        this.librariesService.guidValidate(event.uuid)) {
        uuidLoc = event.uuid.toString()
      }

      if (uuidLoc != "")
        this.router.navigate(['/charts', uuidLoc], { replaceUrl: false })

      //var url = this.router.createUrlTree(['.',event.toString()], {relativeTo: this.route})
      //this.location.go(url.toString());
      //this.router.navigateByUrl('/devices/wifi/'+event.toString())


    }
  }


}
