import { Component, EventEmitter, OnInit, Input, Output, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { DeviceSchedulesService, ClientsService } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
import { ToastrService } from 'ngx-toastr';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { DeviceScheduleConfigurationsDto, DeviceScheduleConfigurationEventsDto, DeviceScheduleDto } from '../../../../models/deviceScheduleObjects';
import { ConfigMapOptionsQueryDto } from '../../../../models/configmap';
import { ColorsService } from '../../../../core/colors/colors.service';
// import { DatatableComponent } from '@swimlane/ngx-datatable';
import { DaySchedulesTimeFormComponent } from '../dayschedulestime/dayschedulestime-form.component';
import * as _ from "lodash";
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../../../../../environments/environment';


export enum DailyEventScheduleFormState {
  Initializing = 1,
  Read = 2,
  New = 3,
  Edit = 4,
  Save = 5,
  Saving = 6,
  Saved = 7,
  Cancelled = 8,
}

@Component({
  selector: 'app-dayschedulesschedule-form',
  templateUrl: './dayschedulesschedule-form.component.html',
  styleUrls: ['./dayschedulesschedule-form.component.scss']
})

export class DaySchedulesScheduleFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('eventScheduleTimeForm') public eventScheduleTimeForm: DaySchedulesTimeFormComponent;
  // @ViewChild('dailyEventScheduleTimesTable') public dataTable: DatatableComponent;
  @Output() onFormEvent_EventSchedule: EventEmitter<any> = new EventEmitter<any>();

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public environment = environment;

  public formStates = DailyEventScheduleFormState;
  private _formState: DailyEventScheduleFormState = DailyEventScheduleFormState.Initializing;
  get formState(): DailyEventScheduleFormState {
    return this._formState;
  }
  @Input() set formState(newFormState: DailyEventScheduleFormState) {
    let updateFormState: DailyEventScheduleFormState = null;

    this.fgDailyEventSchedule.disable();
    
    switch (newFormState) {

      case this.formStates.Read: {
        updateFormState = newFormState;
        this.fgDailyEventSchedule.disable();
        this.eventScheduleTimeRead();
        break;
      }
      case this.formStates.New: {
        updateFormState = newFormState;
        this.fgDailyEventSchedule.enable();
        break;
      }
      case this.formStates.Edit: {
        updateFormState = newFormState;
        this.fgDailyEventSchedule.enable();
        this.eventScheduleTimeForm.formState = this.eventScheduleTimeForm.formStates.Edit;
        this.eventScheduleTimeEdit();
        break;
      }
      case this.formStates.Save: {
        this._formState = newFormState;
        this.fgDailyEventSchedule.enable();
        this.eventScheduleTimeSave();
        break;
      }
      case this.formStates.Cancelled: {
        this.eventScheduleTimeCancel();
        break;
      }
      default: {
        updateFormState = newFormState;
      }
    }

    if (updateFormState != null) {
      this._formState = updateFormState;
    }
  }

  private _deviceSchedule: DeviceScheduleDto;
  get DeviceSchedule(): DeviceScheduleDto {
    return this._deviceSchedule;
  }
  @Input() set DeviceSchedule(newDeviceSchedule: DeviceScheduleDto) {
    this._deviceSchedule = newDeviceSchedule;
  }

  private _configMapOptions: ConfigMapOptionsQueryDto[];
  get ConfigMapOptions(): ConfigMapOptionsQueryDto[] {
    return this._configMapOptions;
  }
  @Input() set ConfigMapOptions(newConfigMapOptions: ConfigMapOptionsQueryDto[]) {
    this._configMapOptions = newConfigMapOptions;
  }

  public deviceScheduleConfigurationEventsTemp: DeviceScheduleConfigurationsDto[] = [];
  public deviceScheduleConfigurationEventsSelected: DeviceScheduleConfigurationsDto[] = [];

  private _dailyEventSchedule: DeviceScheduleConfigurationsDto;
  get DailyEventSchedule(): DeviceScheduleConfigurationsDto {
    return this._dailyEventSchedule;
  }
  @Input() set DailyEventSchedule(newDailyEventSchedule: DeviceScheduleConfigurationsDto) {

    this._dailyEventSchedule = _.cloneDeep(newDailyEventSchedule);

    this.formState = this.formStates.Initializing;

    if (newDailyEventSchedule) {
      this.fgDailyEventSchedule.patchValue({
        sequence: this.DailyEventSchedule.sequence,
        colorCode: this.DailyEventSchedule.colorCode,
        name: this.DailyEventSchedule.name,
        description: this.DailyEventSchedule.description
      });

      if (newDailyEventSchedule.sequence === 0) {
        this.formState = this.formStates.New;
      }
      //else this.formState = this.formStates.Edit;

    }

    this.showDailyEventScheduleForm = true;
  }

  get isEditMode(): boolean {
    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving:
      case this.formStates.Saved:
        {
          return true;
        }
      default:
        {
          return false;
        }
    }
  }

  public isModified = false;
  public showDailyEventScheduleTimeForm: boolean = false;
  public showDailyEventScheduleForm: boolean = true;
  constructor(
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public deviceSchedulesService: DeviceSchedulesService,
    public clientsService: ClientsService,
    public errorsService: ErrorsService,
    public librariesService: LibrariesService,
    public colorsService: ColorsService,
    private formBuilder: UntypedFormBuilder
  ) { }


  public fgDailyEventSchedule: UntypedFormGroup = this.formBuilder.group({
    sequence: 0,
    colorCode: '',
    name: ['', [Validators.required, Validators.minLength(1)]],
    description: '',
  });

  ngOnInit() {

    // this.eventsService.currentMessage.pipe(filter(message => message.toString() !== ''))
    //   .pipe(takeUntil(this.onDestroy$))
    //   .subscribe(
    //     message => {
    //       this.formState = DailyEventScheduleFormState.Initializing;
    //     }
    //   );

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {

  }

  getDailyEventSchedule(): any {
    //this._dailyEventSchedule = null;

    if (!this.settingsService.client || !this.DailyEventSchedule) {
      return;
    }

    //do something, maybe...

  }

  controlValidationClass(control) {
    return 'form-control'
      + (this.controlInvalidFlag(control) != null ? (this.controlInvalidFlag(control) ? ' is-invalid' : ' is-valid') : '');
  }

  controlInvalidFlag(control) {
    if (!control.touched) {
      return null;
    }
    return control.invalid;
  }

  eventScheduleTimeRead() {
    if (this.eventScheduleTimeForm) this.eventScheduleTimeForm.formState = this.eventScheduleTimeForm.formStates.Read
  }

  eventScheduleTimeEdit() {
    this.isModified = false;
    //this.onFormEvent_EventSchedule.emit('edit');
  }

  eventScheduleTimeSave() {
    this.librariesService.validateFormGroup(this.fgDailyEventSchedule);
    if (!this.fgDailyEventSchedule.valid) {
      this.formState = this.DailyEventSchedule.sequence === 0 ? this.formStates.New : this.formStates.Edit;
      this.toastrService.warning('Validation Warning (Daily Event Schedule)',
        'Verify Required Data', { timeOut: 5000, tapToDismiss: true });
      return;
    }

    this.formState = this.formStates.Saving;
    this.onFormEvent_EventSchedule.emit('saving');

    if (this.DailyEventSchedule.sequence === 0) {

      //create new object based on model, should go at end of sequence
      // this.dailyEventSchedule = result.uuid;
      // this.formState = this.formStates.Saved;
      // this.onFormEvent.emit('saved');

    } else {

      this.DailyEventSchedule.colorCode = this.colorsService.palette24[this.DailyEventSchedule.sequence - 1];
      this.DailyEventSchedule.name = this.fgDailyEventSchedule.value.name;
      this.DailyEventSchedule.description = this.fgDailyEventSchedule.value.description;

    }
  }

  onEventScheduleTimeEmitter(event) {

    switch (event.toString().toLowerCase()) {
      case 'add': {
        var dest = this.eventScheduleTimeForm.DailyEventScheduleTime;

        var elementPos = this.DailyEventSchedule.deviceScheduleConfigurationEvents.findIndex(
          function (e: DeviceScheduleConfigurationEventsDto): boolean {
            if (dest.time < e.time) return true;
            return false;
          }
        )
        if (elementPos >= 0) {
          alert(this.eventScheduleTimeForm.DailyEventScheduleTime.time)
        }

        alert('schedules add');
        break;
      }
      case 'edit': {
        //alert('schedules edit');
        break;
      }
      case 'ok': {

        var dest = this.eventScheduleTimeForm.DailyEventScheduleTime;

        var elementPos = this.DailyEventSchedule.deviceScheduleConfigurationEvents.findIndex(
          function (e: DeviceScheduleConfigurationEventsDto): boolean {
            if (dest.time == e.time) return true;
            return false;
          }
        )
        if (elementPos < 0) {
          this.DailyEventSchedule.deviceScheduleConfigurationEvents.push(dest)
        }

        this.DailyEventSchedule.deviceScheduleConfigurationEvents.sort((a, b) =>
          (a.time > b.time) ? 1 : ((b.time > a.time) ? -1 : 0));

        this.showDailyEventScheduleTimeForm = false;
        this.showDailyEventScheduleForm = true;

        // setTimeout(() => {
        //   this.dataTable.rows = this.DailyEventSchedule.deviceScheduleConfigurationEvents;
        // }, 100);

        break;
      }
      case 'cancel': {
        this.showDailyEventScheduleTimeForm = false;
        this.showDailyEventScheduleForm = true;
        break;
      }
      default: {
        //statements; 
        break;
      }
    }

  }

  dailyEventTimeFormat(time: string) {
    //return this.librariesService.datePipe.transform(new Date('2000-01-01' + 'T' + time + '+00:00'), 'h:mm a',"+00:00");
    return this.librariesService.datePipe.transform(
      new Date(this.librariesService.datePipe.transform(
        new Date(), 'yyyy-MM-dd', this.librariesService.getUserTimezoneOffsetString()) + 'T' + time + this.librariesService.getUserTimezoneOffsetString()), 'h:mm a');
  }

  eventScheduleTimeCancel() {
    this.formState = this.formStates.Read;
    //this.getDailyEventSchedule();
    //this.onFormEvent_EventSchedule.emit('cancelled');
  }

  onSelect({ selected }) {
    if (!this.isButtonDelete) {
      if (selected) {
        this.deviceScheduleConfigurationEventsSelected.splice(0, this.deviceScheduleConfigurationEventsSelected.length);

        this.showDailyEventScheduleTimeForm = true;
        this.showDailyEventScheduleForm = false;

        this.eventScheduleTimeForm.DailyEventScheduleTime = selected[0];

        if (this.formState == this.formStates.New || this.formState == this.formStates.Edit) {
          this.eventScheduleTimeForm.formState = this.eventScheduleTimeForm.formStates.Edit
        }
        else {
          console.log('this.eventScheduleTimeForm.formStates.Read', this.eventScheduleTimeForm.DailyEventScheduleTime)
          this.eventScheduleTimeForm.formState = this.eventScheduleTimeForm.formStates.Read
        }

        this.onFormEvent_EventSchedule.emit('edit');


      }
    }
    this.isButtonDelete = false;
  }

  buttonAdd(event) {
    //if (!this.fgDailyEventSchedules.valid) return;
    this.showDailyEventScheduleTimeForm = true;
    this.showDailyEventScheduleForm = false;

    let newEventScheduleTime: DeviceScheduleConfigurationEventsDto = new DeviceScheduleConfigurationEventsDto();

    let maxTime: string = "00:00"
    let i = 0;
    
    for (i = 0; i < this.DailyEventSchedule.deviceScheduleConfigurationEvents.length; i++) {
      if (this.DailyEventSchedule.deviceScheduleConfigurationEvents[i].time > maxTime) {
        maxTime = this.DailyEventSchedule.deviceScheduleConfigurationEvents[i].time
      }
    }
    
    let maxHours: number = +(maxTime.split(":")[0]);
    let maxMinutes: number = +(maxTime.split(":")[1]);

    if (maxTime > "23:50") {
      newEventScheduleTime.time = maxTime;
    }
    else {
      let today = new Date();
      let newTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), maxHours, maxMinutes, 0, 0);
      newTime = new Date(newTime.getTime() + 10 * 60000);
      newEventScheduleTime.time = this.librariesService.datePipe.transform(newTime, 'HH:mm')
    }

    this.eventScheduleTimeForm.DailyEventScheduleTime = newEventScheduleTime;
    this.eventScheduleTimeForm.formState = this.eventScheduleTimeForm.formStates.New;
    this.onFormEvent_EventSchedule.emit('add');
  }

  buttonEdit(event) {
    this.showDailyEventScheduleTimeForm = true;
    this.showDailyEventScheduleForm = false;

    this.eventScheduleTimeForm.DailyEventScheduleTime = event;
    this.onFormEvent_EventSchedule.emit('edit');
  }

  private isButtonDelete: boolean = false;

  buttonDelete(event) {
    this.isButtonDelete = true;

    this.showDailyEventScheduleTimeForm = false;
    this.showDailyEventScheduleForm = true;

    var obj: DeviceScheduleConfigurationEventsDto = event;

    var elementPos = this.DailyEventSchedule.deviceScheduleConfigurationEvents.map(function (x) { return x.time; }).indexOf(obj.time);
    if (elementPos < 0) return;

    this.DailyEventSchedule.deviceScheduleConfigurationEvents.splice(elementPos, 1);
    //this.librariesService.arraySequenceReNumber(this.DailyEventSchedule.deviceScheduleConfigurationEvents);
    //this.librariesService.arrayColorCodeApply(this.DailyEventSchedule.deviceScheduleConfigurationEvents);
    // this.dataTable.rows = this.DailyEventSchedule.deviceScheduleConfigurationEvents;

    this.onFormEvent_EventSchedule.emit('delete');
  }

  buttonOk(event) {
    this.librariesService.validateFormGroup(this.fgDailyEventSchedule);
    if (!this.fgDailyEventSchedule.valid) return;

    this.DailyEventSchedule.sequence = this.fgDailyEventSchedule.value.sequence,
      this.DailyEventSchedule.colorCode = this.fgDailyEventSchedule.value.colorCode,
      this.DailyEventSchedule.name = this.fgDailyEventSchedule.value.name,
      this.DailyEventSchedule.description = this.fgDailyEventSchedule.value.description,

      //this.showDailyEventScheduleTimeForm = false;
      //apply update
      //this.formState = this.formStates.Read;
      this.showDailyEventScheduleForm = true;
    this.onFormEvent_EventSchedule.emit('ok');
  }

  buttonCancel(event) {
    //this.showDailyEventScheduleTimeForm = false;
    //apply update
    //this.formState = this.formStates.Read;
    this.showDailyEventScheduleForm = true;
    this.onFormEvent_EventSchedule.emit('cancel');
  }


}
