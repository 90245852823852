import { Component, ElementRef, EventEmitter, ViewEncapsulation, OnInit, AfterViewInit, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SettingsService, userTypes, userRoles } from '../../../../../core/settings/settings.service';
import { ModemsService } from '../../../../../core/api/api.services';
import { EventsService } from '../../../../../core/events/events.service';
import { ToastrService } from 'ngx-toastr';
import { LibrariesService } from '../../../../../core/libraries/libraries.service';
import { PasswordValidator } from '../../../../../core/validators/password.validator';
import { ErrorsService } from '../../../../../core/errors/errors.service';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

export enum ModemFormState {
  Initializing = 1,
  Read = 2,
  New = 3,
  Edit = 4,
  Save = 5,
  Saving = 6,
  Saved = 7,
  Cancelled = 8
}

@Component({
  selector: 'app-modem-form',
  templateUrl: './modem-form.component.html',
  styleUrls: ['./modem-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ModemFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onFormEvent: EventEmitter<any> = new EventEmitter<any>();

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  get formTitle() {
    if (this.modemId && this.modemId != -1) {
      return 'Edit Modem'
    } else {
      return 'Add Modem'
    }
  }

  public formLoaded: boolean = false;
  public formStates = ModemFormState;
  private _formState: ModemFormState = this.formStates.Initializing;
  get formState(): ModemFormState {
    return this._formState
  }
  @Input() set formState(newFormState: ModemFormState) {
    var updateFormState: ModemFormState = null
    this.fgModem.disable()
    
    switch (newFormState) {
      case this.formStates.Read: {
        updateFormState = newFormState
        this.formRead()
        break
      }
      case this.formStates.New: {
        updateFormState = newFormState
        this.fgModem.enable()
        this.formAdd()
        break
      }
      case this.formStates.Edit: {
        updateFormState = newFormState
        this.fgModem.enable()
        this.formEdit()
        break
      }
      case this.formStates.Save: {
        this._formState = newFormState
        this.fgModem.enable()
        this.formSave()
        break
      }
      case this.formStates.Saved: {
        this._formState = newFormState
        break
      }
      case this.formStates.Cancelled: {
        this.formCancel()
        break
      }
      default: {
          updateFormState = newFormState
      }
    }

    if (updateFormState != null) {
      this._formState = updateFormState
    }
  }

  private _modemId: number = -1;
  get modemId(): number {
    return this._modemId;
  }
  @Input() set modemId(newModemId: number) {
    this._modemId = newModemId;

    if (this.formLoaded && newModemId != -1) 
      this.getModem()
      
    if (newModemId == -1) 
      this.formState = this.formStates.New
  }

  private modem: any = null;

  get isEditMode(): boolean {
    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving:
      case this.formStates.Saved:
        {
          return true
        }
      default:
        {
          return false
        }
    }
  }

  public isModified = false;

  constructor(
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public errorsService: ErrorsService,
    public librariesService: LibrariesService,
    public modemsService: ModemsService,
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef
  ) { }

  public fgModem: UntypedFormGroup = this.formBuilder.group({
    id: '',
    name: ['', [Validators.required]],
    description: ''
  });

  ngOnInit() {
    
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    let self = this;
    
    setTimeout(() => {
      if (self.modem == null) 
        self.getModem()


      self.formLoaded = true
    }, 500);
  }

  clearModem() {
    this.modem = null;
    this.fgModem.patchValue(
      {
        id: '',
        name: '',
        description: ''
      }
    )
  }

  getModem(): any {
    if (!this.modemId || this.modemId == -1) return;

    return this.modemsService.readById(this.modemId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.fgModem.patchValue(
            {
              id: result.id,
              name: result.name,
              description: result.description
            }
          )

          this.formState = this.formStates.Read;
          this.modem = result;
        },
        error => {
          this.toastrService.error("Server Error (getModem)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
          this.formState = this.formStates.Read
        });
  }

  onSuccessItem(event) {
    if (event && event.status == 200 && event.response) {
      var modemAdd = JSON.parse(event.response);
      this.modemId = modemAdd.Id
      this.formState = this.formStates.Edit
    }
  }

  formRead() {
  }

  formAdd() {
    this.isModified = false;
    this.onFormEvent.emit("add")
  }

  formEdit() {
    this.isModified = false;
    this.onFormEvent.emit("edit")
  }

  formSave() {
    if (this.fgModem.valid == false) {
      this.librariesService.validateFormGroup(this.fgModem)
      
      this.formState = this.modemId == -1 ? this.formStates.New : this.formStates.Edit
      this.onFormEvent.emit("edit")
      this.toastrService.warning("Validation Warning (Modem)", "Verify Required Data", { timeOut: 5000, tapToDismiss: true });
      return
    }

    this.formState = this.formStates.Saving
    this.onFormEvent.emit("saving")

    if (this.modemId == -1) {   
      const modem = {
        id: +this.fgModem.value.id,
        name: this.fgModem.value.name,
        description: this.fgModem.value.description
      }
      return this.modemsService.create(modem)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {
            this.modemId = result.id

            this.formState = this.formStates.Read
            this.onFormEvent.emit("saved")
            this.toastrService.success("Success", "Modem Saved", { timeOut: 2000, tapToDismiss: true });
          },
          error => {
            this.toastrService.error("Server Error (modemSave)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
            this.formState = this.formStates.Edit
          });
    } else {
      return this.modemsService.readById(this.modemId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {
            result.name = this.fgModem.value.name
            result.description = this.fgModem.value.description

            return this.modemsService.put(result)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                (result: any) => {
                  this.modemId = result.id

                  this.formState = this.formStates.Read
                  this.onFormEvent.emit("saved")
                  this.toastrService.success("Success", "Modem Saved", { timeOut: 2000, tapToDismiss: true });
                },
                error => {
                  this.toastrService.error("Server Error (modemSave: put)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
                  this.formState = this.formStates.Edit
                });

          },
          error => {
            this.toastrService.error("Server Error (modemSave: getModem)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
            this.formState = this.formStates.Edit
          });

    }
  }

  formCancel() {
    this.librariesService.untouchedFormGroup(this.fgModem)
    this.formState = this.formStates.Read
    this.onFormEvent.emit("cancelled")
    this.getModem()
  }

}
