
import { filter, max, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild, EventEmitter, TemplateRef, Output, Input, AfterViewInit, OnDestroy, ElementRef, QueryList, ViewChildren, HostListener } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MenuService } from '../../../../core/menu/menu.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { DeviceTelemetriesService } from '../../../../core/api/devicetelemetries.service';
import { AgGridModule } from 'ag-grid-angular';
import { GridOptions, ChartType } from 'ag-grid-community';
import { EventsService } from '../../../../core/events/events.service';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { bindCallback, Subject, Observable, OperatorFunction } from 'rxjs';
import { DevicesService } from '../../../../core/api/api.services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { fromIntersectionObserver, IntersectionStatus } from '../../../../core/directives/from-intersection-observer';
import { template } from 'lodash';
import { document } from 'ngx-bootstrap/utils';
import { StatsVehiclesViolatorsChartComponent } from '../../charts/stats-vehiclesviolators-chart/stats-vehiclesviolators-chart.component';

@Component({
  selector: 'app-deviceimagesview',
  templateUrl: './deviceimagesview.component.html',
  styleUrls: ['./deviceimagesview.component.scss'],
})

export class DeviceImagesViewComponent implements OnInit, AfterViewInit, OnDestroy {
  modalRef: BsModalRef;

  private onDestroy$: Subject<void> = new Subject<void>();
  public imagePath: string;
  public gridOptions: GridOptions;
  private paramSub: any;
  public dateStart = new Date();
  public runningDate = new Date();
  public modalPosition: number;
  public tableView: boolean = true;
  public windowScrolled: boolean;

  @Input() debounceTime = 0;
  @Input() public dateBegin: Date = null;
  @Input() threshold = 0;
  @Output() visible = new EventEmitter<HTMLElement>();
  @ViewChildren('theLastElement', { read: ElementRef })
  theLastElement: QueryList<ElementRef>;
  visibilityStatus: { [key: string]: IntersectionStatus } = {};
  intersectionStatus = IntersectionStatus;

  private subject$ = new Subject<{
    entry: IntersectionObserverEntry;
    observer: IntersectionObserver;
  }>();

  @HostListener('window: popstate', ['$event'])
  onPopState(event) {
    this.modalRef.hide();
  }

  

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.plateNumberList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )



  public sortDescending: Boolean = true;
  public plateNumberList: any;
  public defaultCount: number = 48;
  public deviceImages: any[] = [];
  public scrolledImages: any[] = [];
  public imageIndex: number = 0;
  public matchedPlates: any[] = [];
  public chartThemes;
  public defaultPlateNumber = "";
  observer: any;

  public fgImageControls: UntypedFormGroup = this.formBuilder.group({
    unprocessedEnabled: false,
    dateRange: [new Date()],
    plateNumber: "",
    viewType: "0"

  });


  private _gridData: any = null;
  get gridData(): any {
    return this._gridData;
  }
  @Input() set gridData(newGridData: any) {
    this._gridData = newGridData;
  }




  private _deviceUuid = '';
  get deviceUuid(): string {
    return this._deviceUuid;
  }
  set deviceUuid(newDeviceUuid: string) {
    if (this.librariesService.guidValidate(newDeviceUuid)) {
      this._deviceUuid = newDeviceUuid;
    } else {
      this._deviceUuid = '';

    }
  }

  constructor(
    public menu: MenuService,
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public devicesService: DevicesService,
    private route: ActivatedRoute,
    //public deviceTelemetriesService: DeviceTelemetriesService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder) {
    this.setLastDates(0)
    this.chartThemes = [
      'ag-default',

    ],
    this.gridOptions = <GridOptions>{
      rowData: this.gridData,
      columnDefs: this.columnDefs,
      rowSelection: "single",
     // sideBar: this.sideBar,
      //chartThemeOverrides: this.chartThemeOverrides,
      enableCharts: true,
      enableRangeSelection: true
      // pagination: true,
      // rowSelection: 'single'
    }

  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
        this.windowScrolled = true;
    } 
   else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
        this.windowScrolled = false;
    }
}
  scrollToTop() {
    (function smoothscroll() {
        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
            window.requestAnimationFrame(smoothscroll);
            window.scrollTo(0, currentScroll - (currentScroll / 8));
        }
    })();
}

  columnDefs = [
    {
      headerName: 'File Date', field: 'fileDate', chartDataType: 'time', sortable: true, resizable: true, suppressMenu: true,
      cellRenderer: (data) => {
        return data.value ? (data.value != null
          ? (new Date(data.value)).toLocaleString()
          : '') : '';
      }

    },
    { headerName: 'Vehicle Speed', field: 'fileMetaData.speed', sortable: true, resizable: true, suppressMenu: true },
    { headerName: 'Vehicle Number', field: 'fileMetaData.vehNum', sortable: true, resizable: true, suppressMenu: true },
    { headerName: 'Speed Limit', field: 'fileMetaData.limit', sortable: true, resizable: true, suppressMenu: true },
    { headerName: 'Plate Number', field: 'plateNumber', sortable: true, resizable: true, suppressMenu: true },
    { headerName: 'Plate State/Province', field: 'plateStateProvince', sortable: true, resizable: true, suppressMenu: true },
    { headerName: 'Plate Confidence', field: 'plateConfidence', sortable: true, resizable: true, suppressMenu: true },
    { headerName: 'File', field: 'fileName', sortable: true, resizable: true, flex: 1, suppressMenu: true },

  ]

  onVisibilityChanged(index: number, status: IntersectionStatus) {
    let tracking = this.visibilityStatus;
    this.visibilityStatus[index] = status;
  }

  trackByIndex(index: number) {
    return index;
  }

  ngOnInit() {
    this.paramSub = this.route.params.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.deviceUuid = params['uuid'];
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    let self = this;
    this.intersectionObserver();
    this.onChangesFormSetup();
    console.log(this.theLastElement)
    console.log("yo this is table view:", this.tableView)

    self.fgImageControls.get('dateRange').valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(val => {
        this.dateStart = this.fgImageControls.get('dateRange').value;
        self.getImages();
      });

  }

  getImages() {
    let dateUtcString = this.dateStart.toUTCString()
    this.deviceImages = [];
    this.plateNumberList = [];


    return this.devicesService.readImagesByDeviceUuid(this.deviceUuid, dateUtcString, this.defaultCount, this.sortDescending, this.fgImageControls.get('unprocessedEnabled').value,
      this.defaultPlateNumber)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {

          this.deviceImages = result;

          if (this.deviceImages.length > 0) {
            for (var i = 0; i < 48; i++) {
              if (this.deviceImages[i].fileMetaData) {
                this.deviceImages[i].imageIndex = i;
                this.deviceImages[i].fileMetaData = JSON.parse(this.deviceImages[i].fileMetaData);
                if (i == 0) {
                  this.plateNumberList[i] = this.deviceImages[i].plateNumber;
                }
                else if (this.plateNumberList.find(x => x == this.deviceImages[i].plateNumber)) {
                  console.log('duplicate plate')
                } 
                else {
                  this.plateNumberList[i] = this.deviceImages[i].plateNumber;
                }
              }

            }
            console.log("plate list: ", this.plateNumberList);
          }

        },
        error => {

        });

  }




  getMoreImages() {
    let newDate = this.deviceImages[this.deviceImages.length - 1].fileDate;
    let count = 48;

    //let dateTime = new Date(newDate);
    //dateTime.setSeconds(dateTime.getSeconds() - 1);


    return this.devicesService.readImagesByDeviceUuid(this.deviceUuid, newDate, count, this.sortDescending, this.fgImageControls.get('unprocessedEnabled').value, this.defaultPlateNumber)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          //decsending sort

          this.scrolledImages = result;
          for (var i = 0; i < this.scrolledImages.length; i++) {
            if ((this.scrolledImages[i].fileName == this.deviceImages[this.deviceImages.length - 1].fileName) || (this.scrolledImages[i].fileName == this.deviceImages[this.deviceImages.length - 2].fileName)
              || (this.scrolledImages[i].fileName == this.deviceImages[this.deviceImages.length - 3].fileName)) {
              i++;
            }
            else {
              if (this.scrolledImages[i].fileMetaData) {
                this.scrolledImages[i].imageIndex = this.deviceImages.length;
                this.scrolledImages[i].fileMetaData = JSON.parse(this.scrolledImages[i].fileMetaData);
                this.deviceImages[this.deviceImages.length] = this.scrolledImages[i];
                this.deviceImages[this.deviceImages.length - 1].imageIndex = this.scrolledImages[i].imageIndex;

                if (this.plateNumberList.find(x => x == this.scrolledImages[i].plateNumber)) {
                  console.log('duplicate plate')
                }
                else {
                  this.plateNumberList[this.plateNumberList.length] = this.scrolledImages[i].plateNumber;
                }

              }
            }

          }
          console.log("plateNumber more list: ", this.plateNumberList)

        },
        error => {

        });
  }


  dataRefresh() {
    var self = this;
    if (!this.settingsService.client) {
      setTimeout(() => {
        self.dataRefresh();
      }, 100);
    }
    else {

      this.getImages();
    }
  }

  getDataForLastWeek() {
    console.log("last week")
  }

  getDataForLastMonth() {
    console.log("last month")

  }

  buttonRefresh() {
    this.dateStart = new Date();
    let emptyVal = ''
    this.fgImageControls.controls["plateNumber"].setValue(emptyVal);
    this.defaultPlateNumber = this.fgImageControls.get('plateNumber').value;
    this.getImages();
    this.intersectionObserver();
    console.log(this.theLastElement);
    this.theLastElement.changes.subscribe((d) => {
      if (d.last) this.observer.observe(d.last.nativeElement);
    });
  }

  intersectionObserver() {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: .5,
    };
    this.observer = new IntersectionObserver((entries) => {

      if (entries[0].isIntersecting) {
        this.getMoreImages()
      }

    }, options);
  }

  public clickedImage: any;

  openImageModal(template: TemplateRef<any>, imagePath: string, imageIndex: number, item: object) {
    this.clickedImage = item;
    this.imagePath = imagePath;
    this.imageIndex = imageIndex;
    this.modalRef = this.modalService.show(template, { class: "modal-xl modal-dialog-centered" });
  }

  arrowForward() {
    this.clickedImage = this.deviceImages[this.imageIndex + 1]
    this.imagePath = this.deviceImages[this.imageIndex + 1].path

    this.imageIndex++;
  }

  arrowBack() {
    this.clickedImage = this.deviceImages[this.imageIndex - 1]
    this.imagePath = this.deviceImages[this.imageIndex - 1].path
    this.imageIndex--;
  }
  

  setLastDates(days: number) {
    let dateBegin: Date = new Date();
    dateBegin.setDate(dateBegin.getDate() - days);

    this.fgImageControls.patchValue({
      dateRange: [dateBegin]
    });
  }

  searchForPlate() {
    this.defaultPlateNumber = this.fgImageControls.get('plateNumber').value;
    this.matchedPlates = [];
    this.getImages()

  }

  clearPlate() {
    let emptyVal = ''
    this.fgImageControls.controls["plateNumber"].setValue(emptyVal);
    this.defaultPlateNumber = this.fgImageControls.get('plateNumber').value;
    this.plateNumberList = [];
    this.getImages()
  }


  onChangesFormSetup(): void {
    this.fgImageControls.get('unprocessedEnabled').valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(val => {
        this.buttonRefresh();

      });
  }

}

