
import { filter, takeUntil } from 'rxjs/operators';
import { Component, ElementRef, EventEmitter, ViewEncapsulation, OnInit, AfterViewInit, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild, ChangeDetectorRef, TemplateRef, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SettingsService, userTypes, userRoles } from '../../../../../core/settings/settings.service';
import { UsersService, ClientsService, RolesService } from '../../../../../core/api/api.services';
import { EventsService } from '../../../../../core/events/events.service';
import { ToastrService } from 'ngx-toastr';
import { LibrariesService } from '../../../../../core/libraries/libraries.service';
import { PasswordValidator } from '../../../../../core/validators/password.validator';
import { ErrorsService } from '../../../../../core/errors/errors.service';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceNotificationsService } from '../../../../../core/api/devicenotifications.service';
import { VerificationsService } from '../../../../../core/api/verifications.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { template } from 'lodash';
import { AnyMxRecord } from 'dns';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { stringify } from 'querystring';

export enum UserProfileFormState {
  Initializing = 1,
  Read = 2,
  New = 3,
  Edit = 4,
  Save = 5,
  Saving = 6,
  Saved = 7,
  Cancelled = 8

}

export enum UserNotificationTypes {
  Misc = 0,
  Battery = 10,
  Location = 20,
  Offline = 30,
  Disrupted = 40

}

@Component({
  selector: 'app-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UserProfileFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onFormEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('phoneVerifyModal') phoneVerifyModal: TemplateRef<any>;
  @ViewChild('firstField') firstField: ElementRef;


  //Observables

  private onDestroy$: Subject<void> = new Subject<void>();


  get formTitle() {
    if (this.userUuid && this.userUuid.toString() != "") {
      return 'Edit User'
    } else return 'Add User'
  }

  get phoneMobile() {
    return this.fgUser.get('phoneMobile');
  }

  @Input() enableEditForm: any;
  public emptyVerify = "";

  public verifyResponse: any;
  public resendCount = 0;
  public confirmResponse: any;
  public updatedProfile: any[] = [];
  public code: any[] = [];
  public userNotificationSettings: any = null;
  public count = 0;
  public formLoaded: boolean = false;
  public verifyNumber: boolean = false;
  public optIn: boolean = false;
  // public optInModalHasBeenOpened: boolean = false;
  public modalHasBeenOpened: boolean = false;
  public phoneVerified: boolean = false;
  public formStates = UserProfileFormState;
  private _formState: UserProfileFormState = this.formStates.Initializing;
  get formState(): UserProfileFormState {
    return this._formState
  }
  @Input() set formState(newFormState: UserProfileFormState) {

    var updateFormState: UserProfileFormState = null

    switch (newFormState) {
      case this.formStates.Read: {
        updateFormState = newFormState
        this.formRead()
        break
      }
      case this.formStates.New: {
        updateFormState = newFormState
        this.formAdd()
        break
      }
      case this.formStates.Edit: {
        updateFormState = newFormState
        this.formEdit()
        break
      }
      case this.formStates.Save: {
        //presetting to address form state sequencing durring validation
        this._formState = newFormState
        this.formSave()
        break
      }
      case this.formStates.Saved: {
        //presetting to address form state sequencing durring validation
        this._formState = newFormState
        break
      }
      case this.formStates.Cancelled: {
        // this.optInModalHasBeenOpened = false;
        this.modalHasBeenOpened = false;
        this.fgUser.controls.verifiedNumber.setValue(false);
        this.code = [];
        this.resendCount = 0;
        this.formCancel()
        break
      }

      default: {
        updateFormState = newFormState
      }
    }

    if (updateFormState != null) {
      this._formState = updateFormState
    }
  }

  private _userUuid: string = "";
  get userUuid(): string {
    return this._userUuid;
  }
  @Input() set userUuid(newUserUuid: string) {
    if (this.librariesService.guidValidate(newUserUuid)) {
      this._userUuid = newUserUuid;
      if (this.formLoaded) {
        this.getUser()
        this.getProfileSettings()
      }
    } else {
      this._userUuid = "";
    }

    if (newUserUuid.toLowerCase() == 'add')
      this.formState = this.formStates.New
  }

  private paramSub: any;
  private paramEmail: String = "";


  private user: any = null;
  public userProfile: any = null;

  public userRoles: any[] = [];
  public userRoleId: string;
  public userClients: any[] = [];
  public alertTypes: any[] = [];


  get isEditMode(): boolean {

    if (this.enableEditForm) {
      return true
    }

    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving:
      case this.formStates.Saved:
        {
          return true
        }
      default:
        {
          return false
        }
    }
  }

  public isModified = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public eventsService: EventsService,
    public deviceNotificationsService: DeviceNotificationsService,
    public verificationsService: VerificationsService,
    public toastrService: ToastrService,
    public modalService: BsModalService,
    public settingsService: SettingsService,
    public clientsService: ClientsService,
    public usersService: UsersService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    public rolesService: RolesService,
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef
  ) { }

  public modalRef: BsModalRef;


  public fgUser: UntypedFormGroup = this.formBuilder.group({
    uuid: '',
    userRoleId: [0, [Validators.required]],
    email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    first: ['', [Validators.required, Validators.minLength(2)]],
    last: ['', [Validators.required, Validators.minLength(2)]],
    verifiedNumber: false,
    sendInvitation: false,
    passwordChange: false,
    optIn: false,
    password: ['', [PasswordValidator.strong(8, true, true, true, "#@$!%*?()>,:/=^_-{}~|")
    ]
    ],
    phoneMobile: ["", [Validators.required, Validators.pattern('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$')]],
    passwordConfirm: ['', [
      function (control: AbstractControl) {
        if (!control.parent) return null
        if (
          (
            control.parent.value['passwordChange'] == false ||
            (control.parent.value['passwordChange'] == true &&
              control.parent.value['password'] != '' &&
              control.value == control.parent.value['password']
            )
          )
        ) {
          return null
        }
        return { passwordConfirm: false }
      }
    ]
    ]
  });
  //user notification forms and control





  ngOnInit() {

    this.createFormGroupNotifications();
    this.paramSub = this.route.queryParams
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(params => {

        if (params['email']) this.paramEmail = (params['email']).toString().toLowerCase() || ""

        //alert((params['email']).toString())

        //need to validate the email address below
        if (this.paramEmail != "")
          this.router.navigate(['/users', 'add'], { replaceUrl: false })

        if (this.formState == this.formStates.New && this.paramEmail == "")
          this.router.navigate(['/users'], { replaceUrl: false })
      });

    

  }


  public inputEmailBattery: UntypedFormControl;
  public inputMobileBattery: UntypedFormControl;
  public inputEmailLocation: UntypedFormControl;
  public inputMobileLocation: UntypedFormControl;
  public inputEmailDisrupted: UntypedFormControl;
  public inputMobilleDisrupted: UntypedFormControl;
  public inputEmailOffline: UntypedFormControl;
  public inputMobileOffline: UntypedFormControl;
  public fgUserNotifications: UntypedFormGroup;



  createFormGroupNotifications() {
    this.inputEmailBattery = new UntypedFormControl(false);
    this.inputMobileBattery = new UntypedFormControl(false);
    this.inputEmailLocation = new UntypedFormControl(false);
    this.inputMobileLocation = new UntypedFormControl(false);
    this.inputEmailDisrupted = new UntypedFormControl(false);
    this.inputMobilleDisrupted = new UntypedFormControl(false);
    this.inputEmailOffline = new UntypedFormControl(false);
    this.inputMobileOffline = new UntypedFormControl(false);


    this.fgUserNotifications = new UntypedFormGroup({
      "EmailBattery": this.inputEmailBattery,
      "MobileBattery": this.inputMobileBattery,
      "EmailLocation": this.inputEmailLocation,
      "MobileLocation": this.inputMobileLocation,
      "EmailDisrupted": this.inputEmailDisrupted,
      "MobileDisrupted": this.inputMobilleDisrupted,
      "EmailOffline": this.inputEmailOffline,
      "MobileOffline": this.inputMobileOffline


    });
    this.createFormGroupSubscriptions();
  }

  private subUserNotifications: Subscription;

  createFormGroupSubscriptions() {
    this.removeFormGroupSubscriptions()
    this.subUserNotifications = this.fgUserNotifications.valueChanges
      .subscribe(formData => {
        console.log('fgUserNotifications', formData);
        console.log(this.userProfile);
        this.librariesService.validateFormGroup(this.fgUserNotifications);


      });


  }

  removeFormGroupSubscriptions() {
    if (this.subUserNotifications) this.subUserNotifications.unsubscribe();
  }



  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    if (this.formState == this.formStates.New || this.formState == this.formStates.Edit) {
      this.fgUser.get('userRoleId').enable()
    } else {
      this.fgUser.get('userRoleId').disable()
    }

    this.fgUser.get('passwordChange').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(val => {
      if (val == false || !this.isEditMode) {
        this.fgUser.get('password').disable()
        this.fgUser.get('passwordConfirm').disable()
      } else {
        this.fgUser.get('password').enable()
        this.fgUser.get('passwordConfirm').enable()
      }
    });
    //this.getAlertTypes()
    let self = this;
    setTimeout(() => {
      if (self.user == null) self.getUser()
    }, 500);

    this.formLoaded = true
    console.log("Formstate: ", this.formState);

    if (this.fgUser.get('verifiedNumber').value == true) {
      this.optIn = true;
    }
  }



  getUserRoles(): any {
    this.userRoles = [];

    return this.rolesService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.userRoles = result;
        },
        error => {
          this.toastrService.error("Server Error (getUserRoles)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });
  }

  clearUser() {

    this.user = null;
    this.fgUser.patchValue(
      {
        uuid: '',
        userRoleId: 0,
        email: '',
        first: '',
        last: '',
        passwordChange: false,
        verifyNumber: false,
        optIn: false,
        password: '',
        passwordConfirm: ''
      }
    )
  }

  private getUserActive: boolean = false;
  getUser(): any {

    this.clearUser()

    if (this.paramEmail && this.paramEmail != "") {
      this.fgUser.patchValue(
        {
          email: this.paramEmail.toString()
        }
      );
      this.paramEmail = "";
    }

    if (!this.userUuid || this.getUserActive) return;
    if (this.user && this.user.uuid == this.userUuid) return;

    this.getUserActive = true;

    return this.usersService.readByUuid(this.userUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.fgUser.patchValue(
            {
              uuid: result.uuid,
              userRoleId: result.userRoleId,
              email: result.email,
              first: result.first,
              phoneMobile: result.phoneMobile,
              last: result.last,
              passwordChange: false,
              password: '',
              passwordConfirm: ''
            }
          )

          this.user = result;

          return this.usersService.readUserClientRole(this.userUuid)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              (result: any) => {
                this.fgUser.patchValue(
                  {
                    userRoleId: result.roleId
                  }
                );
                this.formState = this.formStates.Read;
                this.getUserActive = false;
                this.getProfileSettings();
              },
              error => {
                this.getUserActive = false;
                this.toastrService.error("Server Error (getUser:readUserClientRole)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
                this.formState = this.formStates.Read
              });

        },
        error => {
          this.getUserActive = false;
          this.clearUser()
          this.toastrService.error("Server Error (getUser)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
          this.formState = this.formStates.Read
        });


  }

  formRead() {
    if (this.enableEditForm) {
      this.fgUser.get('userRoleId').enable()
    } else {
      this.fgUser.get('userRoleId').disable()
    }
  }

  formAdd() {
    //do setup

    this.isModified = false;
    this.fgUser.get('userRoleId').enable()

    if (this.fgUser.get('passwordChange').value == false)
      this.fgUser.get('password').disable()
    if (this.fgUser.get('passwordChange').value == false)
      this.fgUser.get('passwordConfirm').disable()

    //Send Event
    this.onFormEvent.emit("add")
  }

  formEdit() {
    //do setup
    this.isModified = false;
    this.fgUser.get('userRoleId').enable()

    if (this.fgUser.get('passwordChange').value == false)
      this.fgUser.get('password').disable()
    if (this.fgUser.get('passwordChange').value == false)
      this.fgUser.get('passwordConfirm').disable()

    //Send Event
    this.onFormEvent.emit("edit")
  }

  formSave() {
    if (this.fgUser.valid == false) {
      this.librariesService.validateFormGroup(this.fgUser)
      this.formState = this.userUuid == "" ? this.formStates.New : this.formStates.Edit
      this.onFormEvent.emit("edit")
      this.toastrService.warning("Validation Warning (User)", "Verify Required Data", { timeOut: 5000, tapToDismiss: true });
      return
    }

    this.formState = this.formStates.Saving
    this.onFormEvent.emit("saving")


    if (this.fgUser.value.passwordChange == true &&
      this.fgUser.value.password.toString().length > 0 &&
      this.fgUser.value.password == this.fgUser.value.passwordConfirm) {
      this.userProfile.password = this.fgUser.value.password;
    }

    this.userProfile.first = this.fgUser.value.first;
    this.userProfile.last = this.fgUser.value.last;
    this.userProfile.phoneMobile = this.fgUser.value.phoneMobile;
    this.userProfile.password = this.fgUser.value.password;
    this.userProfile.passwordChange = this.fgUser.value.passwordChange;
    this.userProfile.email = this.fgUser.value.email;


    this.updateUserProfile();
  }
  public myNotifications = {
    "name": "",
    "method": 0,
  }
  getProfileSettings() {
    let profileIndex = 0;

    return this.usersService.getUserProfile(this.userUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          //new profile logic 

          this.userProfile = result;
          this.phoneVerified = result.phoneMobileVerified;

          for (var j = 0; j < this.userProfile.userNotificationSettings.length; j++) {
            if (this.userProfile.userNotificationSettings[j].typeId == UserNotificationTypes.Battery && this.userProfile.userNotificationSettings[j].methodId == 0) {

              this.fgUserNotifications.patchValue({ EmailBattery: true });

            }
            if (this.userProfile.userNotificationSettings[j].typeId == UserNotificationTypes.Battery && this.userProfile.userNotificationSettings[j].methodId == 1) {

              this.fgUserNotifications.patchValue({ MobileBattery: true });
            }
            if (this.userProfile.userNotificationSettings[j].typeId == UserNotificationTypes.Battery && this.userProfile.userNotificationSettings[j].methodId == 2) {

              this.fgUserNotifications.patchValue({ EmailBattery: true });
              this.fgUserNotifications.patchValue({ MobileBattery: true });
            }
            if (this.userProfile.userNotificationSettings[j].typeId == UserNotificationTypes.Offline && this.userProfile.userNotificationSettings[j].methodId == 0) {

              this.fgUserNotifications.patchValue({ EmailOffline: true });
            }
            if (this.userProfile.userNotificationSettings[j].typeId == UserNotificationTypes.Offline && this.userProfile.userNotificationSettings[j].methodId == 1) {

              this.fgUserNotifications.patchValue({ MobileOffline: true });
            }
            if (this.userProfile.userNotificationSettings[j].typeId == UserNotificationTypes.Offline && this.userProfile.userNotificationSettings[j].methodId == 2) {

              this.fgUserNotifications.patchValue({ EmailOffline: true });
              this.fgUserNotifications.patchValue({ MobileOffline: true });
            }
            if (this.userProfile.userNotificationSettings[j].typeId == UserNotificationTypes.Disrupted && this.userProfile.userNotificationSettings[j].methodId == 0) {

              this.fgUserNotifications.patchValue({ EmailDisrupted: true });
            }
            if (this.userProfile.userNotificationSettings[j].typeId == UserNotificationTypes.Disrupted && this.userProfile.userNotificationSettings[j].methodId == 1) {

              this.fgUserNotifications.patchValue({ MobileDisrupted: true });
            }
            if (this.userProfile.userNotificationSettings[j].typeId == UserNotificationTypes.Disrupted && this.userProfile.userNotificationSettings[j].methodId == 2) {

              this.fgUserNotifications.patchValue({ EmailDisrupted: true });
              this.fgUserNotifications.patchValue({ MobileDisrupted: true });
            }
            if (this.userProfile.userNotificationSettings[j].typeId == UserNotificationTypes.Location && this.userProfile.userNotificationSettings[j].methodId == 0) {

              this.fgUserNotifications.patchValue({ EmailLocation: true });
            }
            if (this.userProfile.userNotificationSettings[j].typeId == UserNotificationTypes.Location && this.userProfile.userNotificationSettings[j].methodId == 1) {

              this.fgUserNotifications.patchValue({ MobileLocation: true });
            }
            if (this.userProfile.userNotificationSettings[j].typeId == UserNotificationTypes.Location && this.userProfile.userNotificationSettings[j].methodId == 2) {
              this.fgUserNotifications.patchValue({ EmailLocation: true });
              this.fgUserNotifications.patchValue({ MobileLocation: true });
            }
          }

          console.log('User Profile: ' + this.userProfile.toString())

        },
        error => {
          this.toastrService.error("Sever Error (Invalid User)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
          this.formState = this.formStates.Read;
        });
  }


  updateUserProfile() {
    this.fgUserNotifications;
    let updatedValues: any = null;
    let batteryValue: number;
    let offlineValue: number;
    let locationValue: number;
    let disruptedValue: number;

    if (this.fgUserNotifications.controls.EmailBattery.value == true && this.fgUserNotifications.controls.MobileBattery.value == true) {
      batteryValue = 2;
    }
    if (this.fgUserNotifications.controls.EmailBattery.value != true && this.fgUserNotifications.controls.MobileBattery.value == true) {
      batteryValue = 1;
    }
    if (this.fgUserNotifications.controls.EmailBattery.value == true && this.fgUserNotifications.controls.MobileBattery.value != true) {
      batteryValue = 0;
    }
    if (this.fgUserNotifications.controls.EmailBattery.value != true && this.fgUserNotifications.controls.MobileBattery.value != true) {
      batteryValue = null;
    }


    if (this.fgUserNotifications.controls.EmailOffline.value == true && this.fgUserNotifications.controls.MobileOffline.value == true) {
      offlineValue = 2;
    }
    if (this.fgUserNotifications.controls.EmailOffline.value != true && this.fgUserNotifications.controls.MobileOffline.value == true) {
      offlineValue = 1;
    }
    if (this.fgUserNotifications.controls.EmailOffline.value == true && this.fgUserNotifications.controls.MobileOffline.value != true) {
      offlineValue = 0;
    }
    if (this.fgUserNotifications.controls.EmailOffline.value != true && this.fgUserNotifications.controls.MobileOffline.value != true) {
      offlineValue = null;
    }

    if (this.fgUserNotifications.controls.EmailLocation.value == true && this.fgUserNotifications.controls.MobileLocation.value == true) {
      locationValue = 2;
    }
    if (this.fgUserNotifications.controls.EmailLocation.value != true && this.fgUserNotifications.controls.MobileLocation.value == true) {
      locationValue = 1;
    }
    if (this.fgUserNotifications.controls.EmailLocation.value == true && this.fgUserNotifications.controls.MobileLocation.value != true) {
      locationValue = 0;
    }
    if (this.fgUserNotifications.controls.EmailLocation.value != true && this.fgUserNotifications.controls.MobileLocation.value != true) {
      locationValue = null;
    }

    if (this.fgUserNotifications.controls.EmailDisrupted.value == true && this.fgUserNotifications.controls.MobileDisrupted.value == true) {
      disruptedValue = 2;
    }
    if (this.fgUserNotifications.controls.EmailDisrupted.value != true && this.fgUserNotifications.controls.MobileDisrupted.value == true) {
      disruptedValue = 1;
    }
    if (this.fgUserNotifications.controls.EmailDisrupted.value == true && this.fgUserNotifications.controls.MobileDisrupted.value != true) {
      disruptedValue = 0;
    }
    if (this.fgUserNotifications.controls.EmailDisrupted.value != true && this.fgUserNotifications.controls.MobileDisrupted.value != true) {
      disruptedValue = null;
    }


    if (this.userProfile.userNotificationSettings.length > 0) {
      for (var i = 0; i < this.userProfile.userNotificationSettings.length; i++) {
        if (this.userProfile.userNotificationSettings[i].typeCode == "Critical Battery") {
          this.userProfile.userNotificationSettings[i].methodId = batteryValue;
        }
        if (this.userProfile.userNotificationSettings[i].typeCode == "Device Offline") {
          this.userProfile.userNotificationSettings[i].methodId = offlineValue;
        }
        if (this.userProfile.userNotificationSettings[i].typeCode == "New Location") {
          this.userProfile.userNotificationSettings[i].methodId = locationValue;
        }
        if (this.userProfile.userNotificationSettings[i].typeCode == "Disrupted Communications") {
          this.userProfile.userNotificationSettings[i].methodId = disruptedValue;
        }
      }

    }

    this.userProfile.userNotificationSettings.forEach(function (element, index, object) {
      if (element.methodId == null) {
        object.splice(index, 1)
      }
    });


    return this.usersService.updateUserProfile(this.userUuid, this.userProfile)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {

          result = this.userProfile;
          this.toastrService.success("Success", "User Notifications Settings Saved", { timeOut: 2000, tapToDismiss: true })

          // if (this.fgUser.value.phoneMobile != "" && this.fgUser.value.verifiedNumber == false) {
          //   this.openAcceptTermsModal(this.acceptTermsModal);
          //   this.formState = this.formStates.Edit;
          // }

          // if (this.fgUser.value.phoneMobile != "" && this.fgUser.value.optIn == false) {
          //   this.openPhoneVerifyModal(this.phoneVerifyModal);
          //   this.formState = this.formStates.Edit;


          // }
          // else {
          this.formState = this.formStates.Read;
          this.dataRefresh();
         //}


        },
        error => {
          this.toastrService.error("Sever Error (Invalid User)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
          this.formState = this.formStates.Read;
        });
  }

  verifyPhoneNumber() {
    this.resendCount = 0;
    return this.verificationsService.verify()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {

          result = this.verifyResponse;
          this.toastrService.success("Success", "User Phone Number Verified", { timeOut: 2000, tapToDismiss: true })



        },
        error => {
          this.toastrService.error("Sever Error (Invalid User Number)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
          this.formState = this.formStates.Edit;
          this.phoneVerified = false;
        });

  }

  resendNumber() {
    this.resendCount++;
    if (this.resendCount >= 3) {
      this.toastrService.warning("Request Warning", "Please make sure the phone number entered is valid");
    }
    else {
      return this.verificationsService.verify()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {

            result = this.verifyResponse;
            this.toastrService.success("Success", "User Phone Number Verified", { timeOut: 2000, tapToDismiss: true })



          },
          error => {
            this.toastrService.error("Sever Error (Invalid User Number)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
            this.formState = this.formStates.Edit;
            this.phoneVerified = false;
          });
    }

  }

  confirmPhoneNumber(code: any) {
    return this.verificationsService.confirm(code)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {

          result = this.confirmResponse;
          this.toastrService.success("Success", "User Phone Number Confirmed", { timeOut: 2000, tapToDismiss: true })
          this.phoneVerified = true;
          this.modalRef.hide();
          this.formState = this.formStates.Read;
          this.code = [];
          this.resendCount = 0;
          this.dataRefresh();

        },
        error => {
          this.toastrService.error("Sever Error (User Number Not Confirmed)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
          this.formState = this.formStates.Read;
        });
  }

  confirmTermsAcceptance() {
    this.optIn = true;
    this.verifyPhoneNumber();
    // this.modalRef.hide();
  }

  cancelTermsAcceptance() {
    this.optIn = false;
    this.modalRef.hide();
  }



  formCancel() {
    this.librariesService.untouchedFormGroup(this.fgUser)
    if (this.fgUser.get('verifiedNumber').value == false) {
      var phoneField = this.fgUser.get('phoneMobile');
      phoneField.reset();
      this.verifyNumber = false;
      this.optIn = false;
    }
    this.formState = this.formStates.Read
    this.onFormEvent.emit("cancelled")
    this.getUser()
    this.modalRef.hide();
  }

  // openAcceptTermsModal(template: TemplateRef<any>) {
  //   this.optInModalHasBeenOpened = true;
  //   this.modalRef = this.modalService.show(template, { class: "modal-md modal-dialog-centered" });
  // }

  onCheckboxChange(event: any) {
    if (event.target.checked && this.fgUser.value.phoneMobile != "") {
      this.openPhoneVerifyModal(this.phoneVerifyModal);
    }
  }

  openPhoneVerifyModal(template: TemplateRef<any>) {
    this.code = [];
    this.modalHasBeenOpened = true;
    this.modalRef = this.modalService.show(template, { class: "modal-md modal-dialog-centered" });
    if(this.modalHasBeenOpened && this.resendCount >=3){
      this.verifyPhoneNumber();
    }

  }

  onKeyDown(event) {
    if(event.key != "Backspace"){
      return true;
    }
    else if(event.key == "Backspace"){
      return false;
    }
  }

  emailBatteryChange() {
    if (this.fgUserNotifications.controls.EmailBattery.value == 1) {
      this.fgUserNotifications.controls.EmailBattery.setValue(0);
    }
    else {
      this.fgUserNotifications.controls.EmailBattery.setValue(1);
    }
  }

  mobileBatteryChange() {
    if (this.fgUserNotifications.controls.MobileBattery.value == 1) {
      this.fgUserNotifications.controls.MobileBattery.setValue(0);
    }
    else {
      this.fgUserNotifications.controls.MobileBattery.setValue(1);
    }
  }

  dataRefresh() {
    var self = this;
    if (!this.settingsService.client) {
      setTimeout(() => {
        self.dataRefresh();
      }, 100);
    }
    else {
      setTimeout(function () {
        self.getUser();
      }, 500);
    }
  }


}
