<div class="home-container">
    <div class="jumbotron mt-3">
        <div class="row">
            <div class="col-xl-3 col-lg-4">
                <div class="text-center">
                    <img alt="Under Construction" class="img-fluid home-logo" src="assets/img/under-construction.svg" />
                </div>
            </div>
            <div class="col-xl-9 col-lg-8 home-text">
                <h1 class="home-text-big">{{Header}}</h1>
                <p class="lead">{{Title}}
                    <br><small>{{Description}}</small>
                </p>
                <!-- <p><a class="btn btn-primary btn-lg" href="https://angular.io/" target="_blank">Learn more</a></p> -->
            </div>
        </div>
    </div>

</div>