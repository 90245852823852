
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, AfterViewInit, ViewChildren, ViewChild, QueryList, ElementRef, Directive, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { EventsService } from '../../core/events/events.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsService, userTypes, userRoles } from '../../core/settings/settings.service';
import { DeviceWifiFormComponent } from '../../shared/component/forms/devices/devicewifi-form/devicewifi-form.component';
import { DevicesViewComponent } from '../../shared/component/views/devicesview/devicesview.component';
import { LibrariesService } from '../../core/libraries/libraries.service';
import { DeviceDailySummariesService } from '../../core/api/api.services';

import { Subject } from 'rxjs';
import { UploadComponent } from '../../shared/component/upload/upload.component';


export enum DeviceFormState {
  Initializing = 1,
  Upload = 2,
  DeviceListing = 3,
  DeviceForm = 4,
}

@Component({
  selector: 'app-devices-admin',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DeviceWifiFormComponent) public deviceForm: DeviceWifiFormComponent;
  @ViewChild(DevicesViewComponent) devicesView: DevicesViewComponent;
  @ViewChild(UploadComponent) upload: UploadComponent;

  private firstLoad: boolean = true;

  public get dataLoading(): boolean {
    if (!this.devicesView) return false;
    return this.devicesView.dataLoading;
  }

  userTypes = userTypes;
  userRoles = userRoles;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public formStates = DeviceFormState;
  private _formState: DeviceFormState = DeviceFormState.Initializing;
  get formState(): DeviceFormState {
    if (this.router.url.toLowerCase().split("?")[0] == '/devices'){
      this._formState = this.formStates.DeviceListing;
    }
    return this._formState
  }
  @Input() set formState(newFormState: DeviceFormState) {
    let updateFormState: DeviceFormState = null;

    switch (newFormState) {
      case this.formStates.Upload: {
        updateFormState = newFormState;
        break;
      }
      case this.formStates.DeviceListing: {
        updateFormState = newFormState;
        break;
      }
      case this.formStates.DeviceForm: {
        updateFormState = newFormState;
        break;
      }
      default: {
        updateFormState = newFormState;
      }
    }

    if (updateFormState != null) {
      this._formState = updateFormState;
    }
  }

  private paramSub: any;
  public viewCurrent = 'All';
  public connectivityTypeId = -1;
  private _deviceUuid = '';
  get deviceUuid(): string {
    return this._deviceUuid;
  }
  set deviceUuid(newDeviceUuid: string) {
    if (newDeviceUuid && (newDeviceUuid.toString().toLowerCase() === 'add' || this.librariesService.guidValidate(newDeviceUuid))) {
      this._deviceUuid = newDeviceUuid;
      this.formState = this.formStates.DeviceForm;
    }
    else if (this.formState === this.formStates.DeviceForm) {
      this._deviceUuid = '';
      this.formState = this.formStates.DeviceListing
    }
    else {
      this._deviceUuid = '';
      this.formState = this._formState
    }
  }

  public showUpload = true;
  public textUpload = '';
  toggleShowUpload(event) {
    this.showUpload = !this.showUpload;
    if (this.showUpload) {
      this.textUpload = 'Hide Upload';
    } else {
      this.textUpload = 'Upload Data';
    }
  }

  constructor(
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    public deviceDailySummariesService: DeviceDailySummariesService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {

    console.log("my route: ", this.router.url.toLowerCase().split("?")[0])
   
    if (this.router.url.toLowerCase().split("?")[0] == '/devices') {
      this.formState = this.formStates.DeviceListing;
    }


     if (this.router.url.toLowerCase().split("?")[0] == '/devices/upload') {
      this.formState = this.formStates.Upload;
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log(this.route.routeConfig.component.name + ":NavigationEnd", this.router.url)
        if (this.router.url.toLowerCase().split("?")[0] == '/devices') {
          console.log(this.route.routeConfig.component.name + ":NavigationEnd")
        }
      }
    });

    this.paramSub = this.route.params
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(params => {
        this.deviceUuid = params['uuid'];
      });

    this.paramSub = this.route.queryParams
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(params => {

        if (params['view']) {
          // alert((params['view']).toString())

          switch ((params['view']).toString().toLowerCase()) {
            case 'all': {
              this.viewCurrent = 'All';
              this.connectivityTypeId = -1;
              break;
            }
            case 'wi-fi': {
              this.viewCurrent = 'Wi-Fi';
              this.connectivityTypeId = 0;
              break;
            }
            case 'cellular': {
              this.viewCurrent = 'Cellular';
              this.connectivityTypeId = 10;
              break;
            }
            default: {
              this.viewCurrent = 'All';
              this.connectivityTypeId = -1;
              break;
            }
          }
        }
      });

    this.toggleShowUpload(null);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    console.log("onDestroy ", this.formState)
  }

  ngAfterViewInit() {
    // if (this.deviceUuid !="") this.showDeviceForm = true

  }

  dataRefresh() {
    var self = this;
    if (!this.settingsService.client ||
      !this.devicesView) {
      setTimeout(() => {
        console.log("dataLoading: ", this.dataLoading)
        self.dataRefresh();
      }, 100);
    }
    else {
      console.log("dataLoading devices: ", this.dataLoading);
    }
  }

  onDataLoading(event) {
    if (this.dataLoading && this.firstLoad) this.settingsService.showSpinner("devices", false, 250);
    this.firstLoad = false;

    if (!this.dataLoading) this.settingsService.hideSpinner("devices");
  }

  onFormEventDevice(event) {

    switch (event.toString()) {
      case 'saved':
        {
          // alert(this.deviceUuid)
          break;
        }
      case 'cancelled':
        {
          // //this.showDeviceForm = false;
          // this.deviceUuid = "";
          // //this.location.go(this.router.createUrlTree(['/devices/wifi/all'], { relativeTo: this.route }).toString())
          // this.router.navigate(['/devices/wifi/all'])
          break;
        }
    }

  }

  onDeviceSelected(event) {

    if (event && event.uuid && event.uuid.toString().length > 10) {
      // var url = this.router.createUrlTree(['.',event.toString()], {relativeTo: this.route})
      // this.location.go(url.toString());

      // this.router.navigate(['/devices'], { queryParams: { uuid: event.uuid.toString() } });
      this.router.navigate(['/devices', event.uuid.toString()], { replaceUrl: false });
      console.log('wifiFormRoute: ', this.route);

      // this.router.navigate(['/devices/wifi', event.uuid.toString()], {replaceUrl: false}  )
      // this.router.navigateByUrl('/devices/wifi/'+event.toString())
    }
  }

  buttonAdd(event) {
    // alert(this.deviceForm.formState)
    // this.showDeviceForm = true;
    // this.deviceForm.formState = this.deviceForm.formStates.New
    this.router.navigate(['/devices', 'add'], { replaceUrl: false });
  }

  buttonListing(event) {
    // this.router.navigate(['/devices/wifi/all'])
    this.formState = this.formStates.DeviceListing;
    this.firstLoad = false;
    this.router.navigate(['/devices'], { replaceUrl: true });
    console.log(this.router)
    console.log("formstate:", this.formState);
  }

  buttonUpload(event) {
    this.formState = this.formStates.Upload;
    this.firstLoad = false;
    this.router.navigate(['/devices/upload'], { replaceUrl: false });
    console.log("formstate:", this.formState)
    console.log('uploadRoute :', this.route)

  }

  buttonEdit(event) {
    this.deviceForm.formState = this.deviceForm.formStates.Edit;
  }

  buttonSave(event) {
    this.deviceForm.formState = this.deviceForm.formStates.Save;
    // this.deviceForm.deviceConfigurationsForm.formState = this.deviceForm.deviceConfigurationsForm.formStates.Save;
  }

  buttonCancel(event) {
    this.deviceForm.formState = this.deviceForm.formStates.Cancelled;
  }

  buttonRefresh(event) {
    this.devicesView.dataRefresh();
  }

}
