import { Component, OnInit, AfterViewInit, ElementRef, ViewEncapsulation, ViewChild, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuService } from '../../../../core/menu/menu.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { ModemsService } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
// import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GridOptions } from 'ag-grid-community';
import { customAgTheme } from 'app/shared/styles/themes/theme-ag-custom';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { AgGridAngular } from 'ag-grid-angular';


@Component({
  selector: 'app-modems-admin-view',
  templateUrl: './modems-admin-view.component.html',
  styleUrls: ['./modems-admin-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModemsAdminViewComponent implements OnInit, AfterViewInit, OnDestroy {

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public modemsTemp: any[] = [];
  public modems: any[] = [];
  public modemsSelected: any[] = [];

  private _modemId: number = -1;
  get modemId(): number {
    return this._modemId;
  }
  @Input() set modemId(Id: number) {
    this._modemId = Id
    if (this.datatableFilter) this.updateFilter(this.datatableFilter.nativeElement.value)
  }

  @Output() onViewSelected: EventEmitter<any> = new EventEmitter()
  // @ViewChild(DatatableComponent) dataView: DatatableComponent;
  @ViewChild('datatableFilter') datatableFilter: ElementRef;
  @ViewChild('agGrid') grid!: AgGridAngular;

   //ag-grid variables start

   public theme = customAgTheme;

   public gridOptions: GridOptions;
   public chartThemes;
   public rowStyle;
   public isSelected: boolean = false;
 
   private _gridData: any = null;
   get gridData(): any {
     return this._gridData;
   }
   @Input() set gridData(newGridData: any) {
     this._gridData = newGridData;
   }

   @Input() public chartView: boolean = false;

  constructor(
    public menu: MenuService,
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    private router: Router,
    public modemsService: ModemsService) {
      this.chartThemes = [
        'ag-pastel',
        'ag-default',
        'ag-material-dark',
        'ag-vivid-dark',
        'ag-solar',
      ];
    this.rowStyle = {
      cursor: 'pointer'
    }
    this.gridOptions = <GridOptions>{
      rowData: this.gridData,
      columnDefs: this.columnDefs,
      rowStyle: this.rowStyle,
      rowHeight: 40,
      rowSelection: 'multiple',
      cursor: "pointer",
      suppressRowClickSelection: true,
      onCellClicked: (e) => {
        e.node.setSelected(true)
        this.isSelected = true;
    },
    //chartThemeOverrides: this.chartThemeOverrides,
    enableCharts: false,
    enableRangeSelection: false
    // pagination: true,
    // rowSelection: 'single'
  }
  }

  columnDefs = [
    { headerName: "Identifier", field: "id", sortable: true, resizable: true, flex: 1, suppressMenu: true },
    { headerName: "Name", field: "name", sortable: true, resizable: true, flex: 2, suppressMenu: true },
    { headerName: "Description", field: "description", sortable: true, resizable: true, flex: 2, suppressMenu: true },
  ];

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    let self = this
    setTimeout(function () {
      self.getModems()
    }, 500);
  }

  getModems(): any {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 25);
    return this.modemsService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.modems = result;
          this.modemsTemp = result;
          if (this.datatableFilter) this.updateFilter(this.datatableFilter.nativeElement.value)
        },
        error => {
          this.toastrService.error("Server Error (getModems)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });
  }

  updateFilter(event) {
    const val = event.toLowerCase();
    const temp = this.modemsTemp.filter(function (d) {
      return (
        (
          (d.name != null && d.name.toLowerCase().indexOf(val) !== -1) ||
          (d.description != null && d.description.toLowerCase().indexOf(val) !== -1) ||
          val.toString().trim() == ''
        )
      );
    });

    this.modems = temp;
  }

  onSelect({ selected }) {
    
    this.modemsSelected.splice(0, this.modemsSelected.length);
    if (selected && selected[0].id) {
      console.log('!!!selected[0].id', selected[0].id);
    
      this.onViewSelected.emit(selected[0]);
    }
  }
  
  onResize(event) {
    let allColumnIds = [];
    this.grid.api.getColumns().forEach((column) => {
      allColumnIds.push(column);
    });
    this.grid.api.autoSizeColumns(allColumnIds);
}

  onRowSelected({selected}) {
    //console.log(event);
    this.modemsSelected.splice(0, this.modemsSelected.length);
    if (selected && selected[0].id) {
      console.log('!!!selected[0].id', selected[0].id); 
      this.onViewSelected.emit(selected[0]);
    }
  }

  onRowClicked(event) {
    console.log(event)

    if (this.isSelected && !this.chartView) {
      this.router.navigate(['/administration/modems/', event.node.data.id]);
    }

    else if(this.isSelected && this.chartView){
      console.log(event.node)
      this.router.navigate(['/charts/', event.node.data.deviceLocation.id]);
    }
 
  }

  getChartToolbarItems() {
    return ['chartDownload', 'chartData', 'chartFormat']

  }

}
