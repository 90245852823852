
import { filter, takeUntil } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  AfterViewInit,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import {
  DevicesService,
  DeviceSchedulesService,
  FirmwaresService,
  ModelsService,
  DeviceDailySummariesService,
  DeviceLocationsService
} from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
import { ToastrService } from 'ngx-toastr';
import { DeviceLocationsViewComponent } from '../../views/devicelocationsview/devicelocationsview.component';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { DeviceCrudDto } from '../../../../models/device';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { ColorsService } from '../../../../core/colors/colors.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-device-activity-stats-card',
  templateUrl: './device-activity-stats-card.component.html',
  styleUrls: ['./device-activity-stats-card.component.scss']
})

export class DeviceActivityStatsCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onFormEventDevice: EventEmitter<any> = new EventEmitter<any>();

  datePipe = new DatePipe('en-US');
  decimalPipe = new DecimalPipe('en-US');

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public formLoaded: boolean = false;
  public speedLimit: number = undefined;
  public count: number = 0;

  private _deviceUuid: string = null;
  private _deviceLocationUuid: string = null;
  get deviceLocationUuid(): string {
    return this._deviceLocationUuid;
  }
  @Input() set deviceLocationUuid(newDeviceLocationUuid: string) {
    if (this.librariesService.guidValidate(newDeviceLocationUuid)) {
      this._deviceLocationUuid = newDeviceLocationUuid;
      if (this.formLoaded) {
        this.getDeviceLocation();
      }
    }
    else {
      this._deviceLocationUuid = null;
    }
  }

  @Output() chartData: any =
    [{
      "data": [
        [0.5, null]
      ]
    }, {
      "label": "Speeders % (Ave)",
      "color": this.colorsService.byName('primary'),
      "data": [
        [1.5, null], [2.5, null], [3.5, null], [4.5, null]
      ]
    }, {
      "label": "Speeders % (Peak)",
      "color": this.colorsService.byName('danger'),
      "data": [
        [5.5, null], [6.5, null], [7.5, null], [8.5, null]
      ]
    }, {
      "label": "Percentiles",
      "color": this.colorsService.byName("warning"),
      "data": [
        [9.5, null], [10.5, null], [11.5, null]
      ]
    },
    {
      "data": [
        [11.5, null]
      ]
    }
    ];

  @Output() chartTooltips = []

  @Output() chartOptions = {
    series: {
      bars: {
        align: 'middle',
        show: true,
        fill: 0.8,
        barWidth: 2 / this.chartData.length,
        lineWidth: 2
      },
      // points: {
      //   show: true,
      //   radius: 1
      // },
      // splines: {
      //     show: true,
      //     tension: 0.4,
      //     lineWidth: 1,
      //     fill: 0.5
      // }
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 5,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: (label, x, y) => {
        let index = x - 1.5;
        if (index >= 0 && index <= 7) {
          return this.decimalPipe.transform(y, '1.0') + "% (" + this.decimalPipe.transform(this.chartTooltips[index], '1.0') + " Vehicles)"
        } else return this.decimalPipe.transform(y, '1.0') + "%"
      }
    },
    xaxis: {
      ticks: [[1.5, "Tot"], [2.5, ">5"], [3.5, ">10"], [4.5, ">15"], [5.5, "Tot"], [6.5, ">5"], [7.5, ">10"], [8.5, ">15"], [9.5, "50th"], [10.5, "85th"]],
      // tickLength: 0,
      //min: -0.2,
      // max: 2.7,
      tickColor: '#fcfcfc',
      // tickFormatter: (v, axis) => {
      //   if (!v || v == 0) return "----"
      //   return this.datePipe.transform(new Date(v), 'MM/dd')
      // },
      font: {
        size: 8,
        lineHeight: 13,
        style: "italic",
        weight: "bold",
        family: "sans-serif",
        variant: "small-caps",
        color: "#545454"
      },

    },
    yaxis: {
      min: 0,
      max: 100, // percentage
      tickColor: '#eee',
      tickLength: 0,
      // position: ($scope.app.layout.isRTL ? 'right' : 'left'),
      tickFormatter: (v) => { return this.decimalPipe.transform(v, '1.0') },
      font: {
        size: 8,
        lineHeight: 13,
        style: "italic",
        weight: "bold",
        family: "sans-serif",
        variant: "small-caps",
        color: "#545454"
      }
    },

    legend: {
      show: false,
      //labelFormatter: null or (fn: string, series object -> string),
      //labelBoxBorderColor: color,
      noColumns: 2,
      //position: "ne" or "nw" or "se" or "sw",
      margin: [0, 0], //number of pixels or [x margin, y margin],
      //backgroundColor: null or color,
      //backgroundOpacity: number between 0 and 1,
      container: "#violatorsLegend", //null or jQuery object/DOM element/jQuery expression,
      //sorted: null/false, true, "ascending", "descending", "reverse", or a comparator
    },
    shadowSize: 0
  };

  @Output() chartOptionsKph = {
    series: {
      bars: {
        align: 'middle',
        show: true,
        fill: 0.8,
        barWidth: 2 / this.chartData.length,
        lineWidth: 2
      },
      // points: {
      //   show: true,
      //   radius: 1
      // },
      // splines: {
      //     show: true,
      //     tension: 0.4,
      //     lineWidth: 1,
      //     fill: 0.5
      // }
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 5,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: (label, x, y) => {
        let index = x - 1.5;
        if (index >= 0 && index <= 7) {
          return this.decimalPipe.transform(y, '1.0') + "% (" + this.decimalPipe.transform(this.chartTooltips[index], '1.0') + " Vehicles)"
        } else return this.decimalPipe.transform(y, '1.0') + "%"
      }
    },
    xaxis: {
      ticks: [[1.5, "Tot"], [2.5, ">10"], [3.5, ">20"], [4.5, ">30"], [5.5, "Tot"], [6.5, ">10"], [7.5, ">20"], [8.5, ">30"], [9.5, "50th"], [10.5, "85th"]],
      // tickLength: 0,
      //min: -0.2,
      // max: 2.7,
      tickColor: '#fcfcfc',
      // tickFormatter: (v, axis) => {
      //   if (!v || v == 0) return "----"
      //   return this.datePipe.transform(new Date(v), 'MM/dd')
      // },
      font: {
        size: 8,
        lineHeight: 13,
        style: "italic",
        weight: "bold",
        family: "sans-serif",
        variant: "small-caps",
        color: "#545454"
      },

    },
    yaxis: {
      min: 0,
      max: 100, // percentage
      tickColor: '#eee',
      tickLength: 0,
      // position: ($scope.app.layout.isRTL ? 'right' : 'left'),
      tickFormatter: (v) => { return this.decimalPipe.transform(v, '1.0') },
      font: {
        size: 8,
        lineHeight: 13,
        style: "italic",
        weight: "bold",
        family: "sans-serif",
        variant: "small-caps",
        color: "#545454"
      }
    },

    legend: {
      show: false,
      //labelFormatter: null or (fn: string, series object -> string),
      //labelBoxBorderColor: color,
      noColumns: 2,
      //position: "ne" or "nw" or "se" or "sw",
      margin: [0, 0], //number of pixels or [x margin, y margin],
      //backgroundColor: null or color,
      //backgroundOpacity: number between 0 and 1,
      container: "#violatorsLegend", //null or jQuery object/DOM element/jQuery expression,
      //sorted: null/false, true, "ascending", "descending", "reverse", or a comparator
    },
    shadowSize: 0
  };


  public device: any = null; // new DeviceCrudDto();
  public deviceLocation: any = null;
  private cardUuid: string = "";

  constructor(
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public devicesService: DevicesService,
    public deviceLocationsService: DeviceLocationsService,
    public deviceDailySummariesService: DeviceDailySummariesService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    public colorsService: ColorsService
  ) { }


  ngOnInit() {
    this.cardUuid = this.librariesService.guidCreate();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.loadFormData();

  }

  loadFormData() {
    let self = this
    setTimeout(function () {
      // self.getModels();
      // self.getFirmwares();
      // self.getdeviceSchedules();
      if (self.device == null) {
        self.getDeviceLocation();
      }
      self.formLoaded = true;
    }, 1000);
  }

  getDeviceLocation(): any {
    this.deviceLocation = null;
    if (!this.settingsService.client || !this._deviceLocationUuid) return;

    return this.deviceLocationsService.readByUuid(this.deviceLocationUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: any) => {
        this.deviceLocation = result;
        if (this.deviceLocation != null) {
          this.getDevice();
        }

      },
        error => {
          this.toastrService.error('Server Error (getDeviceLocation)',
            this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });
  }

  getDevice(): any {
    this.device = null;
    if (!this.settingsService.client) return;

    return this.deviceLocationsService.readDeviceByLocationUuid(this.deviceLocationUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: any) => {
        this.device = result;
        this.getChartData();
      },
        error => {
          this.toastrService.error('Server Error (getDevice)',
            this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });
  }

  public chartDataDate: string = "";
  getChartData(): any {
    this.chartDataDate = "";


    if (!this.settingsService.client) {
      return;
    }
    console.log('')
    let today = new Date();
    let yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate())
    yesterday.setDate(yesterday.getDate() - 1)
    let dateEnd: Date = yesterday;
    let dateBegin: Date = new Date(dateEnd);
    dateBegin.setDate(dateBegin.getDate() - 6);

    this.count = this.count + 1;
    console.log("count : " + this.count, " SpeedLimit: " + this.speedLimit);

    // if (this.deviceLocation.uuid == "8f1ce822-4fec-4b98-954c-ea1f1c1d42ac") {
    //     dateBegin = new Date("2019-05-06T00:00:00")
    //     dateEnd = new Date("2019-05-12T00:00:00")
    // }
    // if (this.deviceLocation.uuid == "48507887-9d6b-48b1-83f5-a3be49c9449d") {
    //   dateBegin = new Date("2019-04-01T00:00:00")
    //   dateEnd = new Date("2019-04-07T00:00:00")
    // }

    return this.deviceLocationsService.readDailySummaryTotalsByDateRange(
      this.deviceLocation.uuid, this.speedLimit,
      dateBegin, dateEnd)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          const chartDataApply: any = JSON.parse(JSON.stringify(this.chartData));
          this.chartOptions.legend.container = "#" + this.cardUuid;

          const chartOptionsApply: any = (this.chartOptions);

          let spdrsAve = new Array();
          let spdrsPeak = new Array();
          let percentiles = new Array();
          let tooltips = new Array();

          spdrsAve = [
            [1.5, result.violatorsPercAverage == 0 ? null : result.violatorsPercAverage],
            [2.5, result.violatorsPercAverageBreak1 == 0 ? null : result.violatorsPercAverageBreak1],
            [3.5, result.violatorsPercAverageBreak2 == 0 ? null : result.violatorsPercAverageBreak2],
            [4.5, result.violatorsPercAverageBreak3 == 0 ? null : result.violatorsPercAverageBreak3]
          ];
          spdrsPeak = [
            [5.5, result.violatorsPercPeak == 0 ? null : result.violatorsPercPeak],
            [6.5, result.violatorsPercPeakBreak1 == 0 ? null : result.violatorsPercPeakBreak1],
            [7.5, result.violatorsPercPeakBreak2 == 0 ? null : result.violatorsPercPeakBreak2],
            [8.5, result.violatorsPercPeakBreak3 == 0 ? null : result.violatorsPercPeakBreak3]
          ];
          percentiles = [
            [9.5, result.percentile50 == 0 ? null : result.percentile50],
            [10.5, result.percentile85 == 0 ? null : result.percentile85]
          ];

          this.chartTooltips = [
            result.violatorsCountAverage,
            result.violatorsCountAverageBreak1,
            result.violatorsCountAverageBreak2,
            result.violatorsCountAverageBreak3,
            result.violatorsCountPeak,
            result.violatorsCountPeakBreak1,
            result.violatorsCountPeakBreak2,
            result.violatorsCountPeakBreak3
          ];

          if (result.length > 0) {
            var lastDate = new Date(result.dateMax);
            this.chartDataDate = new Date(
              lastDate.getFullYear(),
              lastDate.getMonth(),
              lastDate.getDate()).toLocaleDateString();
          }

          //console.log(spdrsAve, spdrsPeak, percentiles)

          chartDataApply[1].data = spdrsAve;
          chartDataApply[2].data = spdrsPeak;
          chartDataApply[3].data = percentiles;

          //chartOptionsApply.tooltipOpts = tooltips;

          this.chartOptions = chartOptionsApply;
          this.chartData = chartDataApply;
        },
        error => {
          this.toastrService.error('Error (getChartData)', this.errorsService.errorParse(error), { tapToDismiss: true });
        });

  }

}
