
import { filter, takeUntil } from 'rxjs/operators';
import { Component, ElementRef, EventEmitter, ViewEncapsulation, OnInit, AfterViewInit, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SettingsService, userTypes, userRoles } from '../../../../../core/settings/settings.service';
import { UsersService, ClientsService, RolesService } from '../../../../../core/api/api.services';
import { EventsService } from '../../../../../core/events/events.service';
import { ToastrService } from 'ngx-toastr';
import { LibrariesService } from '../../../../../core/libraries/libraries.service';
import { PasswordValidator } from '../../../../../core/validators/password.validator';
import { ErrorsService } from '../../../../../core/errors/errors.service';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceNotificationsService } from '../../../../../core/api/devicenotifications.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { template } from 'lodash';

export enum UserFormState {
  Initializing = 1,
  Read = 2,
  New = 3,
  Edit = 4,
  Save = 5,
  Saving = 6,
  Saved = 7,
  Cancelled = 8,
}

@Component({
  selector: 'app-user-client-form',
  templateUrl: './user-client-form.component.html',
  styleUrls: ['./user-client-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UserClientFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onFormEvent: EventEmitter<any> = new EventEmitter<any>();

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  get formTitle() {
    if (this.userUuid && this.userUuid.toString() != "") {
      return 'Edit User'
    } else return 'Add User'
  }

  @Input() enableEditForm: any;

  public formLoaded: boolean = false;
  public formStates = UserFormState;
  private _formState: UserFormState = this.formStates.Initializing;
  get formState(): UserFormState {
    return this._formState
  }
  @Input() set formState(newFormState: UserFormState) {

    var updateFormState: UserFormState = null

    switch (newFormState) {
      case this.formStates.Read: {
        updateFormState = newFormState
        this.formRead()
        break
      }
      case this.formStates.New: {
        updateFormState = newFormState
        this.formAdd()
        break
      }
      case this.formStates.Edit: {
        updateFormState = newFormState
        this.formEdit()
        break
      }
      case this.formStates.Save: {
        //presetting to address form state sequencing durring validation
        this._formState = newFormState
        this.formSave()
        break
      }
      case this.formStates.Saved: {
        //presetting to address form state sequencing durring validation
        this._formState = newFormState
        break
      }
      case this.formStates.Cancelled: {
        this.formCancel()
        break
      }
      default: {
        updateFormState = newFormState
      }
    }

    if (updateFormState != null) {
      this._formState = updateFormState
    }
  }

  private _userUuid: string = "";
  get userUuid(): string {
    return this._userUuid;
  }
  @Input() set userUuid(newUserUuid: string) {
    if (this.librariesService.guidValidate(newUserUuid)) {
      this._userUuid = newUserUuid;
      if (this.formLoaded) {
        this.getUser()
      }
    } else {
      this._userUuid = "";
    }

    if (newUserUuid.toLowerCase() == 'add')
      this.formState = this.formStates.New
  }

  private paramSub: any;
  private paramEmail: String = "";

  private user: any = null;
  public userRoles: any[] = [];
  public clientRole: any[] = [];
  public userRoleId: string;
  public userClients: any[] = [];
  public alertTypes: any[] = [];

  get isEditMode(): boolean {

    if (this.enableEditForm) {
      return true
    }

    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving:
      case this.formStates.Saved:
        {
          return true
        }
      default:
        {
          return false
        }
    }
  }


  public isModified = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public eventsService: EventsService,
    public deviceNotificationsService: DeviceNotificationsService,
    public toastrService: ToastrService,
    public modalService: BsModalService,
    public settingsService: SettingsService,
    public clientsService: ClientsService,
    public usersService: UsersService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    public rolesService: RolesService,
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef
  ) { }

  public modalRef: BsModalRef;

  public fgUser: UntypedFormGroup = this.formBuilder.group({
    uuid: '',
    userRoleId: [0, [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    first: ['', [Validators.required, Validators.minLength(2)]],
    last: ['', [Validators.required, Validators.minLength(2)]],
    verifyNumber: false,
    sendInvitation: false,
    passwordChange: false,
    password: ['', [PasswordValidator.strong(8, true, true, true, "#@$!%*?()>,:/=^_-{}~|")
    ]
    ],
    passwordConfirm: ['', [
      function (control: AbstractControl) {
        if (!control.parent) return null
        if (
          (
            control.parent.value['passwordChange'] == false ||
            (control.parent.value['passwordChange'] == true &&
              control.parent.value['password'] != '' &&
              control.value == control.parent.value['password']
            )
          )
        ) {
          return null
        }
        return { passwordConfirm: false }
      }
    ]
    ]
  });

  ngOnInit() {


    this.paramSub = this.route.queryParams
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(params => {

        if (params['email']) this.paramEmail = (params['email']).toString().toLowerCase() || ""

        //alert((params['email']).toString())

        //need to validate the email address below
        if (this.paramEmail != "")
          this.router.navigate(['/users', 'add'], { replaceUrl: false })

        if (this.formState == this.formStates.New && this.paramEmail == "")
          this.router.navigate(['/users'], { replaceUrl: false })
      });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    if (this.enableEditForm == true) {
      this.formState = this.formStates.Edit;
    }
    if (this.formState == this.formStates.New || this.formState == this.formStates.Edit) {
      this.fgUser.get('userRoleId').enable()
    } else {
      this.fgUser.get('userRoleId').disable()
    }

    this.fgUser.get('passwordChange').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(val => {
      if (val == false || !this.isEditMode) {
        this.fgUser.get('password').disable()
        this.fgUser.get('passwordConfirm').disable()
      } else {
        this.fgUser.get('password').enable()
        this.fgUser.get('passwordConfirm').enable()
      }
    });

    this.getUserRoles()
    //this.getAlertTypes()
    let self = this;
    setTimeout(() => {
      if (self.user == null) self.getUser()
    }, 500);

    this.formLoaded = true
  }



  getUserRoles(): any {
    this.userRoles = [];

    return this.rolesService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.userRoles = result;
        },
        error => {
          this.toastrService.error("Server Error (getUserRoles)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });
  }

  clearUser() {

    this.user = null;
    this.fgUser.patchValue(
      {
        uuid: '',
        userRoleId: 0,
        email: '',
        first: '',
        last: '',
        passwordChange: false,
        verifyNumber: false,
        password: '',
        passwordConfirm: ''
      }
    )
  }

  private getUserActive: boolean = false;
  getUser(): any {

    this.clearUser()

    if (this.paramEmail && this.paramEmail != "") {
      this.fgUser.patchValue(
        {
          email: this.paramEmail.toString()
        }
      );
      this.paramEmail = "";
    }

    if (!this.userUuid || this.getUserActive) return;
    if (this.user && this.user.uuid == this.userUuid) return;

    this.getUserActive = true;

    return this.usersService.readByUuid(this.userUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.fgUser.patchValue(
            {
              uuid: result.uuid,
              userRoleId: result.userRoleId,
              email: result.email,
              first: result.first,
              last: result.last,
              passwordChange: false,
              verifyNumber: false,
              password: '',
              passwordConfirm: ''
            }
          )

          this.user = result;
          


          return this.usersService.readUserClientRole(this.userUuid)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              (result: any) => {
                if(this.user == null) return;
                this.clientRole = result.clientUuid;
                for (var i = 0; i < this.user.userClientRoles.length; i++) {
                  this.user.userClientRoles[i].compareRole = this.clientRole;
                }

                this.user.userClientRoles.forEach(function (element, index, object) {
                  if (element.clientUuid == element.compareRole) {
                    //keep
                  }
                  else {
                    object.splice(index, 1);
                  
                  }
                });

                this.fgUser.patchValue(
                  {
                    userRoleId: result.roleId
                  }
                );
                if (this.enableEditForm) {
                  this.formState = this.formStates.Edit;
                  this.getUserActive = false;
                }
                else {
                  this.formState = this.formStates.Read;
                  this.getUserActive = false;
                }
              },
              error => {
                this.getUserActive = false;
                this.toastrService.error("Server Error (getUser:readUserClientRole)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
                this.formState = this.formStates.Read
              });

        },
        error => {
          this.getUserActive = false;
          this.clearUser()
          this.toastrService.error("Server Error (getUser)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
          this.formState = this.formStates.Read
        });
  }

  formRead() {
    if (this.enableEditForm) {
      this.fgUser.get('userRoleId').enable()
    } else {
      this.fgUser.get('userRoleId').disable()
    }
  }

  formAdd() {
    //do setup

    this.isModified = false;
    this.fgUser.get('userRoleId').enable()

    if (this.fgUser.get('passwordChange').value == false)
      this.fgUser.get('password').disable()
    if (this.fgUser.get('passwordChange').value == false)
      this.fgUser.get('passwordConfirm').disable()

    //Send Event
    this.onFormEvent.emit("add")
  }

  formEdit() {
    //do setup
    this.isModified = false;
    this.fgUser.get('userRoleId').enable()

    if (this.fgUser.get('passwordChange').value == false)
      this.fgUser.get('password').disable()
    if (this.fgUser.get('passwordChange').value == false)
      this.fgUser.get('passwordConfirm').disable()

    //Send Event
    this.onFormEvent.emit("edit")
  }

  formSave() {
    if (this.fgUser.valid == false) {
      this.librariesService.validateFormGroup(this.fgUser)
      this.formState = this.userUuid == "" ? this.formStates.New : this.formStates.Edit
      this.onFormEvent.emit("edit")
      this.toastrService.warning("Validation Warning (User)", "Verify Required Data", { timeOut: 5000, tapToDismiss: true });
      return
    }

    this.formState = this.formStates.Saving
    this.onFormEvent.emit("saving")

    if (this.userUuid == "") {
      var userPost: any = {
        "uuid": "",
        "email": this.fgUser.value.email,
        "first": this.fgUser.value.first,
        "last": this.fgUser.value.last,
        "userRoleId": this.fgUser.value.userRoleId,
        "sendInvitation": this.fgUser.value.sendInvitation
      }

      if (this.fgUser.value.passwordChange == true &&
        this.fgUser.value.password.toString().length > 0 &&
        this.fgUser.value.password == this.fgUser.value.passwordConfirm) {
        userPost.password = this.fgUser.value.password
      }

      this.userRoleId = this.fgUser.value.userRoleId

      return this.usersService.register(userPost)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {
            this.userUuid = result.uuid

            return this.usersService.updateRoleId(this.userUuid, this.userRoleId)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                (result: any) => {

                  this.enableEditForm = false
                  this.formState = this.formStates.Read
                  this.onFormEvent.emit("saved")
                  this.toastrService.success("Success", "User Saved", { timeOut: 2000, tapToDismiss: true });
                },
                error => {
                  this.toastrService.error("Server Error (userSave: updateRoleId)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
                  this.formState = this.formStates.New
                  this.enableEditForm = true;
                });

          },
          error => {
            this.toastrService.error("Server Error (userSave)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
            this.formState = this.formStates.New
            this.enableEditForm = true;
          });
    } else {
      return this.usersService.readByUuid(this.userUuid)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {

            result.email = this.fgUser.value.email
            result.first = this.fgUser.value.first
            result.last = this.fgUser.value.last
            result.userRoleId = this.fgUser.value.userRoleId
            result.sendInvitation = this.fgUser.value.sendInvitation

            if (this.fgUser.value.passwordChange == true &&
              this.fgUser.value.password.toString().length > 0 &&
              this.fgUser.value.password == this.fgUser.value.passwordConfirm) {
              result.password = this.fgUser.value.password
            }

            this.userRoleId = this.fgUser.value.userRoleId

            return this.usersService.put(result)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                (result: any) => {

                  this.userUuid = result.uuid

                  return this.usersService.updateRoleId(this.userUuid, this.userRoleId)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe(
                      (result: any) => {
                        this.enableEditForm = false
                        this.formState = this.formStates.Read
                        this.onFormEvent.emit("saved")
                        this.toastrService.success("Success", "User Saved", { timeOut: 2000, tapToDismiss: true });
                        this.getUserActive = false;
                        this.getUser();
                       
                      },
                      error => {
                        this.toastrService.error("Server Error (userSave: updateRoleId)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
                        this.formState = this.formStates.Edit
                        this.enableEditForm = true;
                      });

                },
                error => {
                  this.toastrService.error("Server Error (userSave: put)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
                  this.formState = this.formStates.Edit
                  this.enableEditForm = true;
                });

          },
          error => {
            this.toastrService.error("Server Error (userSave: getUser)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
            this.formState = this.formStates.Edit
          });

    }
  }

  formCancel() {
    this.librariesService.untouchedFormGroup(this.fgUser)
    this.formState = this.formStates.Read
    this.onFormEvent.emit("cancelled")
    this.getUser()
    if (this.modalRef != null) {
      this.modalRef.hide();
    }
  }


}
