
import { Component, EventEmitter, OnInit, AfterViewInit, Input, Output, OnChanges, SimpleChanges, SimpleChange, OnDestroy, ViewChild } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgChartsModule } from 'ng2-charts';
import { BaseChartDirective } from 'ng2-charts';
import 'chartjs-plugin-datalabels';

import { DevicesService, DeviceLocationsService, DeviceLocationSettingsService } from '../../../../core/api/api.services';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { EventsService } from '../../../../core/events/events.service';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { ColorsService } from '../../../../core/colors/colors.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { takeUntil, filter } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-daily-speedsummary-vehiclepercentages-chart',
  templateUrl: './daily-speedsummary-vehiclepercentages-chart.component.html',
  styleUrls: ['./daily-speedsummary-vehiclepercentages-chart.component.scss']
})
export class DailySpeedSummaryVehiclePercentagesChartComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Output() onChartJsEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(BaseChartDirective) chartObject: BaseChartDirective;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  datePipe = new DatePipe('en-US');
  decimalPipe = new DecimalPipe('en-US');

  public isViewInit: boolean = true;
  public deviceLocationSettings: any = null;
  @Input() @Output() public chartHeight: number = 400;

  @Input() public dateEnd: Date = null;
  @Input() public dateBegin: Date = null;
  @Input() public speedBinOneOption: number = 0;
  @Input() public speedBinBreakOne: number = 0;
  @Input() public speedBinTwoOption: number = 0;
  @Input() public speedBinBreakTwo: number = 0;
  @Input() public speedBinThreeOption: number = 0;

  @Input() public _device: any = null;
  get deviceUuid(): string {
    return this._device ? this._device.uuid : "";
  }
  @Input() set device(newDevice: any) {

    if (newDevice && this.librariesService.guidValidate(newDevice.uuid)) {

      this._device = newDevice;
    }
    else {
      this._device = null;
    }
  }

  private _deviceLocation: any = null;
  get deviceLocationUuid(): string {
    return this._deviceLocation ? this._deviceLocation.uuid : "";
  }
  @Input() set deviceLocation(newDeviceLocation: any) {

    if (newDeviceLocation && this.librariesService.guidValidate(newDeviceLocation.uuid)) {

      this._deviceLocation = newDeviceLocation;
    }
    else {
      this._deviceLocation = null;
    }
  }



  constructor(
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public colorsService: ColorsService,
    public devicesService: DevicesService,
    public deviceLocationsService: DeviceLocationsService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    public deviceLocationSettingsService: DeviceLocationSettingsService
  ) { }

  ngOnInit() {

  }


  ngOnDestroy() {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    let self = this;
    setTimeout(() => {
      
        this.getDeviceLocationSettings();
    }, 50);
  }

  ngOnChanges(changes: SimpleChanges) {

    // const name: SimpleChange = changes.dateEnd;
    // console.log('prev value: ', name.previousValue);
    // console.log('got name: ', name.currentValue);

    console.log('ngOnChanges', changes)
    console.log('deviceLocationUuid', this.deviceLocationUuid)
    console.log('dateBegin', this.dateBegin)
    console.log('dateEnd', this.dateEnd)
    console.log('speedBinOneOption', this.speedBinOneOption)
    console.log('speedBinBreakOne', this.speedBinBreakOne)
    console.log('speedBinTwoOption', this.speedBinTwoOption)
    console.log('speedBinBreakTwo', this.speedBinBreakTwo)
    console.log('speedBinThreeOption', this.speedBinThreeOption)
    console.log('isViewInit', this.isViewInit)

    if (this.isViewInit &&
      this.librariesService.guidValidate(this.deviceLocationUuid) && this.deviceLocationSettings) this.getChartJsData()

  }

  public chartDisplay: boolean = false;
  public chartDpsData: any = null

  get chartJsData(): any {
    if ((this._deviceLocation.unitIdSpeed == 0 && this.deviceLocationSettings[0].speedLimit < 50) || (this._deviceLocation.unitIdSpeed == 1 && this.deviceLocationSettings[0].speedLimit < 80)) {
      return this.chartJsData1;
    }
    else {
      return this.chartJsData2;
    }
  }

  public chartJsData1: any[] = [
    {
      data: [],
      label: '',
      borderWidth: 1,
      stack: 'Stack 0',
      datalabels: {
        anchor: 'end',
        align: 'end',
        //color: function (context) { return context.dataset.backgroundColor; },
        offset: -2,
        font: {
          weight: 'bold'
        }
      }
    },
    {
      data: [],
      label: '',
      stack: 'Stack 0',
      borderWidth: 1,
      datalabels: {
        anchor: 'end',
        align: 'end',
        //color: function (context) { return context.dataset.backgroundColor; },
        offset: -2,
        font: {
          weight: 'bold'
        }
      }
    },
    {
      data: [],
      label: '',
      borderWidth: 1,
      datalabels: {
        anchor: 'end',
        align: 'end',
        //color: function (context) { return context.dataset.backgroundColor; },
        offset: -2,
        font: {
          weight: 'bold'
        }
      }
    }
  ];

  public chartJsData2: any[] = [
    {
      data: [],
      label: '',
      borderWidth: 1,
      datalabels: {
        anchor: 'end',
        align: 'end',
        //color: function (context) { return context.dataset.backgroundColor; },
        offset: -2,
        font: {
          weight: 'bold'
        }
      }
    },
    {
      data: [],
      label: '',
      borderWidth: 1,
      datalabels: {
        anchor: 'end',
        align: 'end',
        //color: function (context) { return context.dataset.backgroundColor; },
        offset: -2,
        font: {
          weight: 'bold'
        }
      }
    },
  ];

  public chartJsLabels: string[] = [];
  public chartJsType: string = 'bar';
  public chartJsLegend: boolean = true;

  private _chartJsOptions: any = {
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },
    animation: {
      duration: 300,
      easing: "easeOutQuint",
    },
    title: {
      display: true,
      fontSize: 18,
      text: '---Chart Title---'
    },
    scales: {
      yAxes: [{
        stacked: false,
        scaleLabel: {
          display: true,
          labelString: ''
        },
        ticks: {
          beginAtZero: true,
          suggestedMax: 0
        }
      }],
      xAxes: [{
        stacked: true,
        scaleLabel: {
          display: true,
          labelString: ''
        },
        ticks: {
          maxRotation: 90,
          minRotation: 0,
          // autoSkipPadding: 13,
          autoSkip: false
        }
      }]
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  get chartJsOptions(): any {
    return this._chartJsOptions;
  }
  set chartJsOptions(newChartJsOptions: any) {
    this._chartJsOptions = _.cloneDeep(newChartJsOptions);
  }

  public chartJsColors = [
    {
      backgroundColor: this.colorsService.byName("chart-blue"),
      borderColor: this.colorsService.byName('chart-blue-border'),
      pointHoverBackgroundColor: this.colorsService.byName('chart-blue'),
      pointHoverBorderColor: this.colorsService.byName('chart-blue-border')
    },
    {
      backgroundColor: this.colorsService.byName("chart-red"),
      borderColor: this.colorsService.byName('chart-red-border'),
      pointHoverBackgroundColor: this.colorsService.byName('chart-red'),
      pointHoverBorderColor: this.colorsService.byName('chart-red-border')
    },
    {
      backgroundColor: this.colorsService.byName("chart-orange"),
      borderColor: this.colorsService.byName('chart-orange-border'),
      pointHoverBackgroundColor: this.colorsService.byName('chart-orange'),
      pointHoverBorderColor: this.colorsService.byName('chart-orange-border')
    }
  ];

  private getChartJsData_active: boolean = false;
  getChartJsData(): any {

    if (this.getChartJsData_active == true && this.deviceLocationSettings) return;
    this.getChartJsData_active = true;

    if (
      !this.settingsService.client ||
      this.deviceLocationUuid == "" ||
      this.dateBegin == null ||
      this.dateEnd == null ||
      this.speedBinOneOption == null ||
      this.speedBinBreakOne == null ||
      this.speedBinTwoOption == null ||
      this.speedBinBreakTwo == null ||
      this.speedBinThreeOption == null) {

      console.log('getChartJsData')
      console.log('deviceLocationUuid', this.deviceLocationUuid)
      console.log('dateBegin', this.dateBegin)
      console.log('dateEnd', this.dateEnd)
      console.log('speedBinOneOption', this.speedBinOneOption)
      console.log('speedBinBreakOne', this.speedBinBreakOne)
      console.log('speedBinTwoOption', this.speedBinTwoOption)
      console.log('speedBinBreakTwo', this.speedBinBreakTwo)
      console.log('speedBinThreeOption', this.speedBinThreeOption)
      console.log('isViewInit', this.isViewInit)

      this.getChartJsData_active = false;

      setTimeout(() => {
        this.getChartJsData();
      }, 200);
      return;
    }

    if (this.librariesService.guidValidate(this.deviceLocationUuid))
      return this.deviceLocationsService.readDailySummaryTotalsByDateRange(
        this.deviceLocationUuid, this.deviceLocationSettings[0].speedLimit, this.dateBegin, this.dateEnd
      )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {

            this.chartDpsData = result;

            let textSpeedLmit = this.chartDpsData.speedLimit
            this._chartJsOptions.scales.yAxes[0].ticks.suggestedMax = 100
            this._chartJsOptions.scales.yAxes[0].ticks.suggestedMin = 0

            this._chartJsOptions.title.text =
              'Daily Speed Summary - Percentages - Speed Limit ' + (this.chartDpsData.speedLimit && this.chartDpsData.speedLimit > 0 ? textSpeedLmit + ' ' + this.chartDpsData.unitSpeed : '[Unassigned]') + ' - ' +
              this._deviceLocation.name

            this._chartJsOptions.scales.yAxes[0].scaleLabel.labelString = "" //Count of Vehicles
            this._chartJsOptions.scales.xAxes[0].scaleLabel.labelString = (this.dateBegin.toLocaleDateString() == this.dateEnd.toLocaleDateString() ?
              this.dateBegin.toLocaleDateString() : this.dateBegin.toLocaleDateString() + ' to ' + this.dateEnd.toLocaleDateString())

            if ((this._deviceLocation.unitIdSpeed == 0 && this.deviceLocationSettings[0].speedLimit < 50) || (this._deviceLocation.unitIdSpeed == 1 && this.deviceLocationSettings[0].speedLimit < 80)) {
              let tmpLabels: string[] = new Array(10)
              tmpLabels[0] = "Total"
              tmpLabels[1] = `> ${this.chartDpsData.speedBreak1} ${this.chartDpsData.unitSpeed}`
              tmpLabels[2] = `> ${this.chartDpsData.speedBreak2} ${this.chartDpsData.unitSpeed}`
              tmpLabels[3] = `> ${this.chartDpsData.speedBreak3} ${this.chartDpsData.unitSpeed}`
              tmpLabels[4] = "Total"
              tmpLabels[5] = `> ${this.chartDpsData.speedBreak1} ${this.chartDpsData.unitSpeed}`
              tmpLabels[6] = `> ${this.chartDpsData.speedBreak2} ${this.chartDpsData.unitSpeed}`
              tmpLabels[7] = `> ${this.chartDpsData.speedBreak3} ${this.chartDpsData.unitSpeed}`
              tmpLabels[8] = this.chartDpsData.daysIncluded > 1 ? "50th (Averaged)" : "50th"
              tmpLabels[9] = this.chartDpsData.daysIncluded > 1 ? "85th (Averaged)" : "85th"

              this.chartJsLabels = tmpLabels

              this.chartJsData[0].label = 'Speeders % (Average)'
              this.chartJsData[1].label = 'Speeders % (Peak)'
              this.chartJsData[2].label = 'Percentiles'

              this.chartJsData[0].data = [
                this.chartDpsData.violatorsPercAverage,
                this.chartDpsData.violatorsPercAverageBreak1,
                this.chartDpsData.violatorsPercAverageBreak2,
                this.chartDpsData.violatorsPercAverageBreak3,
                null,
                null,
                null,
                null,
                null,
                null,
              ]
              this.chartJsData[1].data = [
                null,
                null,
                null,
                null,
                this.chartDpsData.violatorsPercPeak,
                this.chartDpsData.violatorsPercPeakBreak1,
                this.chartDpsData.violatorsPercPeakBreak2,
                this.chartDpsData.violatorsPercPeakBreak3,
                null,
                null,
              ]
              this.chartJsData[2].data = [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                this.chartDpsData.percentile50,
                this.chartDpsData.percentile85
              ]

              this.chartDisplay = true;
              this.chartJsOptions = this._chartJsOptions;
              this.getChartJsData_active = false;
            }
            else {
              if (this.chartDpsData.violatorsCountPeakBreak6 > 0) {
                let tmpLabels: string[] = new Array(9)
                tmpLabels[0] = "Total"
                tmpLabels[1] = `> ${this.chartDpsData.speedBreak1} ${this.chartDpsData.unitSpeed}`
                tmpLabels[2] = `> ${this.chartDpsData.speedBreak2} ${this.chartDpsData.unitSpeed}`
                tmpLabels[3] = `> ${this.chartDpsData.speedBreak3} ${this.chartDpsData.unitSpeed}`
                tmpLabels[4] = `> ${this.chartDpsData.speedBreak4} ${this.chartDpsData.unitSpeed}`
                tmpLabels[5] = `> ${this.chartDpsData.speedBreak5} ${this.chartDpsData.unitSpeed}`
                tmpLabels[6] = `> ${this.chartDpsData.speedBreak6} ${this.chartDpsData.unitSpeed}`
                tmpLabels[7] = this.chartDpsData.daysIncluded > 1 ? "50th (Averaged)" : "50th"
                tmpLabels[8] = this.chartDpsData.daysIncluded > 1 ? "85th (Averaged)" : "85th"

                this.chartJsLabels = tmpLabels

                this.chartJsData[0].label = 'Speeders % (Peak)'
                this.chartJsData[1].label = 'Percentiles'

                this.chartJsData[0].data = [
                  this.chartDpsData.violatorsPercPeak,
                  this.chartDpsData.violatorsPercPeakBreak1,
                  this.chartDpsData.violatorsPercPeakBreak2,
                  this.chartDpsData.violatorsPercPeakBreak3,
                  this.chartDpsData.violatorsPercPeakBreak4,
                  this.chartDpsData.violatorsPercPeakBreak5,
                  this.chartDpsData.violatorsPercPeakBreak6,
                  null,
                  null,
                ],
                  this.chartJsData[1].data = [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    this.chartDpsData.percentile50,
                    this.chartDpsData.percentile85
                  ]
              }
              else if (this.chartDpsData.violatorsCountPeakBreak5 > 0 && !this.chartDpsData.violatorsCountPeakBreak6) {
                let tmpLabels: string[] = new Array(8)
                tmpLabels[0] = "Total"
                tmpLabels[1] = `> ${this.chartDpsData.speedBreak1} ${this.chartDpsData.unitSpeed}`
                tmpLabels[2] = `> ${this.chartDpsData.speedBreak2} ${this.chartDpsData.unitSpeed}`
                tmpLabels[3] = `> ${this.chartDpsData.speedBreak3} ${this.chartDpsData.unitSpeed}`
                tmpLabels[4] = `> ${this.chartDpsData.speedBreak4} ${this.chartDpsData.unitSpeed}`
                tmpLabels[5] = `> ${this.chartDpsData.speedBreak5} ${this.chartDpsData.unitSpeed}`
                tmpLabels[6] = this.chartDpsData.daysIncluded > 1 ? "50th (Averaged)" : "50th"
                tmpLabels[7] = this.chartDpsData.daysIncluded > 1 ? "85th (Averaged)" : "85th"

                this.chartJsLabels = tmpLabels

                this.chartJsData[0].label = 'Speeders % (Peak)'
                this.chartJsData[1].label = 'Percentiles'

                this.chartJsData[0].data = [
                  this.chartDpsData.violatorsPercPeak,
                  this.chartDpsData.violatorsPercPeakBreak1,
                  this.chartDpsData.violatorsPercPeakBreak2,
                  this.chartDpsData.violatorsPercPeakBreak3,
                  this.chartDpsData.violatorsPercPeakBreak4,
                  this.chartDpsData.violatorsPercPeakBreak5,
                  null,
                  null,
                ],
                  this.chartJsData[1].data = [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    this.chartDpsData.percentile50,
                    this.chartDpsData.percentile85
                  ]
              }
              else if (this.chartDpsData.violatorsCountPeakBreak4 > 0 && !this.chartDpsData.violatorsCountPeakBreak5) {
                let tmpLabels: string[] = new Array(7)
                tmpLabels[0] = "Total"
                tmpLabels[1] = `> ${this.chartDpsData.speedBreak1} ${this.chartDpsData.unitSpeed}`
                tmpLabels[2] = `> ${this.chartDpsData.speedBreak2} ${this.chartDpsData.unitSpeed}`
                tmpLabels[3] = `> ${this.chartDpsData.speedBreak3} ${this.chartDpsData.unitSpeed}`
                tmpLabels[4] = `> ${this.chartDpsData.speedBreak4} ${this.chartDpsData.unitSpeed}`
                tmpLabels[5] = this.chartDpsData.daysIncluded > 1 ? "50th (Averaged)" : "50th"
                tmpLabels[6] = this.chartDpsData.daysIncluded > 1 ? "85th (Averaged)" : "85th"

                this.chartJsLabels = tmpLabels

                this.chartJsData[0].label = 'Speeders % (Peak)'
                this.chartJsData[1].label = 'Percentiles'

                this.chartJsData[0].data = [
                  this.chartDpsData.violatorsPercPeak,
                  this.chartDpsData.violatorsPercPeakBreak1,
                  this.chartDpsData.violatorsPercPeakBreak2,
                  this.chartDpsData.violatorsPercPeakBreak3,
                  this.chartDpsData.violatorsPercPeakBreak4,
                  null,
                  null,
                ],
                  this.chartJsData[1].data = [
                    null,
                    null,
                    null,
                    null,
                    null,
                    this.chartDpsData.percentile50,
                    this.chartDpsData.percentile85
                  ]
              }
              else {
                let tmpLabels: string[] = new Array(6)
                tmpLabels[0] = "Total"
                tmpLabels[1] = `> ${this.chartDpsData.speedBreak1} ${this.chartDpsData.unitSpeed}`
                tmpLabels[2] = `> ${this.chartDpsData.speedBreak2} ${this.chartDpsData.unitSpeed}`
                tmpLabels[3] = `> ${this.chartDpsData.speedBreak3} ${this.chartDpsData.unitSpeed}`
                tmpLabels[4] = this.chartDpsData.daysIncluded > 1 ? "50th (Averaged)" : "50th"
                tmpLabels[5] = this.chartDpsData.daysIncluded > 1 ? "85th (Averaged)" : "85th"

                this.chartJsLabels = tmpLabels

                this.chartJsData[0].label = 'Speeders % (Peak)'
                this.chartJsData[1].label = 'Percentiles'

                this.chartJsData[0].data = [
                  this.chartDpsData.violatorsPercPeak,
                  this.chartDpsData.violatorsPercPeakBreak1,
                  this.chartDpsData.violatorsPercPeakBreak2,
                  this.chartDpsData.violatorsPercPeakBreak3,
                  null,
                  null,
                ],
                  this.chartJsData[1].data = [
                    null,
                    null,
                    null,
                    null,
                    this.chartDpsData.percentile50,
                    this.chartDpsData.percentile85
                  ]
              }


              this.chartDisplay = true;
              this.chartJsOptions = this._chartJsOptions;
              this.getChartJsData_active = false;
            }
          },
          error => {
            this.getChartJsData_active = false;
            this.toastrService.error("Server Error", this.errorsService.errorParse(error), { tapToDismiss: true });
          });
  }

  // events
  public chartJsClicked(e: any): void {
    this.onChartJsEvent.emit("click")
    //console.log("chartJsClicked",e);
  }

  public chartJsHovered(e: any): void {
    //console.log(e);
  }

  getDeviceLocationSettings(): any{

    if (!this._device) {
      return;
    }

    return this.deviceLocationSettingsService.readByDeviceUuid(this.deviceUuid, true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.deviceLocationSettings = result;
          this.getChartJsData();
          // this.formState = this.formStates.Read;
        },
        error => {
          this.toastrService.error("Server Error (getDeviceLocationSettings)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });

  }

}
