import { Component, EventEmitter, OnInit, Input, Output, ViewChild, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { DeviceSchedulesService, ClientsService } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
import { ToastrService } from 'ngx-toastr';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { DeviceScheduleConfigurationsDto, DeviceScheduleDto, DeviceScheduleConfigurationEventsDto } from '../../../../models/deviceScheduleObjects';
import { ConfigMapOptionsQueryDto } from '../../../../models/configmap';
import { ColorsService } from '../../../../core/colors/colors.service';
import { BootstrapYearCalendarComponent } from '../../calendar/bootstrap-year-calendar.component';
import { BootstrapYearCalendarDataSource } from '../../calendar/model/bootstrap-year-calendar';
import * as _ from "lodash";
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject, } from 'rxjs';
import { takeUntil,filter } from 'rxjs/operators';

export enum DaySchedulesCalendarFormState {
  Initializing = 1,
  Read = 2,
  New = 3,
  Edit = 4,
  Save = 5,
  Saving = 6,
  Saved = 7,
  Cancelled = 8,
}

@Component({
  selector: 'app-dayschedulescalendar-form',
  templateUrl: './dayschedulescalendar-form.component.html',
  styleUrls: ['./dayschedulescalendar-form.component.scss'],
})

export class DaySchedulesCalendarFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dailyEventsCalendarView') public bootstrapCalendarCompnent: BootstrapYearCalendarComponent;
  @ViewChild('eventSchedulesControl') public eventSchedulesControl: NgSelectComponent;
  @Output() onDailyEventScheduleSelected: EventEmitter<any> = new EventEmitter();
  @Output() onFormEvent_EventSchedules: EventEmitter<any> = new EventEmitter<any>();

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public formStates = DaySchedulesCalendarFormState;
  private _formState: DaySchedulesCalendarFormState = DaySchedulesCalendarFormState.Initializing;
  get formState(): DaySchedulesCalendarFormState {
    return this._formState;
  }
  @Input() set formState(newFormState: DaySchedulesCalendarFormState) {
    let updateFormState: DaySchedulesCalendarFormState = null;

    this.fgDailyEventsCalendar.disable();
    this.bootstrapCalendarCompnent.CalendarRangeSelection(false);

    switch (newFormState) {
      case this.formStates.New: {
        updateFormState = newFormState;
        this.fgDailyEventsCalendar.enable();

        this.fgDailyEventsCalendar.patchValue({
          sequence: null,
          dateRange: null,
          applyAuto: true,
          applyType: '0'
        });
        this.bootstrapCalendarCompnent.CalendarRangeSelection(true);

        break;
      }
      case this.formStates.Edit: {
        updateFormState = newFormState;
        this.fgDailyEventsCalendar.enable();

        this.fgDailyEventsCalendar.patchValue({
          sequence: null,
          dateRange: null,
        });
        this.bootstrapCalendarCompnent.CalendarRangeSelection(true);

        //this.dailyEventScheduleEdit();
        break;
      }
      case this.formStates.Save: {
        this._formState = newFormState;
        this.fgDailyEventsCalendar.enable();
        //this.dailyEventScheduleSave();
        break;
      }
      case this.formStates.Cancelled: {
        //this.dailyEventScheduleCancel();
        break;
      }
      default: {
        updateFormState = newFormState;
      }
    }

    if (updateFormState != null) {
      this._formState = updateFormState;
    }
  }

  public CalendarOptions: any[];
  public CalendarDateBegin: Date = null;
  public CalendarDateEnd: Date = null;
  public CalendarUndo: any[] = new Array();

  private _deviceSchedule: DeviceScheduleDto;
  get DeviceSchedule(): DeviceScheduleDto {
    return this._deviceSchedule;
  }
  @Input() set DeviceSchedule(newDeviceSchedule: DeviceScheduleDto) {
    this._deviceSchedule = newDeviceSchedule;
    this._dailyEventSchedules = newDeviceSchedule.deviceScheduleConfigurations

    setTimeout(() => {
      if (this.eventSchedulesControl)
        this.eventSchedulesControl.itemsList.setItems(this._dailyEventSchedules)
      this.CalendarDataSourceBuild()
    }, 200);

  }

  private _dailyEventSchedule: DeviceScheduleConfigurationsDto;
  get DailyEventSchedule(): DeviceScheduleConfigurationsDto {
    return this._dailyEventSchedule;
  }
  set DailyEventSchedule(newDailyEventSchedule: DeviceScheduleConfigurationsDto) {

    this._dailyEventSchedule = newDailyEventSchedule;

    if (newDailyEventSchedule.sequence === 0) {
      this.formState = this.formStates.New;
    }
    //else this.formState = this.formStates.Edit;

  }
  public dailyEventsCalendarTemp: DeviceScheduleConfigurationsDto[] = [];
  public dailyEventsCalendarSelected: DeviceScheduleConfigurationsDto[] = [];
  private _dailyEventSchedules: DeviceScheduleConfigurationsDto[] = [];
  get DailyEventsCalendar(): DeviceScheduleConfigurationsDto[] {
    return this._dailyEventSchedules;
  }
  @Input() set DailyEventsCalendar(newDailyEventsCalendar: DeviceScheduleConfigurationsDto[]) {

    this._dailyEventSchedules = newDailyEventsCalendar;
    //probably need to do refresh or something here
    this.formState = this.formStates.Read;
  }

  get isEditMode(): boolean {
    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving:
      case this.formStates.Saved:
        {
          return true;
        }
      default:
        {
          return false;
        }
    }
  }

  public isModified = false;
  public showDailyEventScheduleForm: boolean = false;

  constructor(
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public deviceSchedulesService: DeviceSchedulesService,
    public clientsService: ClientsService,
    public errorsService: ErrorsService,
    public librariesService: LibrariesService,
    public colorsService: ColorsService,
    private formBuilder: UntypedFormBuilder
  ) { }


  public fgDailyEventsCalendar: UntypedFormGroup = this.formBuilder.group({
    sequence: null,
    dateRange: null,
    applyAuto: true,
    applyType: '0'
  });

  ngOnInit() {

    // this.eventsService.currentMessage.pipe(filter(message => message.toString() !== ''))
    //   .pipe(takeUntil(this.onDestroy$))
    //   .subscribe(
    //     message => {
    //       setTimeout(() => {
    //         this.formState = DaySchedulesCalendarFormState.Initializing;
    //       }, 1000);

    //     }
    //   );

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {

  }

  getDailyEventSchedule(): any {
    //this.dailyEventsCalendar = null;

    if (!this.settingsService.client || !this.DailyEventSchedule) {
      return;
    }

    //do something, maybe not...

  }

  controlValidationClass(control) {
    return 'form-control'
      + (this.controlInvalidFlag(control) != null ? (this.controlInvalidFlag(control) ? ' is-invalid' : ' is-valid') : '');
  }

  controlInvalidFlag(control) {
    if (!control.touched) {
      return null;
    }
    return control.invalid;
  }

  onEventScheduleEmitter(event) {

    switch (event.toString().toLowerCase()) {
      case 'add': {
        //alert('schedules add');
        break;
      }
      case 'edit': {
        //alert('schedules edit');
        break;
      }
      case 'ok': {
        break;
      }
      case 'cancel': {

        break;
      }
      default: {
        //statements; 
        break;
      }
    }

  }


  onDayContextMenu(event) {
    console.log('dayContextMenu', event)
  }
  onClickDay(event) {
  }

  onControlSelectChange(event) {
    if (event)
    {

      this.CalendarDateBegin =  event[0];
      this.CalendarDateEnd = event[1];

      let today: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
      let cancelApply: boolean = false;
  
      if (this.CalendarDateBegin < today ||
        this.CalendarDateEnd < today) {
  
        if (this.CalendarDateEnd == this.CalendarDateBegin && this.CalendarDateBegin < today) {
          this.CalendarDateBegin = null;
          this.CalendarDateEnd = null;
          cancelApply = true;
        }
        else {
          if (this.CalendarDateBegin < today) this.CalendarDateBegin = today
          if (this.CalendarDateEnd < today) this.CalendarDateEnd = today
        }
        this.toastrService.warning("Date Range Warning ", "Selected Dates Cannot Be Prior To Today",
          {
            timeOut: 5000,
            progressBar: true,
            tapToDismiss: true
          });
      }
  
      if (!cancelApply && this.fgDailyEventsCalendar.value.applyAuto) {
        setTimeout(() => {
          this.ApplyRange(+this.fgDailyEventsCalendar.value.applyType);
        }, 300);
      }

    }
  }

  onCalendarSelectRange(event) {
    if (event && event.startDate && event.endDate) {
      this.fgDailyEventsCalendar.patchValue({
        dateRange: [event.startDate, event.endDate]
      })
    }
  }


  onCalendarSelectRangeOLD(event, doPatchValue: boolean = true) {
    this.CalendarDateBegin = event.startDate
    this.CalendarDateEnd = event.endDate

    let today: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    let cancelApply: boolean = false;

    if (this.CalendarDateBegin < today ||
      this.CalendarDateEnd < today) {

      if (this.CalendarDateEnd == this.CalendarDateBegin && this.CalendarDateBegin < today) {
        this.CalendarDateBegin = null;
        this.CalendarDateEnd = null;
        cancelApply = true;
      }
      else {
        if (this.CalendarDateBegin < today) this.CalendarDateBegin = today
        if (this.CalendarDateEnd < today) this.CalendarDateEnd = today
      }
      this.toastrService.warning("Date Range Warning ", "Selected Dates Cannot Be Prior To Today",
        {
          timeOut: 5000,
          progressBar: true,
          tapToDismiss: true
        });
    }

    if (doPatchValue) {
      this.fgDailyEventsCalendar.patchValue({
        dateRange: [this.CalendarDateBegin, this.CalendarDateEnd]
      })
    }

    if (!cancelApply && this.fgDailyEventsCalendar.value.applyAuto) {
      this.ApplyRange(+this.fgDailyEventsCalendar.value.applyType);
    }

  }

  onSelectRangeOLD(event) {
    console.log('selectRange', event)
    this.CalendarDateBegin = event.startDate
    this.CalendarDateEnd = event.endDate
      ;
    if (!this.dailyEventsCalendarSelected || this.dailyEventsCalendarSelected.length <= 0) {
      //alert('nodata dailyEventsCalendarSelected')
      return;
    }

    let calData = this.bootstrapCalendarCompnent.CalendarDataSource;
    if (!calData) calData = new Array<BootstrapYearCalendarDataSource>();

    //clear entries
    let dateCurr: Date = _.cloneDeep(this.CalendarDateBegin);
    do {

      let dateChk = new Date(dateCurr.getFullYear(), dateCurr.getMonth(), dateCurr.getDate());

      var elementPos = calData.map(function (x) {
        console.log(dateChk.toString(), x.startDate.toString())
        return x.startDate.toString();
      }).indexOf(dateChk.toString());

      //let chk = calData.filter(cal => cal.startDate == dateChk)

      if (elementPos >= 0) {
        calData.splice(elementPos, 1)
      }

      dateCurr.setDate(dateCurr.getDate() + 1);
      //alert('delete '+dateCurr+'  '+this.CalendarDateEnd)
    } while (dateCurr <= this.CalendarDateEnd)

    //add entries
    let idMax = Math.max.apply(Math, calData.map(function (o) { return o.id; }))
    if (idMax <= 0) idMax = 0;

    dateCurr = _.cloneDeep(this.CalendarDateBegin);
    do {

      let evnt = new BootstrapYearCalendarDataSource();
      idMax += 1

      evnt.id = idMax;
      evnt.name = this.dailyEventsCalendarSelected[0].name
      evnt.startDate = new Date(dateCurr.getFullYear(), dateCurr.getMonth(), dateCurr.getDate())
      evnt.endDate = new Date(dateCurr.getFullYear(), dateCurr.getMonth(), dateCurr.getDate())
      evnt.color = "#" + this.dailyEventsCalendarSelected[0].colorCode

      calData.push(evnt);

      dateCurr.setDate(dateCurr.getDate() + 1);
      //alert('add '+dateCurr+'  '+this.CalendarDateEnd)
    } while (dateCurr <= this.CalendarDateEnd)

    this.bootstrapCalendarCompnent.CalendarDataSource = calData;

  }

  onSelect({ selected }) {
    return;
    // if (!this.isButtonDelete)
    // {
    //   if (selected) {
    //     this.dailyEventsCalendarSelected.splice(0, this.dailyEventsCalendarSelected.length);

    //     this.showDailyEventScheduleForm = true;
    //     this.eventScheduleForm.DailyEventSchedule = selected[0];

    //     if (this.formState == this.formStates.Edit) this.eventScheduleForm.formState = this.eventScheduleForm.formStates.Edit
    //     else this.eventScheduleForm.formState = this.eventScheduleForm.formStates.Read

    //     this.onFormEvent_EventSchedules.emit('edit');


    //   }
    // }
    //this.isButtonDelete = false;
  }

  ApplyRange(scopeId: number = 0) {
    //Scope Ids
    // 0 = All Dates
    // 1 = Weekdays
    // 2 = Weekends
    if (this.fgDailyEventsCalendar.value &&
      this.fgDailyEventsCalendar.value.dateRange) {
      this.CalendarDateBegin = this.fgDailyEventsCalendar.value.dateRange[0];
      this.CalendarDateEnd = this.fgDailyEventsCalendar.value.dateRange[1];
    }

    if (!this.CalendarDateBegin || !this.CalendarDateEnd) return

    let today: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    let calData: BootstrapYearCalendarDataSource[] = _.cloneDeep(this.bootstrapCalendarCompnent.CalendarDataSource);
    this.CalendarUndo.push(this.bootstrapCalendarCompnent.CalendarDataSource)

    if (!calData) calData = new Array<BootstrapYearCalendarDataSource>();

    //clear entries
    let dateCurr: Date = _.cloneDeep(this.CalendarDateBegin);
    do {

      let dateChk = new Date(dateCurr.getFullYear(), dateCurr.getMonth(), dateCurr.getDate());

      var elementPos = calData.map(function (x) {
        //console.log(dateChk.toString(), x.startDate.toString())
        return x.startDate.toString();
      }).indexOf(dateChk.toString());

      //let chk = calData.filter(cal => cal.startDate == dateChk)

      if (elementPos >= 0 && dateCurr >= today &&
        (scopeId == 0 ||
          (scopeId == 1 && dateChk.getDay() >= 1 && dateChk.getDay() <= 5) ||
          (scopeId == 2 && (dateChk.getDay() == 0 || dateChk.getDay() == 6))
        )
      ) {
        calData.splice(elementPos, 1)
      }

      dateCurr.setDate(dateCurr.getDate() + 1);
      //alert('delete '+dateCurr+'  '+this.CalendarDateEnd)
    } while (dateCurr <= this.CalendarDateEnd)

    //add entries
    let idMax = Math.max.apply(Math, calData.map(function (o) { return o.id; }))
    if (idMax <= 0) idMax = 0;

    if (this.eventSchedulesControl.selectedItems.length > 0) {
      dateCurr = _.cloneDeep(this.CalendarDateBegin);
      do {

        if (dateCurr >= today &&
          (scopeId == 0 ||
            (scopeId == 1 && dateCurr.getDay() >= 1 && dateCurr.getDay() <= 5) ||
            (scopeId == 2 && (dateCurr.getDay() == 0 || dateCurr.getDay() == 6))
          )) {
          let itemValue: any = this.eventSchedulesControl.selectedItems[0].value;
          let evnt = new BootstrapYearCalendarDataSource();

          idMax += 1

          evnt.id = itemValue.sequence
          evnt.name = itemValue.name
          evnt.startDate = new Date(dateCurr.getFullYear(), dateCurr.getMonth(), dateCurr.getDate())
          evnt.endDate = new Date(dateCurr.getFullYear(), dateCurr.getMonth(), dateCurr.getDate())
          evnt.color = "#" + itemValue.colorCode

          calData.push(evnt);
        }
        dateCurr.setDate(dateCurr.getDate() + 1);
        //alert('add '+dateCurr+'  '+this.CalendarDateEnd)
      } while (dateCurr <= this.CalendarDateEnd)
    }

    // console.log(calData)
    // console.log(this.CalendarUndo)
    this.bootstrapCalendarCompnent.CalendarDataSource = calData;

    // if (this.CalendarDateBegin < today)
    // {
    //   this.notificationsService.warn("Some Dates Omitted", "Dates prior to today cannot be changed", 
    //   {
    //       timeOut: 5000,
    //       showProgressBar: true,
    //       clickToClose: true,
    //       clickIconToClose: true
    //   });
    // }
    this.CalendarDataSourceSave();

  }

  CalendarDataSourceBuild() {

    let calData = new Array<BootstrapYearCalendarDataSource>();

    for (let sch of this._dailyEventSchedules) {
      for (let dte of sch.calendarDates) {

        let dteAdd = new Date(dte);
        //console.log('CalendarDataSourceBuild: calendarDates',sch.calendarDates)

        let evnt = new BootstrapYearCalendarDataSource();

        evnt.id = sch.sequence
        evnt.name = sch.name
        evnt.startDate = new Date(dteAdd.getFullYear(), dteAdd.getMonth(), dteAdd.getDate())
        evnt.endDate = new Date(dteAdd.getFullYear(), dteAdd.getMonth(), dteAdd.getDate())
        evnt.color = "#" + sch.colorCode

        calData.push(evnt);
      }
    }

    var is_same = (calData.length == this.bootstrapCalendarCompnent.CalendarDataSource.length) && calData.every(function (element, index) {
      return element === this.bootstrapCalendarCompnent.CalendarDataSource[index];
    });

    //console.log('CalendarDataSourceBuild',this._dailyEventSchedules, calData)

    if (!is_same) this.bootstrapCalendarCompnent.CalendarDataSource = calData;

  }

  CalendarDataSourceSave() {
    let calData: BootstrapYearCalendarDataSource[] = this.bootstrapCalendarCompnent.CalendarDataSource;

    for (let sch of this._dailyEventSchedules) {

      sch.calendarDates = calData.filter(dte => dte.id == sch.sequence).map(m => m.startDate);

    }

    console.log(this.DeviceSchedule);
  }


  ApplyUndo(event) {
    if (this.CalendarUndo.length > 0) {
      let calData = this.CalendarUndo.splice(this.CalendarUndo.length - 1, 1)[0];
      console.log(calData)
      this.bootstrapCalendarCompnent.CalendarDataSource = calData;
    }
  }

  buttonApplyAll(event) {
    console.log(this.eventSchedulesControl.selectedItems)
  }

}
