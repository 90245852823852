<div class="row">
  <div class="col-lg-12">
    <!-- START widget-->
    <div class="card bg-default">
      <div class="card-header">
        <div class="row">
          <div class="col-12 col-md-4">
            <h4 *ngIf="!profileForm" class="pt-2 mb-0">Users{{showForm ? " - User" : " - Listing"}}{{ showForm &&
              userForm && userForm.isEditMode
              ? (userForm.formState == userForm.formStates.New ? " - Add" : " - Edit") : "" }}
            </h4>
            <h4 *ngIf="profileForm" class="pt-2 mb-0">Users{{showForm ? " - User Profile Settings" : " - Listing"}}{{ showForm &&
              profileForm && profileForm.isEditMode
              ? (profileForm.formState == profileForm.formStates.New ? " - Add" : " - Edit") : "" }}
            </h4>
          </div>
          <div class="col-12 col-md-8 float-right text-right">
            <button *ngIf="!showForm && settingsService.userAccessCheck(userTypes.EndUser,userRoles.Administrator)"
              class="btn btn-primary" (click)="openEmailModal(emailModal)" [disabled]="showForm">
              <span class="fas fa-plus-square pr-2"></span>Add</button>
            <button *ngIf="userForm && showForm" [disabled]="userForm.formState == userForm.formStates.Saving"
              type="button" class="btn btn-primary ml-2" (click)="buttonBack($event)">
              <span class="fa fas fa-th-list pr-2"></span>Listing</button>
            <button *ngIf="userForm && showForm && !userForm.isEditMode && !enableEditForm &&
              settingsService.userAccessCheck(userTypes.EndUser,userRoles.Administrator)"
              [disabled]="userForm.formState != userForm.formStates.Read && enableEditForm" type="button"
              class="btn btn-primary ml-2" (click)="buttonEdit($event)" id="highlight">
              <span class="fas fa-edit pr-2"></span>Edit</button>
            <button *ngIf="profileForm && showForm && !profileForm.isEditMode && !enableEditForm"
              [disabled]="profileForm.formState != profileForm.formStates.Read && enableEditForm" type="button"
              class="btn btn-primary ml-2" (click)="buttonEdit($event)" id="highlight">
              <span class="fas fa-edit pr-2"></span>Edit</button>
            <button *ngIf="(userForm && showForm && userForm.isEditMode) || (userForm && enableEditForm)"
              [disabled]="userForm.formState != userForm.formStates.New && userForm.formState != userForm.formStates.Edit && !enableEditForm"
              type="button" class="btn btn-success ml-2" (click)="buttonSave($event)">
              <span class="fas fa-save pr-2"></span>Save</button>
            <button *ngIf="(profileForm && showForm && profileForm.isEditMode) || (profileForm && enableEditForm)"
              [disabled]="profileForm.formState != profileForm.formStates.New && profileForm.formState != profileForm.formStates.Edit && !enableEditForm"
              type="button" class="btn btn-success ml-2" (click)="buttonSave($event)">
              <span class="fas fa-save pr-2"></span>Save</button>
            <button *ngIf="(userForm && showForm && userForm.isEditMode && (tknUserUuid != userForm.userUuid)) || (userForm && enableEditForm && (tknUserUuid != userForm.userUuid))"
              [disabled]="userForm.formState != userForm.formStates.New && userForm.formState != userForm.formStates.Edit && !enableEditForm"
              type="button" class="btn btn-danger ml-2" (click)="openDeleteModal(confirmDeleteModal)">
              <span class="fas fa-trash pr-2"></span>Delete</button>
            <button
              *ngIf="(userForm && showForm && userForm.isEditMode && userForm.formState == userForm.formStates.Edit) || (userForm && enableEditForm)"
              [disabled]="userForm.formState != userForm.formStates.Edit && !enableEditForm" type="button"
              class="btn btn-cancel ml-2" (click)="buttonCancel($event)">
              <span class="fas fa-ban pr-2"></span>Cancel</button>
            <button
              *ngIf="(profileForm && showForm && profileForm.isEditMode && profileForm.formState == profileForm.formStates.Edit) || (profileForm && enableEditForm)"
              [disabled]="profileForm.formState != profileForm.formStates.Edit && !enableEditForm" type="button"
              class="btn btn-cancel ml-2" (click)="buttonCancel($event)">
              <span class="fas fa-ban pr-2"></span>Cancel</button>
          </div>
        </div>

        <div *ngIf='showForm && !showProfile' class="bg-body mt-2">
          <app-user-client-form #userForm [userUuid]="userUuid" [enableEditForm]="imEditMode"
            (onFormEvent)="onFormEvent($event)"></app-user-client-form>
        </div>

        <div *ngIf="showForm && showProfile" class="bg-body mt-2">
          <app-user-profile-form #profileForm [userUuid]="userUuid" [enableEditForm]="enableEditForm"
            (onFormEvent)="onFormEvent($event)"></app-user-profile-form>
        </div>
      </div>

      <div *ngIf="!showForm" class="bg-body mt-2">
        <app-users-view #userView (onViewSelected)="onViewSelected($event)"></app-users-view>
      </div>

      
    </div>
    <!-- END widget-->

  </div>
</div>
<ng-template #emailModal>
  <div class="modal-header">
    <h3 class="m-0">Enter Email Address Of New User</h3>
  </div>
  <div class="modal-body text-center">
    <form [formGroup]="fgEmail">
      <div class="col-sm-12 col-12 mb-2">
        <input id="userEmail" name="userEmail" formControlName="userEmail" type="text" placeholder="Email"
          autocomplete="off" [class]="librariesService.controlValidationClass(fgEmail.get('userEmail'))" />
        <div *ngIf="librariesService.controlInvalidFlag(fgEmail.get('userEmail'))" class="invalid-feedback">
          Please provide a valid Email</div>
      </div>
    </form>
    <button type="button" class="btn btn-danger mr-3" (click)="emailModalConfirm()">Ok</button>
    <button type="button" class="btn btn-cancel" (click)="emailModalCancel()">Cancel</button>
  </div>
</ng-template>

<ng-template #confirmDeleteModal>
  <div class="modal-header">
    <h3 class="m-0">Confirm Delete</h3>
  </div>
  <div class="modal-body text-center">
    <i class="fas fa-trash-alt fa-2x bg-primary rounded p-3"></i>
    <h4 class="pt-3 m-1">Are you sure you want to delete {{userForm?.fgUser?.get('email').value}}?</h4>
    <h6 class="pt-3 pb-3">(Deletion is immediate)</h6>
    <button type="button" class="btn btn-delete mr-3" (click)="userDeleteConfirm()">DELETE</button>
    <button type="button" class="btn btn-cancel" (click)="buttonDeleteCancel()">Cancel</button>
  </div>
</ng-template>