
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, ElementRef, ViewEncapsulation, ViewChild, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Observable, Subscription, Subject, fromEvent } from 'rxjs';
import { MenuService } from '../../../../core/menu/menu.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { DevicesService } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
// import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { DataArraysCompareOutput } from '../../../../models/DataArraysCompareOutput';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions, SizeColumnsToContentStrategy } from 'ag-grid-community';
import { customAgTheme } from 'app/shared/styles/themes/theme-ag-custom';
import { element } from 'protractor';


@Component({
  selector: 'app-devicesview',
  templateUrl: './devicesview.component.html',
  styleUrls: ['./devicesview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DevicesViewComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('agGrid') grid!: AgGridAngular;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();
  private dataRefreshSubscription: Subscription;

  public devicesTemp: any[] = [];
  public devices: any[] = [];
  public devicesSelected: any[] = [];

  @Input() public chartView: boolean = false;

  private _connectivityTypeId: number = -1;
  get connectivityTypeId(): number {
    return this._connectivityTypeId;
  }
  @Input() set connectivityTypeId(Id: number) {
    this._connectivityTypeId = Id
    if (this.datatableFilter) this.updateFilter(this.datatableFilter.nativeElement.value)

  }


  @Output() onDeviceSelected: EventEmitter<any> = new EventEmitter()
  // @ViewChild(DatatableComponent) dataView: DatatableComponent;
  @ViewChild('datatableFilter') datatableFilter: ElementRef;
  @Output() onDataLoading: EventEmitter<boolean> = new EventEmitter()
  public dataLoading: boolean = false;
  public firstLoad: boolean = true;

  //ag-grid variables start

  public theme = customAgTheme;

  public gridOptions: GridOptions;
  public chartThemes;
  public rowStyle;
  public isSelected: boolean = false;

  private _gridData: any = null;
  get gridData(): any {
    return this._gridData;
  }
  @Input() set gridData(newGridData: any) {
    this._gridData = newGridData;
  }

  constructor(
    public menu: MenuService,
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    public devicesService: DevicesService,
    public errorsService: ErrorsService,
    private router: Router,
    private route: ActivatedRoute) {

      

      this.chartThemes = [
        'ag-pastel',
        'ag-default',
        'ag-material-dark',
        'ag-vivid-dark',
        'ag-solar',
      ];
      this.rowStyle = {
        cursor: 'pointer'
      }
      this.gridOptions = <GridOptions>{
        rowData: this.gridData,
        columnDefs: this.columnDefs,
        rowStyle: this.rowStyle,
        rowHeight: 40,
        rowSelection: 'multiple',
        cursor: "pointer",
        suppressRowClickSelection: true,
        onCellClicked: (e) => {
          e.node.setSelected(true)
          this.isSelected = true;
        },
        //chartThemeOverrides: this.chartThemeOverrides,
        enableCharts: false,
        enableRangeSelection: false
        // pagination: true,
        // rowSelection: 'single'
      }
  }

  public domLayout;



  columnDefs = [
    {
      headerName: "Type", field: "connectivityTypeId", sortable: true, resizable: true, suppressMenu: true,
      cellRenderer: (data) => {
        let myType = '';

        if (data.data.connectivityTypeId == 0o0) {
          myType = '<div class="mt-2 text-primary"><em class="fas fa-wifi fa-2x"></em></div>'
        }
        if (data.data.connectivityTypeId == 10) {
          myType = '<div class="mt-2 text-green"><em class="fas fa-signal-alt fa-2x"></em></div>'
        }
        return myType;
      },
      cellStyle: {
        padding: '0 0 0 1.5em',
      }
    },

    {
      headerName: "Serial", field: "serial", sortable: true, suppressRowClickSelection: true, resizable: true, suppressMenu: true,
      cellRenderer: (data) => {
        let myVar = data;
        return `<a href="administration/devices/${data.data.uuid}" style="color: #6C757D;">${data.value}</a>`;
      }
    },
    { headerName: "Model", field: "model.code", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Name/Label", field: "label", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Schedule", field: "deviceSchedule.name", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Current Location Name", field: "deviceLocation.name", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Nearest Street Address", field: "deviceLocation.address", sortable: true, resizable: true, suppressMenu: true },
    {
      headerName: "Last Communication", field: "lastActivity", sortable: true, resizable: true, suppressMenu: true, flex: 1,
      cellRenderer: (data) => {
        return data.value ? (data.value != null
          ? (this.librariesService.datePipe.transform(new Date(data.value), 'MM-dd-yyyy HH:mm:ss'))
          : '') : '';
      }
    }
  ];

  autoSizeStrategy:
    SizeColumnsToContentStrategy = {
      type: "fitCellContents",
      colIds: [ "connectivityTypeId", "serial", "model.code", "label", "deviceSchedule.name", "deviceLocation.name", "deviceLocation.address" ]
    };

  ngOnInit(): void {
    console.log(this.route.routeConfig.component.name + ":ngOnInit", this)

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.toLowerCase().split("?")[0] == '/devices') {
          console.log(this.route.routeConfig.component.name + ":NavigationEnd")
        }
      }
    });

    this.dataRefreshSubscription = this.eventsService.dataRefresh$
    .pipe(

      filter(value => value !== null)

    )
    .subscribe(() => {
        this.dataRefresh();
    });

  }
  ngOnDestroy(): void {
    this.dataRefreshSubscription.unsubscribe();
    this.onDestroy$.next();
  }

  ngAfterViewInit(): void {
    console.log(this.route.routeConfig.component.name + ":ngAfterViewInit", this)
    this.dataRefresh();

  }


  dataRefresh() {
    var self = this;
    if (!this.settingsService.client) {
      setTimeout(() => {
        self.dataRefresh();
      }, 100);
    }
    else {
      setTimeout(() => {
        self.getDevices()
      }, 50);
    }
  }

  onResize(event) {
    this.grid.api.sizeColumnsToFit();
  
  }

  public chartDevices: any[] = [];

  getDevices(): any {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 25);

    if (!this.settingsService.client) return;
    this.dataLoading = true;
    this.onDataLoading.emit(this.dataLoading)
    if (this.dataLoading) this.settingsService.showSpinner("devices", false, 250);


    if (this.chartView == false) {
      return this.devicesService.read()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {
            let resetOffset: boolean = this.devices.length == 0

            this.devices = result;
            this.devicesTemp = result;

            this.dataLoading = false;
            this.onDataLoading.emit(this.dataLoading)
            if (!this.dataLoading) this.settingsService.hideSpinner("devices");

          },
          error => {
            this.toastrService.error("Server Error (getDevices)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
            this.dataLoading = false;
            this.onDataLoading.emit(this.dataLoading)
          });
    }
    else {
      
      return this.devicesService.read()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {

            this.devices = [];
            result.forEach(element => {
              if (element.deviceLocation != null) {
                this.devices.push(element);
              }
            });

            this.devicesTemp = this.devices;


            // this.grid.api.setColumnDefs(this.columnDefs);
            // this.grid.api.setRowData(this.devices);

            this.dataLoading = false;


            this.onDataLoading.emit(this.dataLoading)
            if (!this.dataLoading) this.settingsService.hideSpinner("devices");

          },
          error => {
            this.toastrService.error("Server Error (getDevices)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
            this.dataLoading = false;
            this.onDataLoading.emit(this.dataLoading)
          }
        );
    }
  }


  onRowClicked(event) {
    console.log(event)

    if (this.isSelected && !this.chartView) {
      this.router.navigate(['/devices/', event.node.data.uuid]);
    }

    else if (this.isSelected && this.chartView) {
      console.log(event.node)
      this.router.navigate(['/charts/', event.node.data.deviceLocation.uuid]);
    }

  }

  getChartToolbarItems() {
    return ['chartDownload', 'chartData', 'chartFormat']

  }

  updateFilter(event) {
    // filter our data

    const val = event.target.value.toLowerCase();
    const temp = this.devicesTemp.filter(function (d) {
      return (
        (d.deviceLocation != null && d.deviceLocation.name.toLowerCase().indexOf(val) !== -1) ||
        (d.deviceGroup != null && d.deviceGroup.label.toLowerCase().indexOf(val) !== -1) ||
        (d.serial || "").toLowerCase().indexOf(val) !== -1 ||
        (d.model != null && d.model.code.toLowerCase().indexOf(val) !== -1) ||
        (d.deviceSchedule != null && d.deviceSchedule.name.toLowerCase().indexOf(val) !== -1) ||
        (d.label || "").toLowerCase().indexOf(val) !== -1 ||
        (d.deviceLocation != null && d.deviceLocation.address != null && d.deviceLocation.address.toLowerCase().indexOf(val) !== -1) ||
        !val


      );
    })
    // update the rows
    this.devices = temp;
  }

  onSelect({ selected }) {
    this.devicesSelected.splice(0, this.devicesSelected.length);
    if (selected && selected[0].uuid) {
      console.log(this.route.routeConfig.component.name + ":onSelect Event", selected);
      this.onDeviceSelected.emit(selected[0]);
      //this.router.navigate(['/administration/devices', selected[0].uuid])
      //alert(selected[0].uuid)
    }
  }


  getLocationAddressDisplay(device: any): string {
    if (!device.deviceLocation) return ''

    var display: string = device.deviceLocation.city || ''

    if (display.length > 0 && device.deviceLocation.stateProvinceCode && device.deviceLocation.stateProvinceCode.length > 0) display = display + ', '
    display = display + (device.deviceLocation.stateProvinceCode || '')

    if (display.length > 0 && device.deviceLocation.postalCode && device.deviceLocation.postalCode.length > 0) display = display + ', '
    display = display + (device.deviceLocation.postalCode || '')

    if (display.length > 0 && device.deviceLocation.country && device.deviceLocation.country.length > 0) display = display + ', '
    display = display + (device.deviceLocation.country || '')

    return display
  }

  onRowSelected(event) {
    //console.log(event);
  }

}
