import { Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'app-under-construction',
    templateUrl: './under-construction.component.html',
    styleUrls: ['./under-construction.component.scss']
})
export class UnderConstructionComponent implements OnInit {

    @Input() public Header: string = "";
    @Input() public Title: string = "";
    @Input() public Description: string = "";

    constructor() { }

    ngOnInit() {
    }

}
