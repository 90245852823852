import { Component, EventEmitter, OnInit, Input, Output, ViewChild, Host, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { DeviceSchedulesService, ClientsService } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
import { ToastrService } from 'ngx-toastr';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { DeviceScheduleConfigurationEventsDto, DeviceScheduleConfigurationsDto, DeviceScheduleDto } from '../../../../models/deviceScheduleObjects';
import { ConfigMapOptionsQueryDto } from '../../../../models/configmap';
import { ColorsService } from '../../../../core/colors/colors.service';
import { DaySchedulesTimeConfigFormComponent } from '../dayschedulestimeconfig/dayschedulestimeconfig-form.component';
import * as _ from "lodash";
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';


export enum DailyEventScheduleTimeFormState {
  Initializing = 1,
  Read = 2,
  New = 3,
  Edit = 4,
  Save = 5,
  Saving = 6,
  Saved = 7,
  Cancelled = 8,
}

@Component({
  selector: 'app-dayschedulestime-form',
  templateUrl: './dayschedulestime-form.component.html',
  styleUrls: ['./dayschedulestime-form.component.scss']
})

export class DaySchedulesTimeFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('eventScheduleTimeConfigForm') public eventScheduleTimeConfigForm: DaySchedulesTimeConfigFormComponent;
  @Output() onFormEvent_EventScheduleTime: EventEmitter<any> = new EventEmitter<any>();

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public formStates = DailyEventScheduleTimeFormState;
  private _formState: DailyEventScheduleTimeFormState = DailyEventScheduleTimeFormState.Initializing;
  get formState(): DailyEventScheduleTimeFormState {
    return this._formState;
  }
  @Input() set formState(newFormState: DailyEventScheduleTimeFormState) {
    let updateFormState: DailyEventScheduleTimeFormState = null;

    this.fgDailyEventScheduleTime.disable();

    switch (newFormState) {
      case this.formStates.Read: {
        updateFormState = newFormState;
        this.fgDailyEventScheduleTime.disable();
        this.eventScheduleTimeConfigForm.formState = this.eventScheduleTimeConfigForm.formStates.Read;
        break;
      }
      case this.formStates.New: {
        updateFormState = newFormState;
        this.fgDailyEventScheduleTime.enable();
        this.eventScheduleTimeConfigForm.formState = this.eventScheduleTimeConfigForm.formStates.New;
        this._dailyEventScheduleTimeIndex = -1;
        this.eventScheduleTimeNew();
        break;
      }
      case this.formStates.Edit: {
        updateFormState = newFormState;
        this.fgDailyEventScheduleTime.enable();
        this.eventScheduleTimeConfigForm.formState = this.eventScheduleTimeConfigForm.formStates.Edit;
        this.eventScheduleTimeEdit();
        break;
      }
      case this.formStates.Save: {
        this._formState = newFormState;
        this.fgDailyEventScheduleTime.enable();
        //this.eventScheduleTimeSave();
        break;
      }
      case this.formStates.Cancelled: {
        //this.eventScheduleTimeCancel();
        break;
      }
      default: {
        updateFormState = newFormState;
      }
    }

    if (updateFormState != null) {
      this._formState = updateFormState;
    }
  }
  private _deviceSchedule: DeviceScheduleDto;
  get DeviceSchedule(): DeviceScheduleDto {
    return this._deviceSchedule;
  }
  @Input() set DeviceSchedule(newDeviceSchedule: DeviceScheduleDto) {
    this._deviceSchedule = newDeviceSchedule;
  }

  private _dailyEventSchedule: DeviceScheduleConfigurationsDto;
  get DailyEventSchedule(): DeviceScheduleConfigurationsDto {
    return this._dailyEventSchedule;
  }
  @Input() set DailyEventSchedule(newDailyEventSchedule: DeviceScheduleConfigurationsDto) {
    this._dailyEventSchedule = newDailyEventSchedule;
  }

  private _configMapOptions: ConfigMapOptionsQueryDto[];
  get ConfigMapOptions(): ConfigMapOptionsQueryDto[] {
    return this._configMapOptions;
  }
  @Input() set ConfigMapOptions(newConfigMapOptions: ConfigMapOptionsQueryDto[]) {
    this._configMapOptions = newConfigMapOptions;
  }

  public _dailyEventScheduleTimeIndex = -1;
  private _dailyEventScheduleTime: DeviceScheduleConfigurationEventsDto;
  get DailyEventScheduleTime(): DeviceScheduleConfigurationEventsDto {
    return this._dailyEventScheduleTime;
  }
  @Input() set DailyEventScheduleTime(newDailyEventScheduleTime: DeviceScheduleConfigurationEventsDto) {

    this._dailyEventScheduleTime = newDailyEventScheduleTime;

    this.formState = this.formStates.Initializing;
    this._dailyEventScheduleTimeIndex = -1;

    var newTimeControl: Date = new Date(
      this.librariesService.datePipe.transform(new Date(), 'yyyy-MM-dd') + 'T01:00' + this.librariesService.getUserTimezoneOffsetString())
    // var newTimeControl: Date = new Date('2000-01-01T01:00' + this.librariesService.getUserTimezoneOffsetString() )
    //console.log("newTimeControl", '2000-01-01T01:00' + this.librariesService.getUserTimezoneOffsetString() )
    console.log("newTimeControl", newTimeControl)

    if (newDailyEventScheduleTime) {

      if (this.DailyEventScheduleTime.time == "") this.DailyEventScheduleTime.time = "00:00"
      if (this.DailyEventScheduleTime.time == "00:00") this.isDefaultConfigTime = true
      else this.isDefaultConfigTime = false;

      newTimeControl = new Date(
        this.librariesService.datePipe.transform(new Date(), 'yyyy-MM-dd') +
        'T' + this.DailyEventScheduleTime.time + this.librariesService.getUserTimezoneOffsetString())
      // newTimeControl = new Date('2000-01-01T' + this.DailyEventScheduleTime.time + this.librariesService.getUserTimezoneOffsetString() )
      // console.log("newTimeControl", '2000-01-01T' + this.DailyEventScheduleTime.time + this.librariesService.getUserTimezoneOffsetString() )
      console.log("newTimeControl", newTimeControl)

      this.fgDailyEventScheduleTime.patchValue({
        //timeControl: newTimeControl,
        time: this.DailyEventScheduleTime.time,
        name: this.DailyEventScheduleTime.name,
        description: this.DailyEventScheduleTime.description
      });

      if (newDailyEventScheduleTime.time.trim() == "") {

        this.formState = this.formStates.New;
        if (this.DailyEventSchedule.deviceScheduleConfigurationEvents.length > 0) {
          newTimeControl = new Date(
            this.librariesService.datePipe.transform(new Date(), 'yyyy-MM-dd') + 'T' +
            this.DailyEventSchedule.deviceScheduleConfigurationEvents[this.DailyEventSchedule.deviceScheduleConfigurationEvents.length - 1].time
            + this.librariesService.getUserTimezoneOffsetString()
          )
        }
        // this.fgDailyEventScheduleTime.patchValue({
        //   timeControl: newTimeControl
        // });

      }

      //had to do this to make timeControl work correctly
      this.fgDailyEventScheduleTime.enable();
      this.fgDailyEventScheduleTime.controls['timeControl'].setValue(newTimeControl);
      this.fgDailyEventScheduleTime.disable();

      //console.log("@Input() set DailyEventScheduleTime", this.fgDailyEventScheduleTime)

    }

  }

  get isEditMode(): boolean {
    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving:
      case this.formStates.Saved:
        {
          return true;
        }
      default:
        {
          return false;
        }
    }
  }

  public isDefaultConfigTime: boolean = false;
  // get isDefaultConfigTime(): boolean {
  //   if ("00:00" == this.librariesService.datePipe.transform(this.fgDailyEventScheduleTime.value.timeControl, 'HH:mm')) return true;
  //   else return false;
  // }



  public isModified = false;
  public showDailyEventScheduleTimeForm: boolean = false;

  constructor(
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public deviceSchedulesService: DeviceSchedulesService,
    public clientsService: ClientsService,
    public errorsService: ErrorsService,
    public librariesService: LibrariesService,
    public colorsService: ColorsService,
    private formBuilder: UntypedFormBuilder,
  ) { }


  DailyEventScheduleTimeValidator(control: AbstractControl) {
    var varTime: string = ""

    if (control && control.value && this.librariesService.dateIsValid(control.value)) {
      //console.log(control.value)
      varTime = this.librariesService.datePipe.transform(control.value, 'HH:mm');
      //console.log('DailyEventScheduleTimeValidator', control.value, varTime)
    }

    if (varTime != "" && this.DailyEventSchedule) {
      var elementCount = 0;
      for (var i = 0; i < this.DailyEventSchedule.deviceScheduleConfigurationEvents.length; i++) {
        if (this._dailyEventScheduleTimeIndex != i &&
          this.DailyEventSchedule.deviceScheduleConfigurationEvents[i].time == varTime)
          elementCount++;
      }
      // var elementPos = this.DailyEventSchedule.deviceScheduleConfigurationEvents.findIndex(
      //   function (e: DeviceScheduleConfigurationEventsDto): boolean {
      //     if (varTime == e.time) return true;
      //     return false;
      //   }
      // )
      if (elementCount > 0) {
        return { duplicateTime: true };
      }
    }
    else return null;
  }


  public fgDailyEventScheduleTime: UntypedFormGroup = this.formBuilder.group({
    timeControl: [new Date(), [c => this.DailyEventScheduleTimeValidator(c)]],
    time: null,
    name: ['', [Validators.required, Validators.minLength(1)]],
    description: '',
  });

  ngOnInit() {

    // this.eventsService.currentMessage.pipe(filter(message => message.toString() !== ''))
    //   .pipe(takeUntil(this.onDestroy$))
    //   .subscribe(
    //     message => {
    //       this.formState = DailyEventScheduleTimeFormState.Initializing;
    //     }
    //   );

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {

  }

  getDailyEventScheduleTime(): any {
    //this._dailyEventScheduleTime = null;

    if (!this.settingsService.client || !this.DailyEventScheduleTime) {
      return;
    }

    //do something, maybe...

  }

  // controlValidationClass(control) {
  //   return 'form-control'
  //     + (this.controlInvalidFlag(control) != null ? (this.controlInvalidFlag(control) ? ' is-invalid' : ' is-valid') : '');
  // }

  // controlInvalidFlag(control) {
  //   if (!control.touched) {
  //     return null;
  //   }
  //   return control.invalid;
  // }

  eventScheduleTimeNew() {
    this.librariesService.untouchedFormGroup(this.fgDailyEventScheduleTime);
  }

  eventScheduleTimeEdit() {
    this.librariesService.untouchedFormGroup(this.fgDailyEventScheduleTime);
    this.isModified = false;

    if (this.fgDailyEventScheduleTime.value && this.DailyEventSchedule) {
      var varTime = this.librariesService.datePipe.transform(this.fgDailyEventScheduleTime.value.timeControl, 'HH:mm');
      this._dailyEventScheduleTimeIndex = this.DailyEventSchedule.deviceScheduleConfigurationEvents.findIndex(
        function (e: DeviceScheduleConfigurationEventsDto): boolean {
          if (varTime == e.time) return true;
          return false;
        }
      )
      this.fgDailyEventScheduleTime.controls["timeControl"].updateValueAndValidity();
    }
    this.onFormEvent_EventScheduleTime.emit('edit');
  }

  // eventScheduleTimeSave() {
  //   this.librariesService.validateFormGroup(this.fgDailyEventScheduleTime);
  //   if (!this.fgDailyEventScheduleTime.valid) {
  //     this.formState = this.DailyEventScheduleTime.time.trim() == "" ? this.formStates.New : this.formStates.Edit;
  //     this.onFormEvent_EventScheduleTime.emit('edit');
  //     this.notificationsService.warn('Validation Warning (Daily Event Schedule)',
  //       'Verify Required Data', { timeOut: 5000, clickToClose: true });
  //     return;
  //   }

  //   this.formState = this.formStates.Saving;
  //   this.onFormEvent_EventScheduleTime.emit('saving');

  //   if (this.DailyEventScheduleTime.time.trim() == "") {

  //     //create new object based on model, should go at end of sequence
  //     // this.dailyEventSchedule = result.uuid;
  //     // this.formState = this.formStates.Saved;
  //     // this.onFormEvent_EventScheduleTime.emit('saved');

  //   } else {

  //     //this.DailyEventScheduleTime.colorCode = this.colorsService.palette24[this.DailyEventScheduleTime.sequence - 1];
  //     this.DailyEventScheduleTime.time = this.fgDailyEventScheduleTime.value.time;
  //     this.DailyEventScheduleTime.name = this.fgDailyEventScheduleTime.value.name;
  //     this.DailyEventScheduleTime.description = this.fgDailyEventScheduleTime.value.description;

  //   }
  // }

  // eventScheduleTimeCancel() {
  //   this.formState = this.formStates.Read;
  //   //this.getDailyEventScheduleTime();
  //   this.onFormEvent_EventScheduleTime.emit('cancelled');
  // }

  onFormEvent_EventScheduleTimeConfig(event) {

    switch (event.toString().toLowerCase()) {
      case 'add': {

        break;
      }
      case 'edit': {
        //alert('schedules edit');
        break;
      }
      case 'ok': {

        break;
      }
      case 'cancel': {

        break;
      }
      default: {
        //statements; 
        break;
      }
    }

  }

  // buttonAdd(event) {
  //   if (!this.fgDailyEventScheduleTime.valid) return;

  //   this.DailyEventScheduleTime.time = this.fgDailyEventScheduleTime.value.time,
  //     this.DailyEventScheduleTime.name = this.fgDailyEventScheduleTime.value.name,
  //     this.DailyEventScheduleTime.description = this.fgDailyEventScheduleTime.value.description,

  //     this.onFormEvent_EventScheduleTime.emit('add');
  // }

  buttonOkEnabled(): boolean {
    this.librariesService.validateFormGroup(this.fgDailyEventScheduleTime);
    if (!this.fgDailyEventScheduleTime.valid || !this.eventScheduleTimeConfigForm.isConfigurationValid()) return false;
    return true;
  }
  buttonOk(event) {
    this.librariesService.validateFormGroup(this.fgDailyEventScheduleTime);
    if (!this.fgDailyEventScheduleTime.valid || !this.eventScheduleTimeConfigForm.isConfigurationValid()) return;

    this.DailyEventScheduleTime.time = this.librariesService.datePipe.transform(this.fgDailyEventScheduleTime.value.timeControl, 'HH:mm');

    if (this.DailyEventScheduleTime.time == "00:00") {
      this.DeviceSchedule.deviceScheduleConfigurationValuesDefault_Apply(this.eventScheduleTimeConfigForm.DailyEventScheduleTimeOptions)
      this.eventsService.sendEvent(this.eventsService.eventTypes.DayScheduleDefaultConfigApplied,this.DeviceSchedule.uuid)
      console.log('DeviceSchedule',this.DeviceSchedule)
    }
    else {
      //this.DailyEventScheduleTime.time = this.fgDailyEventScheduleTime.value.time,
      this.DailyEventScheduleTime.name = this.fgDailyEventScheduleTime.value.name,
        this.DailyEventScheduleTime.description = this.fgDailyEventScheduleTime.value.description,
        this.DailyEventScheduleTime.deviceScheduleConfigurationEventValues = _.cloneDeep(this.eventScheduleTimeConfigForm.DailyEventScheduleTimeOptions);

      let timeControlDate: Date = this.fgDailyEventScheduleTime.value.timeControl;

      let timeMinutes: number = (timeControlDate.getHours() * 60) + timeControlDate.getMinutes();
      let timeMinutesLow: number = timeMinutes;
      let timeMinutesHigh: number = timeMinutes;

      let valueTimeLow = this.DailyEventScheduleTime.deviceScheduleConfigurationEventValues.filter(v => v.code == "EventMinutePochLow");
      let valueTimeHigh = this.DailyEventScheduleTime.deviceScheduleConfigurationEventValues.filter(v => v.code == "EventMinutePochHigh");

      if (valueTimeLow.length > 0) {
        let maskLow: number = 0b0000000011111111;
        timeMinutesLow = timeMinutesLow & maskLow;
        valueTimeLow[0].value = timeMinutesLow.toString();
      }
      if (valueTimeHigh.length > 0) {
        let maskHigh: number = 0b1111111100000000;
        timeMinutesHigh = timeMinutesHigh & maskHigh;
        timeMinutesHigh = timeMinutesHigh >> 8;
        valueTimeHigh[0].value = timeMinutesHigh.toString();
      }
    }

    //console.log('MinutePochValues', timeMinutes, timeMinutesHigh, timeMinutesLow)
    //console.log('deviceScheduleConfigurationEventValues', this.DailyEventScheduleTime.deviceScheduleConfigurationEventValues)
    //this.showDailyEventScheduleTimeForm = false;
    //apply update
    //this.formState = this.formStates.Read;
    this.onFormEvent_EventScheduleTime.emit('ok');
  }

  buttonCancel(event) {
    //this.showDailyEventScheduleTimeForm = false;
    //apply update
    //this.formState = this.formStates.Read;
    this.onFormEvent_EventScheduleTime.emit('cancel');
  }


}
