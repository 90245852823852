import { Component, EventEmitter, OnInit, AfterViewInit, Input, Output, OnChanges, SimpleChanges, SimpleChange, OnDestroy, ViewChild } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgChartsModule } from 'ng2-charts';
import 'chartjs-plugin-datalabels';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { DevicesService, DeviceLocationsService } from '../../../../core/api/api.services';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { EventsService } from '../../../../core/events/events.service';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { ColorsService } from '../../../../core/colors/colors.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { takeUntil, filter } from 'rxjs/operators';

@Component({
  selector: 'app-periodsummaries-grid',
  templateUrl: './periodsummaries-grid.component.html',
  styleUrls: ['./periodsummaries-grid.component.scss']
})
export class PeriodSummariesGridComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Output() onChartJsEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('agGrid') grid!: AgGridAngular;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  datePipe = new DatePipe('en-US');
  decimalPipe = new DecimalPipe('en-US');

  public isInit: boolean = true;
  public gridOptions: GridOptions;
  @Input() public dateEnd: Date = null;
  @Input() public dateBegin: Date = null;
  @Input() public timeSegment = 0;



  private _gridData: any = null;
  get gridData(): any {
    return this._gridData;
  }
  @Input() set gridData(newGridData: any) {
    this._gridData = newGridData;
  }

  columnDefs = [
    {
      headerName: 'Record Date', field: 'recordDateTime',  sortable: true, resizable: true,
      cellRenderer: (data) => {
        return data.value ? (this.timeSegment == 0
          ? (new Date(data.value)).toLocaleDateString()
          : (new Date(data.value)).toLocaleString()) : '';
      }
    },
    // { headerName: 'Unit Speed', field: 'unitIdSpeed', sortable: true, resizable: true },
    { headerName: 'Speed Limit', field: 'speedLimit', sortable: true, resizable: true },
    { headerName: 'Speed Average', field: 'speedAverage', sortable: true, resizable: true },
    { headerName: 'Speed Peak', field: 'speedPeak', sortable: true, resizable: true },
    { headerName: 'Vehicles', field: 'vehicles', sortable: true, resizable: true },
    { headerName: 'Law Abiders', field: 'lawAbiders', sortable: true, resizable: true },
    { headerName: 'Violators', field: 'violators', sortable: true, resizable: true },
    { headerName: 'Percent Violators', field: 'percentViolators', sortable: true, resizable: true, flex: 1 },
  ];

  sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',

        toolPanelParams: {
          suppressRowGroups: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressValues: true
        }
      }
    ]
  }


  constructor(
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public colorsService: ColorsService,
    public devicesService: DevicesService,
    public deviceLocationsService: DeviceLocationsService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
  ) {
    this.gridOptions = <GridOptions>{
      rowData: this.gridData,
      columnDefs: this.columnDefs,
      sideBar: this.sideBar,
      // pagination: true,
      // rowSelection: 'single'
    };
  }

  ngOnInit() {

  }



  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.dataTableEventsInitialize();
    const self = this;
    self.dataPeriodSummariesResize();
    setTimeout(() => {
      self.isInit = true;
      // this.refreshGrid();
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges) {


    if (this.isInit) {
      this.dataPeriodSummariesResize();
    }
  }


  dataTableEventsInitialize() {
    var self = this;
    window.addEventListener('resize', function () {
      self.dataPeriodSummariesResize();
    });
  }

  dataPeriodSummariesResize() {
    console.log('!!!dataPeriodSummariesResize');

    if (document.getElementById('footerContainer') == null)
      return;

    var dataPeriodSummariesHeight: number =
      document.body.clientHeight - 15 -
      (<HTMLElement>document.getElementsByTagName('ag-grid-angular')[0]).getBoundingClientRect().top -
      (document.getElementById('footerContainer').getBoundingClientRect().bottom -
        document.getElementById('footerContainer').getBoundingClientRect().top)

    dataPeriodSummariesHeight = dataPeriodSummariesHeight.round(0);

    (<HTMLElement>document.getElementsByTagName('ag-grid-angular')[0]).style.height = dataPeriodSummariesHeight.toString() + 'px';
    console.log('!!!', (<HTMLElement>document.getElementsByTagName('ag-grid-angular')[0]).style.height);
  }

  // refreshGrid() {
  //   this.autoSizeColumns();
  // }



  // autoSizeColumns() {
  //  // if (this.gridOptions) return

  //   let allColumnIds = [];
  //   this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
  //     allColumnIds.push(column);
  //   });
  //   this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
  //   this.gridOptions.columnApi.setColumnWidth('recordDateTime', this.timeSegment == 0 ? 100 : 150);
  // }

  saveCsv(fileName) {
    this.grid.api.exportDataAsCsv({ fileName: fileName });
  }
}
