import { Component, EventEmitter, OnInit, Input, Output, ViewChild, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { DeviceSchedulesService, ClientsService } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
import { ToastrService } from 'ngx-toastr';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { DeviceScheduleConfigurationEventsDto, DeviceScheduleConfigurationEventValuesDto, DeviceScheduleConfigurationsDto, DeviceScheduleDto } from '../../../../models/deviceScheduleObjects';
import { ConfigMapOptionsQueryDto } from '../../../../models/configmap';
import { ColorsService } from '../../../../core/colors/colors.service';
import { DaySchedulesScheduleFormComponent } from '../dayschedulesschedule/dayschedulesschedule-form.component';
// import { DatatableComponent } from '@swimlane/ngx-datatable';
import { takeUntil,filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { environment } from '../../../../../environments/environment';


export enum DailyEventSchedulesFormState {
  Initializing = 1,
  Read = 2,
  New = 3,
  Edit = 4,
  Save = 5,
  Saving = 6,
  Saved = 7,
  Cancelled = 8,
}

@Component({
  selector: 'app-dayschedulesschedules-form',
  templateUrl: './dayschedulesschedules-form.component.html',
  styleUrls: ['./dayschedulesschedules-form.component.scss'],
})

export class DaySchedulesSchedulesFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('eventScheduleForm') public eventScheduleForm: DaySchedulesScheduleFormComponent;
  // @ViewChild('dailyEventSchedulesTable') public dataTable: DatatableComponent;
  @Output() onDailyEventScheduleSelected: EventEmitter<any> = new EventEmitter();
  @Output() onFormEvent_EventSchedules: EventEmitter<any> = new EventEmitter<any>();

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public formStates = DailyEventSchedulesFormState;
  private _formState: DailyEventSchedulesFormState = DailyEventSchedulesFormState.Initializing;
  get formState(): DailyEventSchedulesFormState {
    return this._formState;
  }
  @Input() set formState(newFormState: DailyEventSchedulesFormState) {
    let updateFormState: DailyEventSchedulesFormState = null;

    this.fgDailyEventSchedules.disable();

    switch (newFormState) {
      case this.formStates.New: {
        updateFormState = newFormState;
        this.fgDailyEventSchedules.enable();
        break;
      }
      case this.formStates.Edit: {
        updateFormState = newFormState;
        this.fgDailyEventSchedules.enable();
        this.eventScheduleForm.formState = this.eventScheduleForm.formStates.Read;
        this.dailyEventScheduleEdit();
        break;
      }
      case this.formStates.Save: {
        this._formState = newFormState;
        this.fgDailyEventSchedules.enable();
        this.dailyEventScheduleSave();
        break;
      }
      case this.formStates.Cancelled: {
        this.dailyEventScheduleCancel();
        break;
      }
      default: {
        updateFormState = newFormState;
      }
    }

    if (updateFormState != null) {
      this._formState = updateFormState;
    }
  }

  private _deviceSchedule: DeviceScheduleDto;
  get DeviceSchedule(): DeviceScheduleDto {
    return this._deviceSchedule;
  }
  @Input() set DeviceSchedule(newDeviceSchedule: DeviceScheduleDto) {
    this._deviceSchedule = newDeviceSchedule;
    this._dailyEventSchedules = newDeviceSchedule.deviceScheduleConfigurations
  }


  private _dailyEventSchedule: DeviceScheduleConfigurationsDto;
  get DailyEventSchedule(): DeviceScheduleConfigurationsDto {
    return this._dailyEventSchedule;
  }
  set DailyEventSchedule(newDailyEventSchedule: DeviceScheduleConfigurationsDto) {

    this._dailyEventSchedule = newDailyEventSchedule;

    if (newDailyEventSchedule.sequence === 0) {
      this.formState = this.formStates.New;
    }
    //else this.formState = this.formStates.Edit;

  }

  private _configMapOptions: ConfigMapOptionsQueryDto[];
  get ConfigMapOptions(): ConfigMapOptionsQueryDto[] {
    return this._configMapOptions;
  }
  @Input() set ConfigMapOptions(newConfigMapOptions: ConfigMapOptionsQueryDto[]) {
    this._configMapOptions = newConfigMapOptions;
  }


  public dailyEventSchedulesTemp: DeviceScheduleConfigurationsDto[] = [];
  public dailyEventSchedulesSelected: DeviceScheduleConfigurationsDto[] = [];
  private _dailyEventSchedules: DeviceScheduleConfigurationsDto[] = [];
  get DailyEventSchedules(): DeviceScheduleConfigurationsDto[] {
    return this._dailyEventSchedules;
  }
  @Input() set DailyEventSchedules(newDailyEventSchedules: DeviceScheduleConfigurationsDto[]) {

    this._dailyEventSchedules = newDailyEventSchedules;
    //probably need to do refresh or something here
    this.formState = this.formStates.Read;
  }

  get isEditMode(): boolean {
    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving:
      case this.formStates.Saved:
        {
          return true;
        }
      default:
        {
          return false;
        }
    }
  }

  public isModified = false;
  public showDailyEventSchedulesForm: boolean = false;

  constructor(
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public deviceSchedulesService: DeviceSchedulesService,
    public clientsService: ClientsService,
    public errorsService: ErrorsService,
    public librariesService: LibrariesService,
    public colorsService: ColorsService,
    private formBuilder: UntypedFormBuilder
  ) { }


  public fgDailyEventSchedules: UntypedFormGroup = this.formBuilder.group({
    sequence: 0,
    colorCode: '',
    name: ['', [Validators.required, Validators.minLength(1)]],
    description: '',
  });

  ngOnInit() {

    // this.eventsService.currentMessage.pipe(filter(message => message.toString() !== ''))
    //   .pipe(takeUntil(this.onDestroy$))
    //   .subscribe(
    //     message => {
    //       this.formState = DailyEventSchedulesFormState.Initializing;
    //     }
    //   );

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {

  }

  getDailyEventSchedule(): any {
    //this.dailyEventSchedules = null;

    if (!this.settingsService.client || !this.DailyEventSchedule) {
      return;
    }

    //do something, maybe not...

  }

  controlValidationClass(control) {
    return 'form-control'
      + (this.controlInvalidFlag(control) != null ? (this.controlInvalidFlag(control) ? ' is-invalid' : ' is-valid') : '');
  }

  controlInvalidFlag(control) {
    if (!control.touched) {
      return null;
    }
    return control.invalid;
  }


  dailyEventScheduleEdit() {
    this.librariesService.validateFormGroup(this.fgDailyEventSchedules);
    this.isModified = false;
    //this.onFormEvent_EventSchedules.emit('edit');
  }

  dailyEventScheduleSave() {
    if (!this.fgDailyEventSchedules.valid) {
      this.librariesService.validateFormGroup(this.fgDailyEventSchedules);
      this.formState = this.DailyEventSchedule.sequence === 0 ? this.formStates.New : this.formStates.Edit;
      //this.onFormEvent_EventSchedules.emit('edit');
      this.toastrService.warning('Validation Warning (Daily Event Schedule)',
        'Verify Required Data', { timeOut: 5000, tapToDismiss: true });
      return;
    }

    this.formState = this.formStates.Saving;
    this.onFormEvent_EventSchedules.emit('saving');

    if (this.DailyEventSchedule.sequence === 0) {

      //create new object based on model, should go at end of sequence
      // this.dailyEventSchedule = result.uuid;
      // this.formState = this.formStates.Saved;
      // this.onFormEvent.emit('saved');

    } else {

      this.DailyEventSchedule.colorCode = this.colorsService.palette24[this.DailyEventSchedule.sequence - 1];
      this.DailyEventSchedule.name = this.fgDailyEventSchedules.value.name;
      this.DailyEventSchedule.description = this.fgDailyEventSchedules.value.description;

    }
  }

  dailyEventScheduleCancel() {
    this.formState = this.formStates.Read;
    //this.getDailyEventSchedule();
    //this.onFormEvent_EventSchedules.emit('cancelled');
  }

  onEventScheduleEmitter(event) {

    switch (event.toString().toLowerCase()) {
      case 'add': {
        //alert('schedules add');

        this.onFormEvent_EventSchedules.emit('refresh');
        break;
      }
      case 'edit': {
        //alert('schedules edit');
        break;
      }
      case 'ok': {
        this.showDailyEventSchedulesForm = false;
        this.eventScheduleForm.formState = this.eventScheduleForm.formStates.Read;

        let self = this;

        if (this.eventScheduleForm.DailyEventSchedule.sequence == 0) {
          var x: DeviceScheduleConfigurationsDto = new DeviceScheduleConfigurationsDto();
          x.sequence = this.eventScheduleForm.DailyEventSchedule.sequence;
          x.colorCode = this.eventScheduleForm.DailyEventSchedule.colorCode;
          x.name = this.eventScheduleForm.DailyEventSchedule.name;
          x.description = this.eventScheduleForm.DailyEventSchedule.description;
          x.deviceScheduleConfigurationEvents = this.eventScheduleForm.DailyEventSchedule.deviceScheduleConfigurationEvents;
          this.DailyEventSchedules.push(x)
        }
        else {
          for (let i = 0; i < this._dailyEventSchedules.length; i++) {
            if (this._dailyEventSchedules[i].sequence == self.eventScheduleForm.DailyEventSchedule.sequence) {
              this._dailyEventSchedules[i] = self.eventScheduleForm.DailyEventSchedule
              console.log(this._dailyEventSchedules[i], self.eventScheduleForm.DailyEventSchedule)
            }
          }
          // this._dailyEventSchedules.forEach(
          //   function (objAny: DeviceScheduleConfigurationsDto) 
          //   {
          //       if (objAny.sequence == self.eventScheduleForm.DailyEventSchedule.sequence)
          //       {
          //         objAny = self.eventScheduleForm.DailyEventSchedule
          //         console.log(self.eventScheduleForm.DailyEventSchedule)
          //       }
          // });
        }
        this.librariesService.arraySequenceReNumber(this.DailyEventSchedules);
        this.librariesService.arrayColorCodeApply(this.DailyEventSchedules);

        console.log(this.DailyEventSchedules)
        // setTimeout(() => {
        //   this.dataTable.rows = this.DailyEventSchedules;
        // }, 100);


        this.onFormEvent_EventSchedules.emit('refresh');
        break;
      }
      case 'cancel': {
        this.showDailyEventSchedulesForm = false;
        this.eventScheduleForm.formState = this.eventScheduleForm.formStates.Read;
        break;
      }
      default: {
        //statements; 
        break;
      }
    }

  }
 
  onSelect({ selected }) {
    if (!this.isButtonDelete) {
      if (selected) {
        this.dailyEventSchedulesSelected.splice(0, this.dailyEventSchedulesSelected.length);

        this.showDailyEventSchedulesForm = true;
        this.eventScheduleForm.DailyEventSchedule = selected[0];

        if (this.formState == this.formStates.Edit) this.eventScheduleForm.formState = this.eventScheduleForm.formStates.Edit
        else this.eventScheduleForm.formState = this.eventScheduleForm.formStates.Read

        this.onFormEvent_EventSchedules.emit('edit');
      }
    }
    this.isButtonDelete = false;
  }

  buttonAdd(event) {
    //if (!this.fgDailyEventSchedules.valid) return;
    
    let newSchedule: DeviceScheduleConfigurationsDto = new DeviceScheduleConfigurationsDto();
    newSchedule.name = "New Day Schedule"
    this.DeviceSchedule.deviceScheduleConfigurations.push(newSchedule);
    this.DeviceSchedule.deviceScheduleConfigurationsDefault_Refresh();
    this.librariesService.arraySequenceReNumber(this.DailyEventSchedules);
    this.librariesService.arrayColorCodeApply(this.DailyEventSchedules)
    this.eventScheduleForm.DailyEventSchedule = newSchedule

    // this.DailyEventSchedule = new DeviceScheduleConfigurationsDto();
    // this.DailyEventSchedule.name = "New Day Schedule"
    // this.DeviceSchedule.deviceScheduleConfigurations.push(this.DailyEventSchedule);
    // this.DeviceSchedule.deviceScheduleConfigurationsDefault_Refresh();
    // this.librariesService.arraySequenceReNumber(this.DailyEventSchedules);
    // this.librariesService.arrayColorCodeApply(this.DailyEventSchedules);
    // this.eventScheduleForm.DailyEventSchedule = this.DailyEventSchedule
    
    // let eventDefault: DeviceScheduleConfigurationEventsDto = new DeviceScheduleConfigurationEventsDto();
    // eventDefault.name = environment.dayScheduleDefaultEventName;
    // eventDefault.time = "00:00";
    // eventDefault.deviceScheduleConfigurationEventValues = _.cloneDeep(this.DeviceSchedule.deviceScheduleConfigurationValuesDefault);
    // this.eventScheduleForm.DailyEventSchedule.deviceScheduleConfigurationEvents.push(eventDefault);
    
    this.showDailyEventSchedulesForm = true;
    this.onFormEvent_EventSchedules.emit('edit');
    this.eventScheduleForm.formState = this.eventScheduleForm.formStates.Edit
  }

  buttonEdit(event) {
    console.log(event)
    this.showDailyEventSchedulesForm = true;
    this.eventScheduleForm.DailyEventSchedule = event;
    this.showDailyEventSchedulesForm = true;
    this.onFormEvent_EventSchedules.emit('edit');
    this.eventScheduleForm.formState = this.eventScheduleForm.formStates.Edit;
  }

  private isButtonDelete: boolean = false;

  buttonDelete(event) {
    this.isButtonDelete = true;
    this.showDailyEventSchedulesForm = false;

    var obj: DeviceScheduleConfigurationsDto = event;
    var i: number = 1;

    this.DailyEventSchedules.splice(obj.sequence - 1, 1);
    this.librariesService.arraySequenceReNumber(this.DailyEventSchedules);
    this.librariesService.arrayColorCodeApply(this.DailyEventSchedules);
    // this.dataTable.rows = this.DailyEventSchedules;

    this.onFormEvent_EventSchedules.emit('delete');
  }

  listingEvents() {
    this.showDailyEventSchedulesForm = false;
  }
}
