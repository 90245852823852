import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef, Directive, AfterViewInit, OnDestroy, TemplateRef, Input } from '@angular/core';
import { Location } from '@angular/common';
import { EventsService } from '../../../core/events/events.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsService, userTypes, userRoles } from '../../../core/settings/settings.service';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions, SizeColumnsToContentStrategy } from 'ag-grid-community';
import { customAgTheme } from 'app/shared/styles/themes/theme-ag-custom';
import { Router, ActivatedRoute } from '@angular/router';
import { LibrariesService } from '../../../core/libraries/libraries.service';
import { Subject } from 'rxjs';
import { ClientsService } from '../../../core/api/clients.service';
import { ErrorsService } from '../../../core/errors/errors.service';
import { DevicesService } from '../../../core/api/devices.service';
import { FormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
	selector: 'app-search-admin',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
	encapsulation: ViewEncapsulation.None,
})

export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('agGrid') grid!: AgGridAngular;

	//observables
	private onDestroy$: Subject<void> = new Subject<void>();
	public device: any[] = [];
	public clients: any[] = [];


	//agrid 
	public rowStyle;
	public gridOptions: GridOptions;
	public chartThemes;

	public theme = customAgTheme;


	private _gridData: any = null;


	get gridData(): any {
		return this._gridData;
	}
	@Input() set gridData(newGridData: any) {
		this._gridData = newGridData;
	}

	//formcontrols
	public fgSearch: UntypedFormGroup = this.formBuilder.group({
		serial: ['']
	});

	ngOnInit() { }

	constructor(
		public eventsService: EventsService,
		public clientsService: ClientsService,
		public toastrService: ToastrService,
		public settingsService: SettingsService,
		public librariesService: LibrariesService,
		private errorsService: ErrorsService,
		private devicesService: DevicesService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder,

	) {
		this.rowStyle = {
			cursor: 'pointer'
		}
		this.gridOptions = <GridOptions>{
			rowData: this.gridData,
			columnDefs: this.columnDefs,
			sideBar: this.sideBar,
			rowStyle: this.rowStyle,
			rowSelection: 'multiple',
			cursor: "pointer",
			suppressRowClickSelection: false,
			//chartThemeOverrides: this.chartThemeOverrides,
			enableCharts: false,
			enableRangeSelection: false

		}
	}

	sideBar = {
		toolPanels: [
			{
				id: 'columns',
				labelDefault: 'Columns',
				labelKey: 'columns',
				iconKey: 'columns',
				toolPanel: 'agColumnsToolPanel',

				toolPanelParams: {
					suppressRowGroups: true,
					suppressPivots: true,
					suppressPivotMode: true,
					suppressValues: true

				}
			}
		]
	}

	columnDefs = [
		{
			headerName: "Serial", field: "serial", sortable: true, suppressRowClickSelection: true, resizable: true, suppressMenu: true,

		},
		{ headerName: "Client", field: "Client.name", sortable: true, resizable: true, suppressMenu: true },
		{ headerName: "Model", field: "model.code", sortable: true, resizable: true, suppressMenu: true },
		{ headerName: "Status", field: "deviceStatus.code", sortable: true, resizable: true, suppressMenu: true },
		{ headerName: "Name/Label", field: "label", sortable: true, resizable: true, suppressMenu: true },
		{ headerName: "Schedule", field: "deviceSchedule.name", sortable: true, resizable: true, suppressMenu: true },
		{ headerName: "Location Name", field: "deviceLocation.name", sortable: true, resizable: true, suppressMenu: true },
		{
			headerName: "Last Communication", field: "lastActivity", sortable: true, resizable: true, flex: 1, suppressMenu: true,
			cellRenderer: (data) => {
				return data.value ? (data.value != null
					? (this.librariesService.datePipe.transform(new Date(data.value), 'MM-dd-yyyy HH:mm:ss'))
					: '') : '';
			}
		},

	];

	autoSizeStrategy:
    SizeColumnsToContentStrategy = {
      type: "fitCellContents",
      colIds: [ "serial", "Client.name", "model.code", "deviceStatus.code", "label", "deviceSchedule.name", "deciceLocation.name" ]
    };


	ngAfterViewInit() {
		this.getClients();
	}


	ngOnDestroy() {
		this.onDestroy$.next();
	}

	getClients() {
		return this.clientsService.read()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(result: any) => {
					this.clients = result;
					console.log(this.clients)
				},
				error => {
					this.toastrService.error("Server Error (getClients)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
				});
	}


	getDeviceBySerial($event) {
		if (this.fgSearch.value.serial == '') return;

		return this.devicesService.getDeviceBySerial(this.fgSearch.value.serial)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(result: any) => {
					this.device[0] = result;
					for (var i = 0; i < this.clients.length; i++) {
						if (this.clients[i].uuid == this.device[0].clientUuid) {
							this.device[0].Client = this.clients[i];
						}
					}


					this.gridOptions.rowData = this.device;
				},
				error => {
					this.toastrService.warning("(getDeviceBySerial)", "Device Serial Not Found", { timeOut: 15000, tapToDismiss: true });

				});
	}

	onRowClicked(event){
		this.settingsService.clientSetAdmin = true;
        this.settingsService.client = this.device[0].Client
		this.router.navigate(['/administration/devices/', this.device[0].uuid]);


	}

	getChartToolbarItems() {
    return ['chartDownload', 'chartData', 'chartFormat']

  }



}

