
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, ElementRef, ViewEncapsulation, ViewChild, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuService } from '../../../../core/menu/menu.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { DeviceNotificationsService, deviceNotifyTypes } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { Subject } from 'rxjs';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { DataArraysCompareOutput } from '../../../../models/DataArraysCompareOutput';

@Component({
  selector: 'app-topalertsview',
  templateUrl: './topalertsview.component.html',
  styleUrls: ['./topalertsview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopAlertsViewComponent implements OnInit, AfterViewInit, OnDestroy {

  userTypes = userTypes;
  userRoles = userRoles;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public deviceAlerts: any[] = null;
  public deviceAlertSelected: any[] = [];

  @Output() onClearAlert: EventEmitter<any> = new EventEmitter()
  @Output() onDeviceAlertSelected: EventEmitter<any> = new EventEmitter()
  @Output() onDataLoading: EventEmitter<boolean> = new EventEmitter()
  public dataLoading: boolean = false
  public deviceNotifyTypes = deviceNotifyTypes;

  constructor(
    public menu: MenuService,
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    public deviceNotificationsService: DeviceNotificationsService,
    public errorsService: ErrorsService,
    private router: Router) { }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    let self = this
    setTimeout(function () {
      self.getDeviceAlerts()
    }, 500);
  }


  getDeviceAlerts(): any {
    if (!this.settingsService.client) return;

    this.dataLoading = true;
    this.onDataLoading.emit(this.dataLoading)

    return this.deviceNotificationsService.getActive()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {

          if (this.deviceAlerts == null) this.deviceAlerts = [];
          let dacOut: DataArraysCompareOutput = this.librariesService.dataArraysCompare(
            this.deviceAlerts, result, "id")

          if (this.deviceAlerts.length == 0 || dacOut.arrayAdded.length > 0 || dacOut.arrayDeleted.length > 0) {
            if (this.deviceAlerts.length > 0) console.log('deviceAlerts changed', this.deviceAlerts[0]['id'], dacOut)
            {
              for (var i = 0; i < result.length; i++) {
                result[i].ButtonDismiss = false
                result[i].ButtonInactive = false
                if ((result[i].typeId == deviceNotifyTypes.LocationChange || result[i].typeId == deviceNotifyTypes.Communication) &&
                  this.settingsService.userAccessCheck(userTypes.EndUser, userRoles.Manager)) {
                  result[i].ButtonDismiss = true;
                }
                if (result[i].typeId == deviceNotifyTypes.Offline &&
                  this.settingsService.userAccessCheck(userTypes.EndUser, userRoles.Manager)) {
                  result[i].ButtonInactive = true;
                }
              }
              this.deviceAlerts = result;
            }
          }

          this.dataLoading = false;
          this.onDataLoading.emit(this.dataLoading)
        },
        error => {
          this.toastrService.error("Server Error (getDeviceAlerts)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
          this.dataLoading = false;
          this.onDataLoading.emit(this.dataLoading)
        });
  }



  onSelect({ selected }) {
    this.deviceAlertSelected.splice(0, this.deviceAlertSelected.length);
    if (selected && selected[0].uuid) {
      console.log('onSelect Event', selected);
      this.onDeviceAlertSelected.emit(selected[0]);
      this.router.navigate(['/devices/', selected[0].uuid])
    }
  }

  clearAlert(alertId) {
    return this.deviceNotificationsService.clear(alertId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.getDeviceAlerts();
          this.onClearAlert.emit('onClearAlert');
        },
        error => {
          this.toastrService.error("Server Error (clearAlert)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });
  }
}
