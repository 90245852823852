import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map as oMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ColorsService } from '../colors/colors.service';


declare var $: any;

export enum mapMarkerTypes {
    Inactive = 1,
    PreviousLocation = 2,
    Wifi = 3,
    WifiAlert = 4,
    WifiAnalytic = 5,
    Cellular = 6,
    CellularAlert = 7,
    CellularComm = 8,
    CellularOffline = 9,
    CellularAnalytic = 10,
    BatteryLow = 11,
    BatteryCritical = 12,
    SearchLocation = 13,
    Hidden = 14
}


export class mapViewBounds {
    top: number = 47.66; //lat
    left: number = -124.66; //long
    bottom: number = 29.24; //lat
    right: number = -69.17; //long
}


@Injectable({
    providedIn: 'root',
})
export class MappingService {


    constructor(
        protected router: Router,
        protected http: HttpClient,
        public toastrService: ToastrService,
        public colorsService: ColorsService
    ) {
    }

    public largeZoom: number = 4;

    // public _largeViewIsDefault: boolean = true;
    // get largeViewIsDefault(): boolean {
    //     console.log('largeViewIsDefault',this._largeViewIsDefault)
    //     return this._largeViewIsDefault;
    // }
    // private _largeViewBounds: mapViewBounds = new mapViewBounds();
    // get largeViewBounds(): mapViewBounds {
    //     return  this._largeViewBounds;
    // }
    // set largeViewBounds(newViewBounds: mapViewBounds){
    //     this._largeViewIsDefault = false;
    //     console.log('largeViewBounds',this._largeViewIsDefault)

    //     this._largeViewBounds.top = newViewBounds.top;
    //     this._largeViewBounds.left = newViewBounds.left;
    //     this._largeViewBounds.bottom = newViewBounds.bottom;
    //     this._largeViewBounds.right = newViewBounds.right;
    // }
    // public largeViewBoundsReset(){
    //     console.log('largeViewBoundsReset',this._largeViewIsDefault)
    //     this._largeViewIsDefault = true;
    //     this.largeZoom = 4;
    //     this._largeViewBounds = new mapViewBounds();
    // } 

    public app_id: string = 'QFuAVVdDgajjwPTxDC3I'
    public apiKey: string = 'xIfm_53sblCK-vyAdENsbqlETwTCzTLHbmyUjWNBogs'


    // private svgMarker: string =
    //     '<svg width="20" height="29" xmlns="http://www.w3.org/2000/svg">' +
    //     '<path stroke="[stroke]" stroke-width="[stroke-width]" fill="[fill]" d="m9.9,28.79076c0,0.00526 0.01091,0.01577 0.01091,0.01577s9.55576,-14.13771 9.55576,-18.78371c0,-6.83761 -4.84333,-9.81231 -9.56666,-9.82282c-4.72334,0.01051 -9.56666,2.98521 -9.56666,9.82282c0,4.646 9.56121,18.78371 9.56121,18.78371s0.00545,-0.01577 0.00545,-0.01577l-0.00001,0zm-3.3107,-19.15686c0,-1.7659 1.48354,-3.19544 3.31615,-3.19544c1.83261,0 3.31615,1.42954 3.31615,3.19544s-1.489,3.19544 -3.32161,3.19544c-1.82716,0 -3.3107,-1.42954 -3.3107,-3.19544l0.00001,0z"/>' +
    //     '<circle stroke="null" stroke-width="0" fill="[fill-inner]"  cx="10" cy="9.75" id="svg_3" r="5" />' +
    //     '</svg>'


    private svgMarkerInactive: string =
        '<svg width="27" height="41" xmlns="http://www.w3.org/2000/svg"><g display="block" height="41px" width="27px" viewBox="0 0 27 41"><g fill-rule="nonzero">' +
        '    <g transform="translate(3.0, 29.0)" fill="#000000">' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="9.5" ry="4.77275007"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="8.5" ry="4.29549936"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="7.5" ry="3.81822308"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="6.5" ry="3.34094679"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="5.5" ry="2.86367051"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="4.5" ry="2.38636864"></ellipse>' +
        '    </g>' +
        '    <g fill="[fill]"><path d="M27,13.5 C27,19.074644 20.250001,27.000002 14.75,34.500002 C14.016665,35.500004 12.983335,35.500004 12.25,34.500002 C6.7499993,27.000002 0,19.222562 0,13.5 C0,6.0441559 6.0441559,0 13.5,0 C20.955844,0 27,6.0441559 27,13.5 Z"></path></g>' +
        '    <g opacity="0.25" fill="#000000"><path d="M13.5,0 C6.0441559,0 0,6.0441559 0,13.5 C0,19.222562 6.7499993,27 12.25,34.5 C13,35.522727 14.016664,35.500004 14.75,34.5 C20.250001,27 27,19.074644 27,13.5 C27,6.0441559 20.955844,0 13.5,0 Z M13.5,1 C20.415404,1 26,6.584596 26,13.5 C26,15.898657 24.495584,19.181431 22.220703,22.738281 C19.945823,26.295132 16.705119,30.142167 13.943359,33.908203 C13.743445,34.180814 13.612715,34.322738 13.5,34.441406 C13.387285,34.322738 13.256555,34.180814 13.056641,33.908203 C10.284481,30.127985 7.4148684,26.314159 5.015625,22.773438 C2.6163816,19.232715 1,15.953538 1,13.5 C1,6.584596 6.584596,1 13.5,1 Z"></path></g>' +
        '    <g transform="translate(6.0, 7.0)" fill="#FFFFFF"></g>' +
        '    </g><path d="m21.21294,19.64305l-4.24387,-3.28002c0.98242,-1.48799 1.22778,-2.16192 1.22778,-3.30824c0,-2.61451 -2.13914,-4.75365 -4.75365,-4.75365c-1.68854,0 -3.17504,0.89453 -4.01832,2.23124l-2.77866,-2.14781c-0.17281,-0.13419 -0.42189,-0.10324 -0.55608,0.06957l-0.48626,0.62565c-0.13419,0.17257 -0.10324,0.42139 0.06957,0.55583l14.56672,11.25848c0.17281,0.13444 0.42164,0.10324 0.55608,-0.06957l0.48626,-0.62565c0.13419,-0.17257 0.10324,-0.42139 -0.06957,-0.55583zm-8.24511,1.09681c0.23768,0.31691 0.71305,0.31691 0.95073,0c0.46051,-0.66081 0.87225,-1.24585 1.2414,-1.7695l-6.3845,-4.93464c0.26516,1.30106 1.24164,2.47041 4.19237,6.70413z" style="fill: rgb(255, 255, 255);"></path></g>' +
        '</svg>'


    private svgMarkerCommunicationOffline: string =
        '<svg width="27" height="41" xmlns="http://www.w3.org/2000/svg"><g display="block" height="41px" width="27px" viewBox="0 0 27 41"><g fill-rule="nonzero">' +
        '    <g transform="translate(3.0, 29.0)" fill="#000000">' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="9.5" ry="4.77275007"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="8.5" ry="4.29549936"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="7.5" ry="3.81822308"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="6.5" ry="3.34094679"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="5.5" ry="2.86367051"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="4.5" ry="2.38636864"></ellipse>' +
        '    </g>' +
        '    <g fill="[fill]"><path d="M27,13.5 C27,19.074644 20.250001,27.000002 14.75,34.500002 C14.016665,35.500004 12.983335,35.500004 12.25,34.500002 C6.7499993,27.000002 0,19.222562 0,13.5 C0,6.0441559 6.0441559,0 13.5,0 C20.955844,0 27,6.0441559 27,13.5 Z"></path></g>' +
        '    <g opacity="0.25" fill="#000000"><path d="M13.5,0 C6.0441559,0 0,6.0441559 0,13.5 C0,19.222562 6.7499993,27 12.25,34.5 C13,35.522727 14.016664,35.500004 14.75,34.5 C20.250001,27 27,19.074644 27,13.5 C27,6.0441559 20.955844,0 13.5,0 Z M13.5,1 C20.415404,1 26,6.584596 26,13.5 C26,15.898657 24.495584,19.181431 22.220703,22.738281 C19.945823,26.295132 16.705119,30.142167 13.943359,33.908203 C13.743445,34.180814 13.612715,34.322738 13.5,34.441406 C13.387285,34.322738 13.256555,34.180814 13.056641,33.908203 C10.284481,30.127985 7.4148684,26.314159 5.015625,22.773438 C2.6163816,19.232715 1,15.953538 1,13.5 C1,6.584596 6.584596,1 13.5,1 Z"></path></g>' +
        '    <g transform="translate(6.0, 7.0)" fill="#FFFFFF"></g>' +
        '    </g><path d="m7.16103,12.70841c0,1.22803 0.52983,2.35206 1.41124,3.23595c-0.31196,1.24536 -1.34439,2.35702 -1.35677,2.37187c-0.05447,0.05694 -0.06932,0.14112 -0.03714,0.2154c0.03219,0.0718 0.10151,0.11884 0.18074,0.11884c1.64149,0 2.87199,-0.78732 3.48106,-1.27259c0.80961,0.30453 1.70834,0.48032 2.65907,0.48032c0.67839,0 1.32954,-0.08913 1.94107,-0.24759l-8.05892,-6.22926c-0.13865,0.42337 -0.22035,0.86655 -0.22035,1.32706zm14.10744,5.39985l-2.83238,-2.18866c0.8715,-0.88141 1.40134,-1.99307 1.40134,-3.21119c0,-2.84476 -2.83733,-5.14978 -6.3382,-5.14978c-1.61178,0 -3.07502,0.49765 -4.1941,1.30478l-2.60213,-2.01287c-0.17331,-0.1337 -0.4209,-0.10399 -0.55707,0.06932l-0.48527,0.62392c-0.1337,0.17331 -0.10399,0.4209 0.06932,0.55459l14.56795,11.25773c0.17331,0.1337 0.4209,0.10399 0.55707,-0.06932l0.48527,-0.62639c0.1337,-0.16836 0.10151,-0.41842 -0.0718,-0.55212z" style="fill: rgb(255, 255, 255);"></path></g>' +
        '</svg>'

    private svgMarkerBatteryCritical: string =
        '<svg width="27" height="41" xmlns="http://www.w3.org/2000/svg"><g display="block" height="41px" width="27px" viewBox="0 0 27 41"><g fill-rule="nonzero">' +
        '    <g transform="translate(3.0, 29.0)" fill="#000000">' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="9.5" ry="4.77275007"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="8.5" ry="4.29549936"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="7.5" ry="3.81822308"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="6.5" ry="3.34094679"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="5.5" ry="2.86367051"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="4.5" ry="2.38636864"></ellipse>' +
        '    </g>' +
        '    <g fill="[fill]"><path d="M27,13.5 C27,19.074644 20.250001,27.000002 14.75,34.500002 C14.016665,35.500004 12.983335,35.500004 12.25,34.500002 C6.7499993,27.000002 0,19.222562 0,13.5 C0,6.0441559 6.0441559,0 13.5,0 C20.955844,0 27,6.0441559 27,13.5 Z"></path></g>' +
        '    <g opacity="0.25" fill="#000000"><path d="M13.5,0 C6.0441559,0 0,6.0441559 0,13.5 C0,19.222562 6.7499993,27 12.25,34.5 C13,35.522727 14.016664,35.500004 14.75,34.5 C20.250001,27 27,19.074644 27,13.5 C27,6.0441559 20.955844,0 13.5,0 Z M13.5,1 C20.415404,1 26,6.584596 26,13.5 C26,15.898657 24.495584,19.181431 22.220703,22.738281 C19.945823,26.295132 16.705119,30.142167 13.943359,33.908203 C13.743445,34.180814 13.612715,34.322738 13.5,34.441406 C13.387285,34.322738 13.256555,34.180814 13.056641,33.908203 C10.284481,30.127985 7.4148684,26.314159 5.015625,22.773438 C2.6163816,19.232715 1,15.953538 1,13.5 C1,6.584596 6.584596,1 13.5,1 Z"></path></g>' +
        '    <g transform="translate(6.0, 7.0)" fill="#FFFFFF"></g>' +
        '    </g><path d="m7.41394,16.1146l0,-4.36325l-1.57135,-1.21398a1.19451,1.19451 0 0 0 -0.04729,0.31665l0,5.66524a1.21398,1.21398 0 0 0 1.21398,1.21398l8.14378,0l-2.09437,-1.61864l-5.64476,0zm13.75844,0.40466l0,-0.40466l0.20233,0a0.60699,0.60699 0 0 0 0.60699,-0.60699l0,-3.64194a0.60699,0.60699 0 0 0 -0.60699,-0.60699l-0.20233,0l0,-0.40466a1.21398,1.21398 0 0 0 -1.21398,-1.21398l-9.98221,0l-3.0309,-2.34273a0.40466,0.40466 0 0 0 -0.56829,0.07107l-0.49647,0.63911a0.40466,0.40466 0 0 0 0.07107,0.56779l14.88011,11.50069a0.40466,0.40466 0 0 0 0.56829,-0.07107l0.49672,-0.63911a0.40466,0.40466 0 0 0 -0.07107,-0.56779l-1.47043,-1.13659a1.21019,1.21019 0 0 0 0.81716,-1.14215zm-0.80932,-2.0233l-0.80932,0l0,1.61864l-1.20032,0l-6.28285,-4.85592l7.48318,0l0,1.61864l0.80932,0l0,1.61864z" style="fill: rgb(255, 255, 255);"></path></g>' +
        '</svg>'

    private svgMarkerBatteryLow: string =
        '<svg width="27" height="41" xmlns="http://www.w3.org/2000/svg"><g display="block" height="41px" width="27px" viewBox="0 0 27 41"><g fill-rule="nonzero">' +
        '    <g transform="translate(3.0, 29.0)" fill="#000000">' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="9.5" ry="4.77275007"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="8.5" ry="4.29549936"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="7.5" ry="3.81822308"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="6.5" ry="3.34094679"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="5.5" ry="2.86367051"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="4.5" ry="2.38636864"></ellipse>' +
        '    </g>' +
        '    <g fill="[fill]"><path d="M27,13.5 C27,19.074644 20.250001,27.000002 14.75,34.500002 C14.016665,35.500004 12.983335,35.500004 12.25,34.500002 C6.7499993,27.000002 0,19.222562 0,13.5 C0,6.0441559 6.0441559,0 13.5,0 C20.955844,0 27,6.0441559 27,13.5 Z"></path></g>' +
        '    <g opacity="0.25" fill="#000000"><path d="M13.5,0 C6.0441559,0 0,6.0441559 0,13.5 C0,19.222562 6.7499993,27 12.25,34.5 C13,35.522727 14.016664,35.500004 14.75,34.5 C20.250001,27 27,19.074644 27,13.5 C27,6.0441559 20.955844,0 13.5,0 Z M13.5,1 C20.415404,1 26,6.584596 26,13.5 C26,15.898657 24.495584,19.181431 22.220703,22.738281 C19.945823,26.295132 16.705119,30.142167 13.943359,33.908203 C13.743445,34.180814 13.612715,34.322738 13.5,34.441406 C13.387285,34.322738 13.256555,34.180814 13.056641,33.908203 C10.284481,30.127985 7.4148684,26.314159 5.015625,22.773438 C2.6163816,19.232715 1,15.953538 1,13.5 C1,6.584596 6.584596,1 13.5,1 Z"></path></g>' +
        '    <g transform="translate(6.0, 7.0)" fill="#FFFFFF"></g>' +
        '    </g><path d="m11.41459,15.05671l-3.26433,0l0,-3.26433l3.26433,0l0,3.26433zm8.56888,-4.48846l-13.05733,0l0,5.71258l13.05733,0l0,-1.63217l0.81608,0l0,-2.44825l-0.81608,0l0,-1.63217m0,-1.22413c0.67607,0 1.22413,0.54805 1.22413,1.22413l0,0.40804l0.20402,0c0.33804,0 0.61206,0.27403 0.61206,0.61206l0,3.67238c0,0.33804 -0.27403,0.61206 -0.61206,0.61206l-0.20402,0l0,0.40804c0,0.67607 -0.54805,1.22413 -1.22413,1.22413l-13.05733,0c-0.67607,0 -1.22413,-0.54805 -1.22413,-1.22413l0,-5.71258c0,-0.67607 0.54805,-1.22413 1.22413,-1.22413l13.05733,0z" style="fill: rgb(255, 255, 255);"></path></g>' +
        '</svg>'

    private svgMarkerLte: string =
        '<svg width="27" height="41" xmlns="http://www.w3.org/2000/svg"><g display="block" height="41px" width="27px" viewBox="0 0 27 41"><g fill-rule="nonzero">' +
        '    <g transform="translate(3.0, 29.0)" fill="#000000">' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="9.5" ry="4.77275007"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="8.5" ry="4.29549936"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="7.5" ry="3.81822308"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="6.5" ry="3.34094679"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="5.5" ry="2.86367051"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="4.5" ry="2.38636864"></ellipse>' +
        '    </g>' +
        '    <g fill="[fill]"><path d="M27,13.5 C27,19.074644 20.250001,27.000002 14.75,34.500002 C14.016665,35.500004 12.983335,35.500004 12.25,34.500002 C6.7499993,27.000002 0,19.222562 0,13.5 C0,6.0441559 6.0441559,0 13.5,0 C20.955844,0 27,6.0441559 27,13.5 Z"></path></g>' +
        '    <g opacity="0.25" fill="#000000"><path d="M13.5,0 C6.0441559,0 0,6.0441559 0,13.5 C0,19.222562 6.7499993,27 12.25,34.5 C13,35.522727 14.016664,35.500004 14.75,34.5 C20.250001,27 27,19.074644 27,13.5 C27,6.0441559 20.955844,0 13.5,0 Z M13.5,1 C20.415404,1 26,6.584596 26,13.5 C26,15.898657 24.495584,19.181431 22.220703,22.738281 C19.945823,26.295132 16.705119,30.142167 13.943359,33.908203 C13.743445,34.180814 13.612715,34.322738 13.5,34.441406 C13.387285,34.322738 13.256555,34.180814 13.056641,33.908203 C10.284481,30.127985 7.4148684,26.314159 5.015625,22.773438 C2.6163816,19.232715 1,15.953538 1,13.5 C1,6.584596 6.584596,1 13.5,1 Z"></path></g>' +
        '    <g transform="translate(6.0, 7.0)" fill="#FFFFFF"></g>' +
        '    </g><path d="M 8.871 15.165 L 8.21 15.165 C 7.846 15.165 7.549 15.461 7.549 15.826 L 7.549 17.147 C 7.549 17.512 7.846 17.808 8.21 17.808 L 8.871 17.808 C 9.236 17.808 9.531 17.512 9.531 17.147 L 9.531 15.826 C 9.531 15.461 9.236 15.165 8.871 15.165 Z M 12.175 12.521 L 11.514 12.521 C 11.15 12.521 10.853 12.818 10.853 13.182 L 10.853 17.147 C 10.853 17.512 11.15 17.808 11.514 17.808 L 12.175 17.808 C 12.54 17.808 12.836 17.512 12.836 17.147 L 12.836 13.182 C 12.836 12.818 12.54 12.521 12.175 12.521 Z M 15.48 9.877 L 14.819 9.877 C 14.454 9.877 14.158 10.174 14.158 10.539 L 14.158 17.147 C 14.158 17.512 14.454 17.808 14.819 17.808 L 15.48 17.808 C 15.844 17.808 16.141 17.512 16.141 17.147 L 16.141 10.539 C 16.141 10.174 15.844 9.877 15.48 9.877 Z M 18.784 7.234 L 18.123 7.234 C 17.758 7.234 17.462 7.531 17.462 7.895 L 17.462 17.147 C 17.462 17.512 17.758 17.808 18.123 17.808 L 18.784 17.808 C 19.149 17.808 19.444 17.512 19.444 17.147 L 19.444 7.895 C 19.444 7.531 19.149 7.234 18.784 7.234 Z" style="fill: rgb(255, 255, 255);"></path></g>' +
        '</svg>'

    private svgMarkerLteOffline: string =
        '<svg width="27" height="41" xmlns="http://www.w3.org/2000/svg"><g display="block" height="41px" width="27px" viewBox="0 0 27 41"><g fill-rule="nonzero">' +
        '    <g transform="translate(3.0, 29.0)" fill="#000000">' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="9.5" ry="4.77275007"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="8.5" ry="4.29549936"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="7.5" ry="3.81822308"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="6.5" ry="3.34094679"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="5.5" ry="2.86367051"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="4.5" ry="2.38636864"></ellipse>' +
        '    </g>' +
        '    <g fill="[fill]"><path d="M27,13.5 C27,19.074644 20.250001,27.000002 14.75,34.500002 C14.016665,35.500004 12.983335,35.500004 12.25,34.500002 C6.7499993,27.000002 0,19.222562 0,13.5 C0,6.0441559 6.0441559,0 13.5,0 C20.955844,0 27,6.0441559 27,13.5 Z"></path></g>' +
        '    <g opacity="0.25" fill="#000000"><path d="M13.5,0 C6.0441559,0 0,6.0441559 0,13.5 C0,19.222562 6.7499993,27 12.25,34.5 C13,35.522727 14.016664,35.500004 14.75,34.5 C20.250001,27 27,19.074644 27,13.5 C27,6.0441559 20.955844,0 13.5,0 Z M13.5,1 C20.415404,1 26,6.584596 26,13.5 C26,15.898657 24.495584,19.181431 22.220703,22.738281 C19.945823,26.295132 16.705119,30.142167 13.943359,33.908203 C13.743445,34.180814 13.612715,34.322738 13.5,34.441406 C13.387285,34.322738 13.256555,34.180814 13.056641,33.908203 C10.284481,30.127985 7.4148684,26.314159 5.015625,22.773438 C2.6163816,19.232715 1,15.953538 1,13.5 C1,6.584596 6.584596,1 13.5,1 Z"></path></g>' +
        '    <g transform="translate(6.0, 7.0)" fill="#FFFFFF"></g>' +
        '    </g><path d="m20.22772,16.91129l-0.55689,-0.4305l0,-8.75963c0,-0.38111 -0.30907,-0.69018 -0.69018,-0.69018l-0.69018,0c-0.38111,0 -0.69018,0.30907 -0.69018,0.69018l0,7.1595l-1.38035,-1.06675l0,-3.33204c0,-0.38111 -0.30907,-0.69018 -0.69018,-0.69018l-0.69018,0c-0.38111,0 -0.69018,0.30907 -0.69018,0.69018l0,1.73169l-6.61124,-5.10989c-0.15054,-0.1169 -0.36752,-0.08994 -0.48442,0.06061l-0.4236,0.54502c-0.1169,0.15033 -0.08994,0.36709 0.06061,0.4842l12.68975,9.80761c0.15054,0.11711 0.3673,0.08994 0.48442,-0.06061l0.4236,-0.54502c0.11668,-0.15033 0.08972,-0.36709 -0.06082,-0.4842zm-11.5997,-1.59819l-0.69018,0c-0.38111,0 -0.69018,0.30907 -0.69018,0.69018l0,1.38035c0,0.38111 0.30907,0.69018 0.69018,0.69018l0.69018,0c0.38111,0 0.69018,-0.30907 0.69018,-0.69018l0,-1.38035c0,-0.38111 -0.30907,-0.69018 -0.69018,-0.69018zm2.07053,-2.07053l0,4.14106c0,0.38111 0.30907,0.69018 0.69018,0.69018l0.69018,0c0.38111,0 0.69018,-0.30907 0.69018,-0.69018l0,-2.74776l-2.03451,-1.57231c-0.01596,0.05845 -0.03602,0.1156 -0.03602,0.17901zm3.45088,4.14106c0,0.38111 0.30907,0.69018 0.69018,0.69018l0.69018,0c0.38111,0 0.69018,-0.30907 0.69018,-0.69018l0,-0.08045l-2.07053,-1.60035l0,1.68079z" style="fill: rgb(255, 255, 255);"></path></g>' +
        '</svg>'
    private svgMarkerWifi: string =
        '<svg width="27" height="41" xmlns="http://www.w3.org/2000/svg"><g display="block" height="41px" width="27px" style="padding-top: 10px;" viewBox="0 0 27 41"><g fill-rule="nonzero">' +
        '    <g transform="translate(3.0, 29.0)" style="padding-top: 100px;" fill="#000000">' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="9.5" ry="4.77275007"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="8.5" ry="4.29549936"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="7.5" ry="3.81822308"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="6.5" ry="3.34094679"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="5.5" ry="2.86367051"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="4.5" ry="2.38636864"></ellipse>' +
        '    </g>' +
        '    <g fill="[fill]" style="padding-top: 10px;"><path d="M27,13.5 C27,19.074644 20.250001,27.000002 14.75,34.500002 C14.016665,35.500004 12.983335,35.500004 12.25,34.500002 C6.7499993,27.000002 0,19.222562 0,13.5 C0,6.0441559 6.0441559,0 13.5,0 C20.955844,0 27,6.0441559 27,13.5 Z"></path></g>' +
        '    <g opacity="0.25" fill="#000000" style="padding-top: 10px;"><path d="M13.5,0 C6.0441559,0 0,6.0441559 0,13.5 C0,19.222562 6.7499993,27 12.25,34.5 C13,35.522727 14.016664,35.500004 14.75,34.5 C20.250001,27 27,19.074644 27,13.5 C27,6.0441559 20.955844,0 13.5,0 Z M13.5,1 C20.415404,1 26,6.584596 26,13.5 C26,15.898657 24.495584,19.181431 22.220703,22.738281 C19.945823,26.295132 16.705119,30.142167 13.943359,33.908203 C13.743445,34.180814 13.612715,34.322738 13.5,34.441406 C13.387285,34.322738 13.256555,34.180814 13.056641,33.908203 C10.284481,30.127985 7.4148684,26.314159 5.015625,22.773438 C2.6163816,19.232715 1,15.953538 1,13.5 C1,6.584596 6.584596,1 13.5,1 Z"></path></g>' +
        '    <g transform="translate(6.0, 7.0)" fill="#FFFFFF" style="padding-top: 10px;"></g>' +
        '    <g style="padding-top: 10px;"><path d="M 21.67 10.889 C 17.183 6.74 10.206 6.74 5.72 10.889 C 5.551 11.045 5.548 11.309 5.712 11.472 L 6.579 12.331 C 6.734 12.486 6.984 12.49 7.146 12.341 C 10.842 8.956 16.549 8.955 20.245 12.341 C 20.406 12.49 20.657 12.486 20.812 12.331 L 21.679 11.472 C 21.842 11.309 21.839 11.045 21.67 10.889 Z M 13.695 15.881 C 12.8 15.881 12.075 16.606 12.075 17.502 C 12.075 18.397 12.8 19.123 13.695 19.123 C 14.59 19.123 15.316 18.397 15.316 17.502 C 15.316 16.606 14.59 15.881 13.695 15.881 Z M 18.828 13.765 C 15.909 11.183 11.478 11.185 8.563 13.765 C 8.388 13.918 8.383 14.187 8.549 14.351 L 9.421 15.212 C 9.572 15.361 9.817 15.372 9.979 15.231 C 12.105 13.394 15.291 13.397 17.412 15.231 C 17.573 15.372 17.818 15.361 17.97 15.212 L 18.842 14.351 C 19.008 14.187 19.002 13.918 18.828 13.765 Z" style="fill: rgb(255, 255, 255);"></path></g>' +
        '</svg>'

    private svgMarkerLocation: string =
        '<svg width="27" height="41" xmlns="http://www.w3.org/2000/svg"><g display="block" height="41px" width="27px" viewBox="0 0 27 41"><g fill-rule="nonzero">' +
        '    <g transform="translate(3.0, 29.0)" fill="#000000">' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="9.5" ry="4.77275007"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="8.5" ry="4.29549936"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="7.5" ry="3.81822308"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="6.5" ry="3.34094679"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="5.5" ry="2.86367051"></ellipse>' +
        '        <ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="4.5" ry="2.38636864"></ellipse>' +
        '    </g>' +
        '    <g fill="[fill]"><path d="M27,13.5 C27,19.074644 20.250001,27.000002 14.75,34.500002 C14.016665,35.500004 12.983335,35.500004 12.25,34.500002 C6.7499993,27.000002 0,19.222562 0,13.5 C0,6.0441559 6.0441559,0 13.5,0 C20.955844,0 27,6.0441559 27,13.5 Z"></path></g>' +
        '    <g opacity="0.25" fill="#000000"><path d="M13.5,0 C6.0441559,0 0,6.0441559 0,13.5 C0,19.222562 6.7499993,27 12.25,34.5 C13,35.522727 14.016664,35.500004 14.75,34.5 C20.250001,27 27,19.074644 27,13.5 C27,6.0441559 20.955844,0 13.5,0 Z M13.5,1 C20.415404,1 26,6.584596 26,13.5 C26,15.898657 24.495584,19.181431 22.220703,22.738281 C19.945823,26.295132 16.705119,30.142167 13.943359,33.908203 C13.743445,34.180814 13.612715,34.322738 13.5,34.441406 C13.387285,34.322738 13.256555,34.180814 13.056641,33.908203 C10.284481,30.127985 7.4148684,26.314159 5.015625,22.773438 C2.6163816,19.232715 1,15.953538 1,13.5 C1,6.584596 6.584596,1 13.5,1 Z"></path></g>' +
        '    <g transform="translate(6.0, 7.0)" fill="#FFFFFF"></g>' +
        '    <g transform="translate(8.0, 8.0)"><circle fill="#000000" opacity="0.25" cx="5.5" cy="5.5" r="5.4999962"></circle><circle fill="#FFFFFF" cx="5.5" cy="5.5" r="5.4999962"></circle></g></g>' +
        '</svg>'

    //13B5C7 - auqua-teal/google
    //EF74B6 - magenta/google
    //F88181 - red/google
    //7B9EB0 - grey/google
    //FF9E67 - orange/goole
    //4DB546 - green/google
    //4B96F3 - blue/google
    //3FB1CE - blue/mapbox

    private colorSearch: string = this.colorsService.APP_COLORS['map-device-search']
    private colorLocation: string = this.colorsService.APP_COLORS['map-device-location']
    private colorCellular: string = this.colorsService.APP_COLORS['map-device-cellular']
    private colorWifi: string = this.colorsService.APP_COLORS['map-device-wifi']
    private colorAlert: string = this.colorsService.APP_COLORS['map-device-alert']
    private colorAnalytics: string = this.colorsService.APP_COLORS['map-device-warning']
    private colorWarning: string = this.colorsService.APP_COLORS['map-device-warning']
    private colorDark: string = this.colorsService.APP_COLORS['map-device-inactive']
    private colorHidden: string = this.colorsService.APP_COLORS['map-device-hidden']

    public markerTypes = mapMarkerTypes

    public getMarkerTypeSvg(markerType: mapMarkerTypes): string {
        var markerSvg: string = ""

        switch (markerType) {
            case this.markerTypes.SearchLocation: {

                markerSvg = this.svgMarkerLocation
                    .replace("[fill]", this.colorSearch)

                break;
            }
            case this.markerTypes.Hidden: {

                markerSvg = this.svgMarkerLocation
                    .replace("[fill]", this.colorHidden)

                break;
            }
            case this.markerTypes.Inactive: {

                markerSvg = this.svgMarkerInactive
                    .replace("[fill]", this.colorDark)

                break;
            }
            case this.markerTypes.PreviousLocation: {

                markerSvg = this.svgMarkerLocation
                    .replace("[fill]", this.colorLocation)

                break;
            }
            case this.markerTypes.Wifi: {

                markerSvg = this.svgMarkerWifi
                    .replace("[fill]", this.colorWifi)

                break;
            }
            case this.markerTypes.WifiAlert: {

                markerSvg = this.svgMarkerWifi
                    .replace("[fill]", this.colorAlert)

                break;
            }
            case this.markerTypes.WifiAnalytic: {

                markerSvg = this.svgMarkerWifi
                    .replace("[fill]", this.colorAnalytics)

                break;
            }
            case this.markerTypes.Cellular: {

                markerSvg = this.svgMarkerLte
                    .replace("[fill]", this.colorCellular)

                break;
            }
            case this.markerTypes.CellularAlert: {

                markerSvg = this.svgMarkerLte
                    .replace("[fill]", this.colorAlert)

                break;
            }
            case this.markerTypes.CellularComm: {

                markerSvg = this.svgMarkerCommunicationOffline
                    .replace("[fill]", this.colorWarning)

                break;
            }
            case this.markerTypes.CellularOffline: {

                markerSvg = this.svgMarkerLteOffline
                    .replace("[fill]", this.colorAlert)

                break;
            }
            case this.markerTypes.CellularAnalytic: {

                markerSvg = this.svgMarkerLte
                    .replace("[fill]", this.colorAnalytics)

                break;
            }
            case this.markerTypes.BatteryLow: {
                markerSvg = this.svgMarkerBatteryLow
                    .replace("[fill]", this.colorWarning)

                break;
            }
            case this.markerTypes.BatteryCritical: {
                markerSvg = this.svgMarkerBatteryCritical
                    .replace("[fill]", this.colorAlert)

                break;
            }
            default: {
                //nothing; 
                break;
            }
        }

        return markerSvg
    }

    private svgMarker: string =
        '<svg display="block" height="41px" width="27px" viewBox="0 0 27 41"><g fill-rule="nonzero">' +
        '<g transform="translate(3.0, 29.0)" fill="#000000"><ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse><ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse><ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="9.5" ry="4.77275007"></ellipse><ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="8.5" ry="4.29549936"></ellipse><ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="7.5" ry="3.81822308"></ellipse><ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="6.5" ry="3.34094679"></ellipse><ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="5.5" ry="2.86367051"></ellipse><ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="4.5" ry="2.38636864"></ellipse></g><g fill="#3FB1CE"><path d="M27,13.5 C27,19.074644 20.250001,27.000002 14.75,34.500002 C14.016665,35.500004 12.983335,35.500004 12.25,34.500002 C6.7499993,27.000002 0,19.222562 0,13.5 C0,6.0441559 6.0441559,0 13.5,0 C20.955844,0 27,6.0441559 27,13.5 Z"></path></g><g opacity="0.25" fill="#000000"><path d="M13.5,0 C6.0441559,0 0,6.0441559 0,13.5 C0,19.222562 6.7499993,27 12.25,34.5 C13,35.522727 14.016664,35.500004 14.75,34.5 C20.250001,27 27,19.074644 27,13.5 C27,6.0441559 20.955844,0 13.5,0 Z M13.5,1 C20.415404,1 26,6.584596 26,13.5 C26,15.898657 24.495584,19.181431 22.220703,22.738281 C19.945823,26.295132 16.705119,30.142167 13.943359,33.908203 C13.743445,34.180814 13.612715,34.322738 13.5,34.441406 C13.387285,34.322738 13.256555,34.180814 13.056641,33.908203 C10.284481,30.127985 7.4148684,26.314159 5.015625,22.773438 C2.6163816,19.232715 1,15.953538 1,13.5 C1,6.584596 6.584596,1 13.5,1 Z"></path></g>' +
        '<g transform="translate(6.0, 7.0)" fill="#FFFFFF"></g>' +
        '<g transform="translate(8.0, 8.0)"><circle fill="#000000" opacity="0.25" cx="5.5" cy="5.5" r="5.4999962"></circle><circle fill="#FFFFFF" cx="5.5" cy="5.5" r="5.4999962"></circle></g>' +
        '</g></svg>'

    // private colorLocation: string = "#ee82ee" //ff66ff
    // private colorCellular: string = "#37bc9b"
    // private colorWifi: string = "#5d9cec" //37bc9b
    // private colorAlert: string = "#f05050"
    // private colorAnalytics: string = "#ff902b"
    public getMarkerTypeSvgOld(markerType: mapMarkerTypes): string {
        var markerSvg: string = ""

        switch (markerType) {
            case this.markerTypes.PreviousLocation: {

                markerSvg = this.svgMarker
                    .replace("[stroke]", "white")
                    .replace("[stroke-width]", "0.5")
                    .replace("[fill]", this.colorLocation)
                    .replace("[fill-inner]", this.colorLocation)

                break;
            }
            case this.markerTypes.Wifi: {

                markerSvg = this.svgMarker
                    .replace("[stroke]", "white")
                    .replace("[stroke-width]", "0.5")
                    .replace("[fill]", this.colorWifi)
                    .replace("[fill-inner]", this.colorWifi)

                break;
            }
            case this.markerTypes.WifiAlert: {

                markerSvg = this.svgMarker
                    .replace("[stroke]", "white")
                    .replace("[stroke-width]", "0.5")
                    .replace("[fill]", this.colorWifi)
                    .replace("[fill-inner]", this.colorAlert)

                break;
            }
            case this.markerTypes.WifiAnalytic: {

                markerSvg = this.svgMarker
                    .replace("[stroke]", "white")
                    .replace("[stroke-width]", "0.5")
                    .replace("[fill]", this.colorWifi)
                    .replace("[fill-inner]", this.colorAnalytics)

                break;
            }
            case this.markerTypes.Cellular: {

                markerSvg = this.svgMarker
                    .replace("[stroke]", "white")
                    .replace("[stroke-width]", "0.5")
                    .replace("[fill]", this.colorCellular)
                    .replace("[fill-inner]", this.colorCellular)
                break;
            }
            case this.markerTypes.CellularAlert: {

                markerSvg = this.svgMarker
                    .replace("[stroke]", "white")
                    .replace("[stroke-width]", "0.5")
                    .replace("[fill]", this.colorCellular)
                    .replace("[fill-inner]", this.colorAlert)
                break;
            }
            case this.markerTypes.CellularAnalytic: {

                markerSvg = this.svgMarker
                    .replace("[stroke]", "white")
                    .replace("[stroke-width]", "0.5")
                    .replace("[fill]", this.colorCellular)
                    .replace("[fill-inner]", this.colorAnalytics)
                break;
            }
            default: {
                //nothing; 
                break;
            }
        }

        return markerSvg
    }

    //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    //:::                                                                         :::
    //:::  This routine calculates the distance between two points (given the     :::
    //:::  latitude/longitude of those points). It is being used to calculate     :::
    //:::  the distance between two locations                                     :::
    //:::                                                                         :::
    //:::  Definitions:                                                           :::
    //:::    South latitudes are negative, east longitudes are positive           :::
    //:::                                                                         :::
    //:::  Passed to function:                                                    :::
    //:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
    //:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
    //:::    unit = the unit you desire for results                               :::
    //:::           where: 'M' is statute miles (default)                         :::
    //:::                  'K' is kilometers                                      :::
    //:::                  'N' is nautical miles                                  :::
    //:::                                                                         :::
    //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    distanceBetweenPoints(lat1, lon1, lat2, lon2, unit) {
        var radlat1 = Math.PI * lat1 / 180
        var radlat2 = Math.PI * lat2 / 180
        var theta = lon1 - lon2
        var radtheta = Math.PI * theta / 180
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist)
        dist = dist * 180 / Math.PI
        dist = dist * 60 * 1.1515
        if (unit == "K") { dist = dist * 1.609344 }
        if (unit == "N") { dist = dist * 0.8684 }
        return dist
    }

    searchAddressFreeform(
        address: string,
        city: string,
        stateProvinceCode: string,
        postalCode: string,
        countryCode: string): Observable<any> {
        var searchString: string = '';

        if (address && address.trim() != "") { searchString += address.trim() + ' ' }
        if (city && city.trim() != "") { searchString += city.trim() + ' ' }
        if (stateProvinceCode && stateProvinceCode.trim() != "") { searchString += stateProvinceCode.trim() + ' ' }
        if (postalCode && postalCode.trim() != "") { searchString += postalCode.trim() + ' ' }
        if (countryCode && countryCode.trim() != "") { searchString += countryCode.trim() + ' ' }

        var url: string = 'https://geocode.search.hereapi.com/v1/geocode?q=' + searchString + '&apiKey=' + this.apiKey;

        url = url.trim().replace(' ', '%20')
        const jsonHeaders = new HttpHeaders(
            {
                'Content-Type': 'application/x-www-form-urlencoded;',
            });

        return this.http.get<any>(url, { headers: jsonHeaders })
            .pipe(oMap(res => {

                if (res && res.items.length > 0) {

                    return res;
                } else
                    return []
            })
            )

    }

    reverseGeocode(
        latitude: number,
        longitude: number
    ): Observable<any> {


        url = latitude.toFixed(6).toString() + '%2C' + longitude.toFixed(6).toString();
        var url: string = 'https://revgeocode.search.hereapi.com/v1/revgeocode?' + 'at=' + url + "&lang=en-US" + '&apiKey=' + this.apiKey

        const jsonHeaders = new HttpHeaders(
            {
                'Content-Type': 'application/x-www-form-urlencoded;',
            });

        return this.http.get<any>(url, { headers: jsonHeaders })
            .pipe(oMap(res => {

                if (res && res.items.length > 0) {

                    return res;
                } else
                    return []
            })
            )

    }

}


