import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, OnDestroy, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FileUploader, FileUploaderOptions, FileItem } from 'ng2-file-upload';
import { SettingsService, userTypes, userRoles } from '../../../core/settings/settings.service';
import { HttpClient } from '@angular/common/http';
import { ApiUuidService } from '../../../core/api/_api-uuid.service';
import {  Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DevicesService } from '../../../core/api/devices.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { template } from 'lodash';
import { DeviceLocationsFormComponent } from '../forms/devicelocations/devicelocations-form.component';




@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UploadComponent implements OnInit, AfterViewInit, OnDestroy{
    @Output() onAfterAddingAll: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild(DeviceLocationsFormComponent) public deviceLocForm: DeviceLocationsFormComponent;
    @Output() onBeforeUploadItem: EventEmitter<any> = new EventEmitter<any>()
    @Output() onErrorItem: EventEmitter<any> = new EventEmitter<any>()
    @Output() onCancelItem: EventEmitter<any> = new EventEmitter<any>()
    @Output() onSuccessItem: EventEmitter<any> = new EventEmitter<any>()
    @Output() onCompleteItem: EventEmitter<any> = new EventEmitter<any>()
    @Output() onCompleteAll: EventEmitter<any> = new EventEmitter<any>()
    
    @Input() public showUpload: boolean = false;
    @Input() multipleFiles: boolean = true;
    @Input() uploadUrl: string = "";
    @Input() expectedFileType: 'csv' | 'blob';

    //observables
	private onDestroy$: Subject<void> = new Subject<void>();
	public device: any[] = [];
	public clients: any[] = [];

    public deviceHasLocation = true;
    public hasFormState = false;
    public deviceSerialError = false;

    //formcontrols
	public fgSearch: UntypedFormGroup = this.formBuilder.group({
		serial: ['']
	});

    constructor(
        public settingsService: SettingsService,
        protected http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        public toastrService: ToastrService,
        private formBuilder: UntypedFormBuilder,
        // private modalService: BsModalService,
        private devicesService: DevicesService,
        ) {}

    
    public modalRef: BsModalRef;

    public uploader: FileUploader = new FileUploader(
        {
            url: this.uploadUrl,
            itemAlias: 'file' //default name
        });

    public hasBaseDropZoneOver: boolean = false;

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }
    public uploaderReset(): void {
        this.uploader.cancelAll();
        this.uploader.clearQueue()
    }
    public uploaderFileItemMessage(fileItem: FileItem) {
        if (fileItem.isSuccess || fileItem.isError) {
            if (fileItem._xhr && fileItem._xhr.responseText && (fileItem._xhr.status == 200 || fileItem._xhr.status == 400)) {
                if (fileItem._xhr.status == 200) {
                    var responseMessage = JSON.parse(fileItem._xhr.responseText);
                    if (responseMessage.message) return responseMessage.message
                }
                if (fileItem._xhr.status == 400) {
                    var responseMessage = JSON.parse(fileItem._xhr.responseText);
                    if (responseMessage.message) return "Error: " + responseMessage.message
                }
            }

            if (fileItem._xhr) {
                return "HTTP Status " + fileItem._xhr.status.toString() + " - " + fileItem._xhr.statusText.toString();
            }

            return "Unknown Error";
        }
        return "";
    }

    public afterAddingFile(fileItem) {
        if (this.multipleFiles == false) {
            for (let upFile of this.uploader.queue) {
                if (fileItem.file.name != upFile.file.name)
                {
                    this.uploader.removeFromQueue(upFile)
                }
            }
        }
    }

    public onAfterAddingAllFiles() {
        this.uploader.onAfterAddingAll = (fileItems) => {
            console.info('onAfterAddingAll', fileItems);
            // this.onAfterAddingAll.emit(fileItems)

            this.fgSearch.value.serial = '';
            for (let char of fileItems[0].file.name) {
                if (char == '_') {
                    break;
                } 
                else if (char == 'x') {
                    continue;
                } 
                else {
                    this.fgSearch.value.serial += char;
                }
            }
            
          
            if (this.expectedFileType === 'csv' && fileItems[0].file.name.split('.').pop() !== 'csv') {
                fileItems[0].remove();
                this.toastrService.warning("File Upload", "File must be .csv format", { timeOut: 15000, tapToDismiss: true });
            } 
            else if (this.expectedFileType === 'csv' && fileItems[0].file.name.split('.').pop() === 'csv') {
                this.deviceSerialError = false;
                this.getDeviceBySerial();
                setTimeout(() => {
                    if (this.deviceSerialError) {                        
                        fileItems[0].remove();
                    }}, 500);
            }

            if (this.expectedFileType === 'blob' && fileItems[0].file.name.split('.').pop() !== 'blob') {
                fileItems[0].remove();
                this.toastrService.warning("File Upload", "File must be .blob format", { timeOut: 15000, tapToDismiss: true });
            } 
            
            
        };
    }

    public onBeforeUploadFile() {
        this.uploader.onBeforeUploadItem = (item) => {
            item.withCredentials = false;

            var token: String = '';
            let authenticationResponse = JSON.parse(localStorage.getItem('authenticationResponse'));
            if (authenticationResponse && authenticationResponse.access_token) {
                token = authenticationResponse.access_token;
            }
            
            var uo: FileUploaderOptions = {
                url: '', //TODO: add the actual backend URL here for file uploads.
                headers: [
                    { name: 'Authorization', value: 'Bearer ' + token },
                    { name: 'ClientUuid', value: this.settingsService.client.uuid }
                ]
            };

            this.uploader.setOptions(uo);

            // this.onBeforeUploadItem.emit(item)
        };
    }

    public onSuccessFile() {
        this.uploader.onSuccessItem = (fileItem, response: string, status: number, headers) => {
            console.info('onSuccessItem', fileItem, response, status, headers);
            this.onSuccessItem.emit({fileItem, response, status, headers})
        };
    }

    public onErrorFile() {
        this.uploader.onErrorItem = (fileItem, response: string, status: number, headers) => {
            console.info('onErrorItem', fileItem, response, status, headers);
            this.onErrorItem.emit({fileItem, response, status, headers})
        };
    }

    public onCancelFile() {
        this.uploader.onCancelItem = (fileItem, response: string, status: number, headers) => {
            console.info('onCancelItem', fileItem, response, status, headers);
            this.onCancelItem.emit({fileItem, response, status, headers})
        };
    }

    public onCompleteFile() {
        this.uploader.onCompleteItem = (fileItem, response: string, status: number, headers) => {
            console.info('onCompleteItem', fileItem, response, status, headers);
            this.onCompleteItem.emit({fileItem, response, status, headers})
        };
    }

    public onCompleteAllFile() {
        this.uploader.onCompleteAll = () => {
            console.info('onCompleteItem');
            this.onCompleteItem.emit(this.uploader.getNotUploadedItems())
        };
    }


    ngOnInit() {      
        let self = this;

        if (this.deviceLocForm?.formState == this.deviceLocForm?.formStates?.New) {
            this.hasFormState = true;
        }

    

        this.uploader.options.url = this.uploadUrl;
        console.log('uploadOnIt')

        this.deviceLocForm;
    }

    
    
    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
    
    ngAfterViewInit() {
       this.onAfterAddingAllFiles();
        this.onBeforeUploadFile();
        this.onSuccessFile();
        this.onErrorFile();
        this.onCancelFile();
        this.onCompleteFile();
        this.onCompleteAllFile();
    }
    
    locationFormAppear(event: any) {
        if (this.deviceHasLocation == false) {
            this.deviceLocForm.deviceUuid = this.device[0].uuid;
            this.deviceLocForm.formState = this.deviceLocForm.formStates.New;
        }
    }


    getDeviceBySerial() {

		return this.devicesService.getDeviceBySerial(this.fgSearch.value.serial)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(result: any) => {
					this.device[0] = result;
					for (var i = 0; i < this.clients.length; i++) {
						if (this.clients[i].uuid == this.device[0].clientUuid) {
							this.device[0].Client = this.clients[i];
						}
					}

                    if (result.deviceLocation === null) {
                        this.deviceHasLocation = false;
                        // this.router.navigate(['/devices/', this.device[0].uuid]);
                        this.toastrService.warning("Location Needed", "Device requires a location", { timeOut: 15000, tapToDismiss: true });
                        setTimeout(() => {
                            if (this.deviceHasLocation == false) {
                                
                                this.deviceLocForm.formState = 4;
                                this.deviceLocForm.formEnabled = true;
                                this.deviceLocForm.device = this.device[0];
                                this.deviceLocForm.deviceUuid = this.device[0].uuid;
                                }
                        }, 25); 
                        
                    } else {
                        this.deviceHasLocation = true;
                    }
				},
				error => {
					this.toastrService.warning("(getDeviceBySerial)", "Device Serial Not Found", { timeOut: 15000, tapToDismiss: true });
                    this.deviceSerialError = true;
				});           
	}
}
