import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { AlertsService } from '../../../core/alerts/alerts.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { SettingsService, userTypes, userRoles } from '../../../core/settings/settings.service';


@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [AuthenticationService, AlertsService, RouterModule, SettingsService]
})

export class LoginComponent implements OnInit {
    model: any = {};
    loading = false;
    returnUrl: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public authenticationService: AuthenticationService,
        private alertService: AlertsService,
        public settingsService: SettingsService,
        private toastrService: ToastrService) { }

    ngOnInit() {
        // reset login status
        this.authenticationService.clear();
        this.settingsService.clearClients();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        console.log("returnUrl: "+this.returnUrl)
    }

    login() {
        this.loading = true;
        this.settingsService.clearClients();
        
        this.authenticationService.login(this.model.username, this.model.password)
            .subscribe(
                data => {
                    if (data.error) {
                        this.toastrService.error("Login Message", data.errorText, {tapToDismiss: true});
                        this.loading = false;
                    }
                    this.toastrService.clear()
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    if (error.status==401)
                    {
                        this.toastrService.error("Login Error", "User Name Or Password Incorrect", 
                        {
                            timeOut: 0,
                            tapToDismiss: true
                        });
                    }
                    else{
                        this.toastrService.error("Server Error: "+error.status, error.statusText, 
                        {
                            timeOut: 0,
                            progressBar: true,
                            tapToDismiss: true
                        });
                    }
                    this.loading = false;
                });
    }

    public isIE() {
        
        const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
        const msie = ua.indexOf('MSIE '); // IE 10 or older
        const trident = ua.indexOf('Trident/'); //IE 11
    
        return (msie > 0 || trident > 0);
    }
    
}
