import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiUuidService } from './_api-uuid.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorsService } from '../errors/errors.service';

@Injectable()
export class UsersService extends ApiUuidService {
    constructor(
        protected router: Router,
        protected http: HttpClient,
        protected errorsService: ErrorsService,
        protected toastr: ToastrService,
    ) {
        super(router, http, 'users');
    }

    register(user: any): Observable<any> {
        var apiParam: string = `${this.getApiUrl()}/${this.apiRoute}/register`;
        //var headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }

        return this.http.post<any>(
            apiParam,
            user
        );
    }

    create(user: any): Observable<any> {
        var apiParam: string = `${this.getApiUrl()}/${this.apiRoute}`;
        //var headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }

        return this.http.post<any>(
            apiParam,
            user
        );
    }

    reset(email: string): Observable<any> {
        var apiParam: string = `${this.getApiUrl()}/${this.apiRoute}/reset`;

        return this.http.post<any>(
            apiParam,
            { email: email }
        );
    }

    setNewPassword(newpassword: string, uuid: string): Observable<any> {
        var apiParam: string = `${this.getApiUrl()}/${this.apiRoute}/reset/${uuid}`;

        return this.http.put<any>(
            apiParam,
            { newPassword: newpassword }
        );
    }

    read() {
        return this.all();
    }

    readByUuid(uuid: string): Observable<any> {
        return this.get(uuid);
    }

    async readByUuidAync(uuid: string): Promise<any> {
        return this.get(uuid);
    }

    readClients(uuid: string): Observable<any> {
        var apiParam: string = `${uuid}/clients`;

        return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`);
    }

    readUsersClient(): Observable<any> {
        var apiParam: string = `client`;

        return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`);
    }

    updateClients(
        uuid: string,
        clientDtos: any): Observable<any> {// 

        var apiParam: string = `${uuid}/clients`;

        return this.http.put(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`, clientDtos);
    }

    readUserClientRole(uuid: string): Observable<any> {
        return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${uuid}/role`);
    }

    updateRoleId(uuid: string, roleId: string): Observable<any> {
        var apiParam: string = `${uuid}/role/${roleId}`;

        return this.http.put(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`, {});
    }

    removeRoleId(uuid: string): Observable<any>{
        var apiParam: string = `${uuid}/role/`;

        return this.http.delete(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`, {});
    }

    checkUserByEmail(userEmail: string): Observable<any> {
        var apiParam: string = `${userEmail}/email`;

        return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`);
    }

    getUserProfile(uuid: string): Observable<any>{
        var apiParam: string = `${uuid}/userProfile`

        return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`);
    }

    updateUserProfile(uuid: string,
        UserProfileDto: any): Observable<any>{
        var apiParam: string = `${uuid}/userProfile`;

        return this.http.put(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`, UserProfileDto);
    }

  
}