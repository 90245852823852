<div class="card-body">
  <div class="form-group row">
    <div class="col-lg-12">
      <form [formGroup]="fgUser">
        <!-- <div class="form-row">
          <div class="col-md-6 col-sm-12 mb-2">
            <label>Customer</label>
            <ng-select #clientUuid [selectOnTab]="true" [items]="clients" bindValue="uuid" bindLabel="name" [clearable]="false"
              id="clientUuid" name="clientUuid" formControlName="clientUuid" placeholder="Customer">
            </ng-select>
          </div>
        </div> -->
        <div class="row">
          <div class="col-sm-6 col-12 m-0 p-0">
            <div class="form-row">
              <div class="col-sm-6 col-12 mb-2">
                <label>First Name</label>
                <div *ngIf="!isEditMode" class="form-control">{{user?.first ? user.first : '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgUser.get('first'))"
                  id="first" name="first" formControlName="first" type="text" placeholder="User First Name" />
                <div *ngIf="librariesService.controlInvalidFlag(fgUser.get('first'))" class="invalid-feedback">Please
                  provide a First Name</div>
              </div>
              <div class="col-sm-6 col-12 mb-2">
                <label>Last Name</label>
                <div *ngIf="!isEditMode" class="form-control">{{user?.last ? user.last : '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgUser.get('last'))"
                  id="last" name="last" formControlName="last" type="text" placeholder="User Last Name" />
                <div *ngIf="librariesService.controlInvalidFlag(fgUser.get('last'))" class="invalid-feedback">Please
                  provide a Last Name</div>
              </div>
              <div class="col-sm-12 col-12 mb-2">
                <label>Email</label>
                <div *ngIf="!isEditMode" class="form-control">{{user?.email ? user.email : '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgUser.get('email'))"
                  id="email" name="email" formControlName="email" type="text" placeholder="User Email Address"
                  autocomplete="off" />
                <div *ngIf="librariesService.controlInvalidFlag(fgUser.get('email'))" class="invalid-feedback">Please
                  provide a valid
                  Email</div>
              </div>
              <div class="col-sm-6 col-12 mb-2 mr-sm-2 mt-2">
                <div class="checkbox c-checkbox">
                  <label>
                    <input #sendInvitation type="checkbox" [attr.disabled]="fgUser.get('passwordChange').value ==true || !isEditMode ? '' : null" id="sendInvitation"
                      name="sendInvitation" formControlName="sendInvitation" />
                    <span class="fas fa-check"></span>Send Welcome Email</label>
                  <!-- [attr.disabled]="!isEditMode ? '' : null"  -->
                </div>

                <div class="checkbox c-checkbox pt-2">
                  <label>
                    <input #passwordChange type="checkbox" [attr.disabled]="fgUser.get('sendInvitation').value ==true || !isEditMode ? '' : null" id="passwordChange"
                      name="passwordChange" formControlName="passwordChange" />
                    <span class="fas fa-check"></span>Change Password</label>
                  <!-- [attr.disabled]="!isEditMode ? '' : null"  -->
                </div>

              </div>
              <div class="col-sm-6 col-12 mb-2">
                <label>Password</label>
                <input [class]="librariesService.controlValidationClass(fgUser.get('password'))" id="password"
                  name="password" formControlName="password" type="password" placeholder="User Password"
                  autocomplete="new-password" />
                <div *ngIf="librariesService.controlInvalidFlag(fgUser.get('password'))" class="invalid-feedback">
                  Minimum
                  8
                  characters with mixed-case, numbers, and special characters (#&#64;$!%*?()>,:/=^_-~|)</div>
              </div>
              <div class="col-sm-6 col-12 mb-2">
                <label>Confirm Password</label>
                <input [class]="librariesService.controlValidationClass(fgUser.get('passwordConfirm'))"
                  id="passwordConfirm" name="passwordConfirm" formControlName="passwordConfirm" type="password"
                  placeholder="Confirm User Password" autocomplete="new-password" />
                <div *ngIf="librariesService.controlInvalidFlag(fgUser.get('passwordConfirm'))"
                  class="invalid-feedback">Does
                  not match Password</div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-12">
            <label>User Type</label>
            <ng-select #userTypeId [selectOnTab]="true" [items]="userTypes" bindValue="id" bindLabel="name"
              [clearable]="false" id="userTypeId" name="userTypeId" formControlName="userTypeId"
              placeholder="User Account Type">
            </ng-select>

            <label class="mt-2">Client Access</label>
            <div *ngIf="(fgUser.get('userTypeId').value!=0)" class="form-control">{{(fgUser.get('userTypeId').value!=0)
              ? "All Clients" : "&nbsp;" }}</div>
            <ng-select *ngIf="(fgUser.get('userTypeId').value==0)" #clientsSelect [selectOnTab]="true" [items]="clients"
              bindValue="uuid" bindLabel="name" [multiple]="true" [clearable]="false" id="clientsSelect"
              name="clientsSelect" formControlName="clientsSelect" placeholder="Select Clients">
            </ng-select>

          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="pt-0 text-right uuid-opaque">{{ (this.userUuid && this.userUuid.toString() !="") ?
    this.userUuid.toString() : "&nbsp;"}}</div>
  <!-- {{fgUser.get('passwordChange').value==false}}<br><br>
  {{fgUser.value | json}}<br><br>
  {{clientsSelected | json}}<br><br> -->
</div>