<div class="row" *ngIf="!showDailyEventSchedulesForm">
    <div class="col-12 pr-2 pl-2">
        <!-- Form Command Buttons -->
        <div class="col-md-6 pr-0 float-right text-right">
            <button *ngIf="!showDailyEventSchedulesForm && isEditMode" type="button" class="btn btn-primary"
                (click)="buttonAdd($event)" [disabled]="false">
                <span class="fas fa-calendar-plus pr-2"></span>Add Event Schedule</button>

            <button *ngIf="showDailyEventSchedulesForm && !isEditMode" type="button" class="btn btn-primary"
                (click)="listingEvents($event)" [disabled]="false">
                <span class="fas fa-th-list pr-2"></span>Listing</button>
        </div>
    </div>
</div>

<div class="row" *ngIf="!showDailyEventSchedulesForm">
    <div class="col-12 pl-1 pr-0">


        <!--   TODO: ADD AG GRID TO THIS TEMPLATE    -->


        <!-- <ngx-datatable #dailyEventSchedulesTable class='bootstrap no-detail-row table-hover' [selectionType]="'single'"
            [selected]="dailyEventSchedulesSelected" [columnMode]="'flex'" [footerHeight]="20" [rowHeight]="'auto'"
            [limit]="24" [rows]='DailyEventSchedules' (select)='onSelect($event)'>

            <ngx-datatable-column [resizeable]="false" name="Icon" [flexGrow]="0.5">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="fas fa-calendar-alt" [style.color]="'#'+row.colorCode"></span>
                    </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Color" prop="colorCode" [flexGrow]="1"></ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Day Schedule Name" prop="name" [flexGrow]="2.5"></ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Created" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div></div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="" [flexGrow]="0.8" cellClass='ngx-cell-align-right'>
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <table style="border: none; padding: 0">
                        <tr>
                            <td style="border: none; padding: 0; white-space: nowrap;">

                            </td>
                            <td style="border: none; padding: 0; white-space: nowrap;">

                            </td>
                        </tr>
                    </table>
                    <div style="white-space: nowrap;">
                        <button *ngIf="isEditMode" type="button" class="btn btn-xs btn-danger"
                            (click)="buttonDelete(row)"><span class="fas fa-trash-alt"></span></button>
                        <button *ngIf="isEditMode" type="button" class="btn btn-xs btn-primary ml-2"
                            (click)="buttonEdit(row)"><span class="fas fa-edit"></span></button>
                    </div>

                </ng-template>
            </ngx-datatable-column>


        </ngx-datatable> -->
    </div>
</div>

<div class="row">
    <div class="col-12">
        <!-- <app-dailyeventschedules-view [hidden]="showDailyEventSchedulesForm"></app-dailyeventschedules-view> -->
        <app-dayschedulesschedule-form  [DeviceSchedule]="DeviceSchedule" [ConfigMapOptions]="ConfigMapOptions" (onFormEvent_EventSchedule)="onEventScheduleEmitter($event)"
            [hidden]="!showDailyEventSchedulesForm" #eventScheduleForm></app-dayschedulesschedule-form>
    </div>
</div>
<!-- {{DeviceSchedule | json}} -->
<!-- {{ConfigMapOptions | json}} -->