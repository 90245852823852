<div class="card border-0">
    <div class="card-body pt-0">
        <div class="row">
            <div class="col-lg-6 align-middle">
                <input type='text' #datatableFilter class="form-control" 
                placeholder='Type to filter by Code or Description' (keyup)='updateFilter($event?.target?.value?.toLowerCase())'
                />
            </div>
        </div>
        <ag-grid-angular #agGrid id="devicesViewTable" name="devicesViewTable" style="height: 78vh; width: 100%;"
            class="devicesViewTable m-0 pt-2 border-0"
            [animateRows]="true" [suppressCellFocus]="true"  [rowData]="models" [theme]="theme" [autoSizeStrategy]="autoSizeStrategy"
            (rowSelected)="onRowSelected($event)" [chartThemes]="chartThemes" [rowStyle]="rowStyle"
            (rowClicked)="onRowClicked($event)" [gridOptions]="gridOptions"
            [getChartToolbarItems]="getChartToolbarItems">
        </ag-grid-angular>
    </div>
</div>

<!-- <ngx-datatable #table class='bootstrap no-detail-row' (select)='onSelect($event)' [selectionType]="'single'" [selected]="modelsSelected" 
    [columnMode]="'flex'" [footerHeight]="20" [rowHeight]="'auto'" [limit]="15" [rows]='models'>

    <ngx-datatable-column [resizeable]="false" name="Identifier"  [flexGrow]="2" >
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{row.id}}</div>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [resizeable]="false" name="Code"  [flexGrow]="1" >
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{row.code}}</div>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [resizeable]="false" name="Description"  [flexGrow]="4" >
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{row.description}}</div>
        </ng-template>
    </ngx-datatable-column>
    
    <ngx-datatable-column [resizeable]="false" name="Target Firmware"  [flexGrow]="4" >
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{row.firmwareTarget?.code}}</div>
        </ng-template>
    </ngx-datatable-column>

</ngx-datatable> -->
<!-- </div>
</div> -->