<div class="row">
    <div class="col-lg-6 align-middle">
        <input type='text' class="form-control" placeholder='Type to filter by Device Name, Location, or Group...'
            (keyup)='updateFilter($event)' />
    </div>
    <i class="fas fa-walking fa-2x ml-2 mt-1" title="Google Maps Street View" (click)="streetWindow()"></i>
    <div class="col-lg-12 mt-2">
        <ag-grid-angular #agGrid id="adminDevicesTable" name="adminDevicesTable" style="height: 200px; width: 100%;"
        class="ag-theme-balham adminDevicesTable mt-2 pt-2 border-0" [animateRows]="true" [suppressCellFocus]="true"
        (gridReady)="onGridReady($event)" 
        [chartThemes]="chartThemes" [gridOptions]="gridOptions"
        [getChartToolbarItems]="getChartToolbarItems">
        </ag-grid-angular>
    </div>
</div>

