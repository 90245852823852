
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, ElementRef, ViewEncapsulation, ViewChild, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Route } from '@angular/router';
import { MenuService } from '../../../../core/menu/menu.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { ClientsService, SubscriptionsService } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
// import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { Subject } from 'rxjs';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions, SizeColumnsToContentStrategy } from 'ag-grid-community';
import { customAgTheme } from 'app/shared/styles/themes/theme-ag-custom';
import { LibrariesService } from '../../../../core/libraries/libraries.service';


export enum SubscriptionTypes {
	Configuration = 0,
	Data = 1

}

@Component({
	selector: 'app-subscriptions-admin-view',
	templateUrl: './subscriptions-admin-view.component.html',
	styleUrls: ['./subscriptions-admin-view.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class SubscriptionsAdminViewComponent implements OnInit, AfterViewInit, OnDestroy {
	@Output() onDeviceSelected: EventEmitter<any> = new EventEmitter()
	@Output() onDataLoading: EventEmitter<boolean> = new EventEmitter()
	@ViewChild('datatableFilter') datatableFilter: ElementRef;
	@ViewChild('agGrid') grid!: AgGridAngular;
	// @ViewChild(DatatableComponent) dataView: DatatableComponent;

	private onDestroy$: Subject<void> = new Subject<void>();
	public subscriptions: any[] = [];
	public subscriptionsTemp: any[] = [];
	public clients: any[] = [];
	public rowStyle;
	public rowSelection: 'single' | 'multiple' = 'multiple';
	public isSelected: boolean = false;
	public dataLoading: boolean = false;

	public showForm: boolean = false;

	public gridOptions: GridOptions;
	public chartThemes;
	private _gridData: any = null;

	public theme = customAgTheme;






	get gridData(): any {
		return this._gridData;
	}
	@Input() set gridData(newGridData: any) {
		this._gridData = newGridData;
	}

	constructor(
		public menu: MenuService,
		public eventsService: EventsService,
		public toastrService: ToastrService,
		public subscriptionsService: SubscriptionsService,
		public librariesService: LibrariesService,
		public router: Router,
		public route: ActivatedRoute,
		public errorsService: ErrorsService,
		public settingsService: SettingsService,
		public clientsService: ClientsService) {
		this.chartThemes = [
			'ag-pastel',
			'ag-default',
			'ag-material-dark',
			'ag-vivid-dark',
			'ag-solar',

		];
		this.rowStyle = {
			cursor: 'pointer'
		}
		this.gridOptions = <GridOptions>{
			rowData: this.gridData,
			columnDefs: this.columnDefs,
			cursor: "pointer",
			rowStyle: this.rowStyle,
			rowSelection: 'multiple',
			suppressRowClickSelection: true,
			groupDisplayType: 'groupRows',
			onCellClicked: (e) => {
				if (e.colDef.field != 'serial') { // cell is from non-select column
					e.node.setSelected(true);
					this.isSelected = true;
				}
			},


			//chartThemeOverrides: this.chartThemeOverrides,
			enableCharts: false,
			enableRangeSelection: false
			// pagination: true,
			// rowSelection: 'single'
		}
	}


	columnDefs = [
		{ headerName: "Client", field: "clientName", sort: 'asc', sortable: true, resizable: true, suppressMenu: true },
		{ headerName: "Sales Order No.", field: "notes", sortable: true, resizable: true, suppressMenu: true },
		{
			headerName: "Type", field: "typeId", sortable: true, resizable: true, suppressMenu: true,
			cellRenderer: (data) => {
				let myType = '';

				if (data.value == 3) {
					myType = 'Premium'
				}
				if (data.value == 2) {
					myType = 'Configuration'
				}
				if (data.value == 1) {
					myType = 'Data Only'
				}
				else if (data.value == 0) {
					myType = 'Default'
				}

				return myType;
			}
		},
		{
			headerName: "Subscription Status", field: "alertStatusName", sortable: true, resizable: true, suppressMenu: true,
			cellStyle: (data) => {
				if (data.value == 'Expired') {
					return {color: 'red' }

				}
				else {
					return null;
				}
			}

		},
		{
			headerName: "Expiration Date", field: "expirationDate", sortable: true, resizable: true, suppressMenu: true,
			cellRenderer: (data) => {
				return data.value ? (data.value != null
					? (this.librariesService.datePipe.transform(new Date(data.value), 'MM-dd-yyyy'))
					: '') : '';
			}
		},
		{
			headerName: "Device Count", field: "subscriptionDevices", sortable: true, resizable: true, suppressMenu: true,
			cellRenderer: (data) => {
				let deviceCount = 0;

				for (var i = 0; i < data.value.length; i++) {
					deviceCount++;


				}
				return deviceCount;

			}
		},
		{ headerName: "Uuid", field: "uuid", sortable: true, resizable: true, flex: 1, suppressMenu: true }
	]

	autoSizeStrategy:
    SizeColumnsToContentStrategy = {
      type: "fitCellContents",
      colIds: [ "clientName", "notes", "typeId", "alertStatusName", "expirationDate", "subscriptionDevices" ]
    };


	ngOnInit() {

		console.log(this.route.routeConfig.component.name + ":ngOnInit", this)

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				if (this.router.url.toLowerCase().split("?")[0] == '/administration/subscriptions/all') {
					console.log(this.route.routeConfig.component.name + ":NavigationEnd")
					this.dataRefresh();
				}
			}
		});


	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
	}

	ngAfterViewInit() {
		console.log(this.route.routeConfig.component.name + ":ngAfterViewInit", this)
		this.dataRefresh();

	}


	dataRefresh() {
		var self = this;
		if (!this.settingsService.client) {
			setTimeout(() => {
				self.dataRefresh();
			}, 100);
		}
		else {
			setTimeout(function () {
				self.getSubscriptions();
				self.getClients();
			}, 500);
		}
	}


	getSubscriptions() {

		this.dataLoading = true;
		this.onDataLoading.emit(this.dataLoading)

		return this.subscriptionsService.Read()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(result: any) => {
					this.subscriptions = result;
					this.subscriptionsTemp = result;

					this.dataLoading = false;
					this.onDataLoading.emit(this.dataLoading)
				},
				error => {
					this.toastrService.error("Server Error (getSubscriptions)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
					this.dataLoading = false;
					this.onDataLoading.emit(this.dataLoading)

				});
	}

	getClients(): any {
		this.clients = [];

		if (!this.settingsService.client) return;

		return this.clientsService.read()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(result: any) => {
					this.clients = result;
				},
				error => {
					this.settingsService.hideSpinner("deviceAdminForm");
					this.toastrService.error("Server Error (Getting Clients)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
				});
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();
		// filter our data

		var self = this

		const temp = this.subscriptionsTemp.filter(function (d) {
			return (
				(
					(d.name != null && d.name.toLowerCase().indexOf(val) !== -1) ||
					(d.clientName != null && d.clientName.toLowerCase().indexOf(val) !== -1) ||
					(d.notes != null && d.notes.toLowerCase().indexOf(val) !== -1) ||
					(d.alertStatusName != null && d.alertStatusName.toLowerCase().indexOf(val) !== -1) ||
					val.toString().trim() == ''
				)
			);
		});

		// update the rows
		this.subscriptions = temp;
		// Whenever the filter changes, always go back to the first page
		// this.dataView.offset = 0;
	}


	getChartToolbarItems() {
		return ['chartDownload', 'chartData', 'chartFormat']

	}

	onRowClicked(event) {
		console.log(event);

		this.settingsService.clientSetAdmin = true;
		this.settingsService.client = this.settingsService.clients.filter(x => x.uuid == event.data.clientUuid)[0];
		if (!this.showForm && this.isSelected) {
			this.router.navigate(['/administration/subscriptions/', event.node.data.uuid]);
		}

	}

	// refreshGrid() {
	// 	this.autoSizeColumns();
	// }


	// autoSizeColumns() {
	// 	// if (this.gridOptions) return

	// 	let allColumnIds = [];
	// 	this.grid.api.getColumns().forEach(function (column) {
	// 		allColumnIds.push(column);
	// 	});
	// 	this.grid.api.autoSizeColumns(allColumnIds);

	// }

	onRowSelected(event) {
		console.log(event);
	}




}
