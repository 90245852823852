import { Component, ViewEncapsulation, ViewChild, OnInit, AfterViewInit, HostListener, Input, Output, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgChartsModule } from 'ng2-charts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import 'chartjs-plugin-datalabels';
import { ErrorsService } from '../../../core/errors/errors.service';
import { SettingsService, userTypes, userRoles } from '../../../core/settings/settings.service';
import { ColorsService } from '../../../core/colors/colors.service';
import { EventsService } from '../../../core/events/events.service';
import { LibrariesService } from '../../../core/libraries/libraries.service';
import { DeviceLocationsService } from '../../../core/api/api.services';
import { SpeedBinsPercentChartComponent } from '../../../shared/component/charts/speedbins-percent-chart/speedbins-percent-chart.component';
import { PeriodSummariesGridComponent } from '../../../shared/component/grids/periodsummaries-grid/periodsummaries-grid.component';
import { BinSummariesGridComponent } from '../../../shared/component/grids/binsummaries-grid/binsummaries-grid.component';
import * as $ from 'jquery';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-charts-datatables',
  templateUrl: './charts-datatables.component.html',
  styleUrls: ['./charts-datatables.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChartsDataTablesComponent implements OnInit, AfterViewInit, OnDestroy {
  //@ViewChild('sliderBinGroups') sliderBinGroups: IonRangeSliderComponent;
  @ViewChild(PeriodSummariesGridComponent) dataPeriodSummaries: PeriodSummariesGridComponent;
  @ViewChild(BinSummariesGridComponent) dataBinSummaries: BinSummariesGridComponent;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  private stickyTrigger: boolean = false;
  private stickyTriggerScroll: number = 0;
  public periodSummariesData: any = null;
  public binSummariesData: any = null;


  @Input() public dateEnd: Date = null;
  @Input() public dateBegin: Date = null;
  @Input() public timeSegment: number = 0;

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll($event) {

  //   var cbHeight = $('#cardBase').height()
  //     -$('#cardBaseHeaderRow').height()-$('#cardBaseHeaderRow').position().top

  //   if($('#chartOptionsStickySpacer').height() <= 0)
  //     $("#chartOptionsStickySpacer").css({ height: $('#cardBase').height()-parseInt($('#dataPeriodSummaries').css('margin-top')) + 'px' });

  //   var spaceHeight = $('#chartOptionsStickySpacer').height()
  //     -$('#cardBaseHeaderRow').height()-$('#cardBaseHeaderRow').position().top
    
  //   //console.log("cbHeight-window.scrollY="+(cbHeight-window.scrollY));
  //   //console.log("spaceHeight-window.scrollY="+(spaceHeight-window.scrollY));

  //   if (this.showChartOptions && window.scrollY < cbHeight) {
  //     this.showChartOptions = true;
  //     this.showChartOptionsSticky = false;
  //     $("#chartOptionsStickySpacer").css({ height: $('#cardBase').height()-parseInt($('#dataPeriodSummaries').css('margin-top')) + 'px' });
  //     console.log("scrolling if options: Spacer Set, spaceHeight="+$('#cardBase').height());
  //   }
  //   else if (this.showChartOptionsSticky && window.scrollY < spaceHeight)  {
      
  //     this.showChartOptions = true;
  //     this.showChartOptionsSticky = false;
  //     this.stickyTrigger = true;
  //     this.stickyTriggerScroll = window.scrollY;

  //     $("#cardBase").css({ width: '', top: '' });
  //     console.log("scrolling if sticky: cardBase clear, spaceHeight="+spaceHeight);
  //   }
  //   else if (this.stickyTrigger){
  //     //console.log("scrolling stickyTrigger: window.scrollY ="+window.scrollY+', '+this.stickyTriggerScroll); 
  //     if (window.scrollY - this.stickyTriggerScroll > 20) this.stickyTrigger = false;

  //   }
  //   else {
  //     // if (this.showChartOptionsSticky == true) 
  //     // {
  //     // this.chartOptionsStickySpacerHeight = 10 * $('#cardBaseHeaderRow').position().top+$('#cardBaseHeaderRow').height();
  //     // if (this.chartOptionsStickySpacerHeight>0) $("#chartOptionsStickySpacer").css({ height: this.chartOptionsStickySpacerHeight+'px'});
  //     // }
  //     var new_width = $('#cardContainer').width();
  //     $('#cardBase').width(new_width);

  //     var new_top = $('#headerRightNavbar').position().top + $('#headerRightNavbar').height();
  //     if (this.showChartOptionsSticky != true || spaceHeight < 0) {
  //       $("#cardBase").css({ top: (new_top) + 'px' });
  //       this.showChartOptionsSticky = true;
  //       console.log("scrolling else: Set new_top");
        
  //       //setTimeout(() => {this.onWindowScroll(event); }, 250);
  
  //     }
  //     if (this.showChartOptions != false) {
  //       this.showChartOptions = false;
  //     }

  //      console.log("scrolling else: new_top:"+new_top+", spaceHeight="+spaceHeight);
  //   }

  // }
  // @HostListener('window:resize', ['$event'])
  // onWindowResize($event) {
  //   //console.log("scrolling: window.scrollY:"+window.scrollY);
  //   if (this.showChartOptionsSticky) {
  //     var new_width = $('#cardContainer').width()
  //     $('#cardBase').width(new_width)
  //   }
  // }

  public chartDpsData: any = null
  datePipe = new DatePipe('en-US');
  decimalPipe = new DecimalPipe('en-US');

  private paramSub: any;
  public showDevicesView: boolean = true;
  public showChartOptions: boolean = true;
  public showChartOptionsSticky: boolean = false;
  public chartOptionsStickySpacerHeight: number = 0;

  // private _deviceLocationUuid: string = "";
  @Input() public deviceLocation: any = null;  
  get deviceLocationUuid(): string {
    if (this.deviceLocation) return this.deviceLocation.uuid
    return ""
  }

  public fgData: UntypedFormGroup = this.formBuilder.group({
    dateRange: [new Date(), new Date()],
    dataTableType: "0",  
    timeSegment: "0",  
  });

  constructor(
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    public deviceLocationsService: DeviceLocationsService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder
  ) {

    var date1: Date = new Date(localStorage.getItem('chartsStandardDateRangeOneBegin'))
    var date2: Date = new Date(localStorage.getItem('chartsStandardDateRangeOneEnd'))
    
    this.fgData.patchValue({
      dateRange: [date1 > new Date('1/1/2000') ? date1 : new Date(), date2 > new Date('1/1/2000') ? date2 : new Date()],

    });
  }

  ngOnInit() {

  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.onChangesFormSetup();
  }

  public chartsRefresh() {
    if (!this.deviceLocationUuid || this.deviceLocationUuid === '')  {
      return;
    }
    this.getPeriodSummariesData();
    this.getBinSummariesData();

    // localStorage.setItem('chartsStandardBinGroupsEnabled', this.fgData.get('binGroupsEnabled').value)
    // localStorage.setItem('chartsStandardBinGroupSplitOne', this.fgData.get('binGroupSplitOne').value)
    // localStorage.setItem('chartsStandardBinGroupSplitTwo', this.fgData.get('binGroupSplitTwo').value)
    // localStorage.setItem('chartsStandardBinGroupLeftDisplay', this.fgData.get('binGroupLeftDisplay').value)
    // localStorage.setItem('chartsStandardBinGroupCenterDisplay', this.fgData.get('binGroupCenterDisplay').value)
    // localStorage.setItem('chartsStandardBinGroupRightDisplay', this.fgData.get('binGroupRightDisplay').value)

  }
  public timeSegmentRefresh() {
    var dateStart = new Date(this.fgData.get('dateRange').value[0]);
    var dateEnd = new Date(this.fgData.get('dateRange').value[1]);
    var today = new Date();
    var yesterday = new Date(today.getFullYear(),today.getMonth(), today.getDate())
    yesterday.setDate(yesterday.getDate()-1)
    if (dateEnd > yesterday){
      dateEnd = yesterday
      //this.fgChart.get('dateRange').value[1] = yesterday;
      this.fgData.patchValue({
        dateRange: [dateStart, dateEnd]
      });
    }

    var timeDiff = dateEnd.getTime() - dateStart.getTime();
    let daysDiff = (timeDiff / (1000 * 3600 * 24)).round(0)

    if (daysDiff > 7) {
      this.fgData.patchValue({timeSegment: "0"})
    }

    if (daysDiff > 365) {
      this.toastrService.error("", "Date Period should be less then 365 days", { timeOut: 15000, tapToDismiss: true });
      
      const dateEndNew = new Date(dateEnd);
      const dateStartNew = new Date(dateEnd.setDate(dateEnd.getDate() - 365));
 
      //this.fgChart.get('dateRange').value[1] = dateEndNew;
      //this.fgChart.get('dateRange').value[0] = dateStartNew;

      this.fgData.patchValue({
        dateRange: [dateStartNew, dateEndNew]
      });

    }
  }
  onChangesFormSetup(): void {
    this.chartsRefresh();
    this.fgData.get('dateRange').valueChanges
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(val => {
      
      this.timeSegmentRefresh();
      this.chartsRefresh();
      localStorage.setItem('chartsStandardDateRangeOneBegin', this.fgData.get('dateRange').value[0].toLocaleString());
      localStorage.setItem('chartsStandardDateRangeOneEnd', this.fgData.get('dateRange').value[1].toLocaleString());
      
    });
    this.fgData.get('timeSegment').valueChanges
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(val => {
      this.chartsRefresh();
    });
  }

  getPeriodSummariesData(): any {
    if (this.deviceLocationUuid === '' || !this.settingsService.client) {
      return;
    }

    this.dateBegin = this.fgData.get('dateRange').value[0];
    this.dateEnd = this.fgData.get('dateRange').value[1];
    this.timeSegment = this.fgData.get('timeSegment').value;

    this.settingsService.showSpinner("chartdatatables", false, 250);
    
    return this.deviceLocationsService.readPeriodSummaryByDateRange(this.deviceLocationUuid, this.dateBegin, this.dateEnd, this.timeSegment)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.settingsService.hideSpinner("chartdatatables");
          this.periodSummariesData = result;
        },
        error => {
          this.settingsService.hideSpinner("chartdatatables");
          this.toastrService.error('Server Error (getPeriodSummariesData)',
            this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });
  }

  getBinSummariesData(): any {
    if (this.deviceLocationUuid === '' || !this.settingsService.client) {
      return;
    }

    this.dateBegin = this.fgData.get('dateRange').value[0];
    this.dateEnd = this.fgData.get('dateRange').value[1];

    this.settingsService.showSpinner("chartdatatables", false, 250);

    return this.deviceLocationsService.readBinSummaryByDateRange(this.deviceLocationUuid, this.dateBegin, this.dateEnd)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.settingsService.hideSpinner("chartdatatables");
          this.binSummariesData = result;
        },
        error => {
          this.settingsService.hideSpinner("chartdatatables");
          this.toastrService.error('Server Error (getBinSummariesData)',
            this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });
  }

  showChartOptionsClick(event) {
    // if (this.showChartOptions && this.showChartOptionsSticky == false && window.scrollY < $('#cardBase').height()) {
    //   $("#chartOptionsStickySpacer").css({ height: $('#cardBase').height() + 'px' });
    //   this.showChartOptionsSticky = true;
    //   $('html, body').animate({
    //     scrollTop: (
    //         $("#dataPeriodSummaries").position().top-
    //         $('#cardBaseHeaderRow').height()-
    //         $('#cardBaseHeaderRow').position().top )
    //   }, 200);
      
    //   setTimeout(() => {this.onWindowScroll(event); }, 250);
    // }
    this.showChartOptions = !this.showChartOptions;

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      //console.log(data)
    }, 25);

  }

  onChartJsEvent(event)
  {

    switch (event.toString()) {
      case "click":
        {
          if (this.showChartOptionsSticky == true && 
            this.showChartOptions == true)
            {
              this.showChartOptions = false
            }
          break;
        }
      case "---":
        {
          break;
        }
    }

  }

  saveCsv() {
    const locationName = $('[class*="location_name"]').text().replace(' ', '_');
    const dateBegin = this.fgData.get('dateRange').value[0];
    const formattedDateBegin = `${dateBegin.getMonth() + 1}-${dateBegin.getDate()}-${dateBegin.getFullYear()}`;

    const dateEnd = this.fgData.get('dateRange').value[1];
    const formattedDateEnd = `${dateEnd.getMonth() + 1}-${dateEnd.getDate()}-${dateEnd.getFullYear()}`;

    const fileName = `${locationName}_${formattedDateBegin}_to_${formattedDateEnd}`;

    if (this.fgData.get('dataTableType').value.toString() == "0")
      this.dataPeriodSummaries.saveCsv("Stats_"+fileName);
    else
      this.dataBinSummaries.saveCsv("Speeds_"+fileName);
  }

}

