
import {filter,  takeUntil } from 'rxjs/operators';
import { Component, ElementRef, EventEmitter, ViewEncapsulation, OnInit, AfterViewInit, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SettingsService, userTypes, userRoles } from '../../../../../core/settings/settings.service';
import { UsersService, ClientsService, UserTypesService } from '../../../../../core/api/api.services';
import { EventsService } from '../../../../../core/events/events.service';
import { ToastrService } from 'ngx-toastr';
import { LibrariesService } from '../../../../../core/libraries/libraries.service';
import { PasswordValidator } from '../../../../../core/validators/password.validator';
import { ErrorsService } from '../../../../../core/errors/errors.service';
import { Subject } from 'rxjs';

export enum UserFormState {
  Initializing = 1,
  Read = 2,
  New = 3,
  Edit = 4,
  Save = 5,
  Saving = 6,
  Saved = 7,
  Cancelled = 8,
}

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UserFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onFormEvent: EventEmitter<any> = new EventEmitter<any>();

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  get formTitle() {
    if (this.userUuid && this.userUuid.toString() != "") {
      return 'Edit User'
    } else return 'Add User'
  }

  public formLoaded: boolean = false;
  public formStates = UserFormState;
  private _formState: UserFormState = this.formStates.Initializing;
  get formState(): UserFormState {
    return this._formState
  }
  @Input() set formState(newFormState: UserFormState) {

    var updateFormState: UserFormState = null
    //this.fgUser.disable()

    switch (newFormState) {

      case this.formStates.Read: {
        updateFormState = newFormState
        this.formRead()
        break
      }
      case this.formStates.New: {
        updateFormState = newFormState
        this.formAdd()
        break
      }
      case this.formStates.Edit: {
        updateFormState = newFormState
        this.formEdit()
        break
      }
      case this.formStates.Save: {
        //presetting to address form state sequencing durring validation
        this._formState = newFormState
        this.formSave()
        break
      }
      case this.formStates.Saved: {
        //presetting to address form state sequencing durring validation
        this._formState = newFormState
        break
      }
      case this.formStates.Cancelled: {
        this.formCancel()
        break
      }
      default:
        {
          updateFormState = newFormState
        }
    }

    if (updateFormState != null) {
      this._formState = updateFormState
    }
  }

  private _userUuid: string = "";
  get userUuid(): string {
    return this._userUuid;
  }
  @Input() set userUuid(newUserUuid: string) {
    if (this.librariesService.guidValidate(newUserUuid)) {
      this._userUuid = newUserUuid;
      if (this.formLoaded) this.getUser()
    }
    else {
      this._userUuid = "";
    }
    if (newUserUuid.toLowerCase() == 'add') this.formState = this.formStates.New
  }

  private user: any = null; //new UserCrudDto();
  public userTypes: any[] = [];
  public clients: any[] = [];
  public clientsSelected: any[] = [];
  public userClients: any[] = [];

  get isEditMode(): boolean {

    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving:
      case this.formStates.Saved:
        {
          return true
        }
      default:
        {
          return false
        }
    }
  }

  public isModified = false;

  constructor(
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public clientsService: ClientsService,
    public usersService: UsersService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    public userTypesService: UserTypesService,
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef
  ) { }

  public fgUser: UntypedFormGroup = this.formBuilder.group({
    uuid: '',
    userTypeId: [0, [Validators.required]],
    clientsSelect: [],
    email: ['', [Validators.required, Validators.email]],
    first: ['', [Validators.required, Validators.minLength(2)]],
    last: ['', [Validators.required, Validators.minLength(2)]],
    sendInvitation: false,
    passwordChange: false,
    password: ['', [PasswordValidator.strong(8, true, true, true, "#@$!%*?()>,:/=^_-{}~|")
    ]
    ],
    passwordConfirm: ['', [
      function (control: AbstractControl) {
        if (!control.parent) return null
        if (
          (
            control.parent.value['passwordChange'] == false ||
            (control.parent.value['passwordChange'] == true &&
              control.parent.value['password'] != '' &&
              control.value == control.parent.value['password']
            )
          )
        ) {
          return null
        }
        return { passwordConfirm: false }
      }
    ]
    ]
  });

  ngOnInit() {
    
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {

    if (this.formState == this.formStates.New ||
      this.formState == this.formStates.Edit) {
      this.fgUser.get('userTypeId').enable()
      this.fgUser.get('clientsSelect').enable()
    }
    else {
      this.fgUser.get('userTypeId').disable()
      this.fgUser.get('clientsSelect').disable()
    }

    this.fgUser.get('passwordChange').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(val => {

      if (val == false || !this.isEditMode) {
        this.fgUser.get('password').disable()
        this.fgUser.get('passwordConfirm').disable()
      } else {
        this.fgUser.get('password').enable()
        this.fgUser.get('passwordConfirm').enable()
      }

    });

    this.getUserTypes()
    this.getClients()

    let self = this;
    setTimeout(() => {
      if (self.user == null) self.getUser()
      self.formLoaded = true
    }, 500);

  }

  getUserTypes(): any {
    this.userTypes = [];

    //alert(this.deviceUuid);

    return this.userTypesService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.userTypes = result;
        },
        error => {
          this.toastrService.error("Server Error (getUserTypes)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });
  }

  getClients(): any {
    this.clients = [];

    //alert(this.deviceUuid);

    return this.clientsService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.clients = result;
        },
        error => {
          this.toastrService.error("Server Error (getClients)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });
  }

  clearUser() {
    this.user = null;
    this.fgUser.patchValue(
      {
        uuid: '',
        userTypeId: 0,
        clientsSelect: [],
        email: '',
        first: '',
        last: '',
        passwordChange: false,
        password: '',
        passwordConfirm: ''
      }
    )
  }

  getUser(): any {

    this.clearUser()

    if (!this.userUuid) return;

    return this.usersService.readByUuid(this.userUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {

          this.fgUser.patchValue(
            {
              uuid: result.uuid,
              userTypeId: result.userTypeId,
              email: result.email,
              first: result.first,
              last: result.last,
              passwordChange: false,
              password: '',
              passwordConfirm: ''
            }
          )
          this.getUserClients();
          this.formState = this.formStates.Read;
          this.user = result;

          //this.deviceLocationsTemp = result;
        },
        error => {
          this.toastrService.error("Server Error (getUser)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
          this.formState = this.formStates.Read
        });
  }

  getUserClients(): any {

    this.userClients = [];
    this.fgUser.patchValue(
      {
        clientsSelect: []
      }
    )

    if (this.formState == this.formStates.New) return;
    if (this.fgUser.get('userTypeId').value != 0) return;

    return this.usersService.readClients(this.userUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.userClients = result;

          let clntUsr = this.userClients.map(obj => obj.uuid);

          this.fgUser.patchValue(
            {
              clientsSelect: clntUsr
            }
          )

        },
        error => {
          this.toastrService.error("Server Error (getUserClients)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });
  }

  formRead() {

    this.fgUser.get('userTypeId').disable()
    this.fgUser.get('clientsSelect').disable()

  }

  formAdd() {
    //do setup

    this.isModified = false;
    //this.fgUser.enable()
    this.fgUser.get('userTypeId').enable()
    this.fgUser.get('clientsSelect').enable()

    if (this.fgUser.get('passwordChange').value == false)
      this.fgUser.get('password').disable()
    if (this.fgUser.get('passwordChange').value == false)
      this.fgUser.get('passwordConfirm').disable()

    //Send Event
    this.onFormEvent.emit("add")
  }

  formEdit() {
    //do setup

    var self = this;

    this.isModified = false;
    //this.fgUser.enable()
    this.fgUser.get('userTypeId').enable()
    this.fgUser.get('clientsSelect').enable()

    if (this.fgUser.get('passwordChange').value == false)
      this.fgUser.get('password').disable()
    if (this.fgUser.get('passwordChange').value == false)
      this.fgUser.get('passwordConfirm').disable()

    //Send Event
    this.onFormEvent.emit("edit")

    // setTimeout(() => {

    // }, 1000);

  }

  formSave() {

    if (this.fgUser.valid == false) {
      //alert(this.fgUser.valid)
      this.librariesService.validateFormGroup(this.fgUser)
      this.formState = this.userUuid == "" ? this.formStates.New : this.formStates.Edit
      this.onFormEvent.emit("edit")
      this.toastrService.warning("Validation Warning (User)", "Verify Required Data", { timeOut: 5000, tapToDismiss: true });
      return
    }

    this.formState = this.formStates.Saving
    this.onFormEvent.emit("saving")

    this.clientsSelected = []
    if (this.fgUser.value.clientsSelect && this.fgUser.value.clientsSelect.length > 0)
      this.clientsSelected = this.clients.filter(c => { return this.fgUser.value.clientsSelect.includes(c.uuid) }
      )

    if (this.userUuid == "") {

      var userPost: any = {
        "uuid": "",
        "email": this.fgUser.value.email,
        "first": this.fgUser.value.first,
        "last": this.fgUser.value.last,
        "userTypeId": this.fgUser.value.userTypeId
      }

      if (this.fgUser.value.passwordChange == true &&
        this.fgUser.value.password.toString().length > 0 &&
        this.fgUser.value.password == this.fgUser.value.passwordConfirm) {
        userPost.password = this.fgUser.value.password
      }

      return this.usersService.create(userPost)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {

            this.userUuid = result.uuid

            return this.usersService.updateClients(this.userUuid, this.clientsSelected)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                (result: any) => {

                  this.getUserClients();

                  this.formState = this.formStates.Read
                  this.onFormEvent.emit("saved")
                  this.toastrService.success("Success", "User Saved", { timeOut: 2000, tapToDismiss: true });
                },
                error => {
                  this.toastrService.error("Server Error (userSave: updateClients)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
                  this.formState = this.formStates.New
                });

          },
          error => {
            this.toastrService.error("Server Error (userSave)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
            this.formState = this.formStates.New
          });
    }
    else {
      return this.usersService.readByUuid(this.userUuid)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {

            result.email = this.fgUser.value.email
            result.first = this.fgUser.value.first
            result.last = this.fgUser.value.last
            result.userTypeId = this.fgUser.value.userTypeId
            result.sendInvitation = this.fgUser.value.sendInvitation

            if (this.fgUser.value.passwordChange == true &&
              this.fgUser.value.password.toString().length > 0 &&
              this.fgUser.value.password == this.fgUser.value.passwordConfirm) {
              result.password = this.fgUser.value.password
            }

            return this.usersService.put(result)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                (result: any) => {

                  this.userUuid = result.uuid

                  return this.usersService.updateClients(this.userUuid, this.clientsSelected)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe(
                      (result: any) => {

                        this.getUserClients();

                        this.formState = this.formStates.Read
                        this.onFormEvent.emit("saved")
                        this.toastrService.success("Success", "User Saved", { timeOut: 2000, tapToDismiss: true });
                      },
                      error => {
                        this.toastrService.error("Server Error (userSave: updateClients)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
                        this.formState = this.formStates.Edit
                      });

                },
                error => {
                  this.toastrService.error("Server Error (userSave: put)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
                  this.formState = this.formStates.Edit
                });

          },
          error => {
            this.toastrService.error("Server Error (userSave: getUser)", this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
            this.formState = this.formStates.Edit
          });

    }
  }

  formCancel() {
    this.librariesService.untouchedFormGroup(this.fgUser)
    this.formState = this.formStates.Read
    this.onFormEvent.emit("cancelled")
    this.getUser()
  }

}
