
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, Input, Output, OnDestroy, DoCheck, NgZone, ChangeDetectionStrategy } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Observable, Subscription, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgChartsModule } from 'ng2-charts';

import { ClientsService, DeviceDailySummariesService, DeviceLocationsService } from '../../core/api/api.services';
import { SettingsService, userTypes, userRoles } from '../../core/settings/settings.service';
import { ColorsService } from '../../core/colors/colors.service';
import { EventsService } from '../../core/events/events.service';
import { ErrorsService } from '../../core/errors/errors.service';
import { DevicesService } from '../../core/api/devices.service';
import { DeviceNotificationsService } from '../../core/api/devicenotifications.service';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { LibrariesService } from '../../core/libraries/libraries.service';
export enum DeviceCommunicationType {
  WiFi = 0,
  Cellular = 1
}
export enum CategoriesType {
  Alert = 10,
  Analytics = 20
}

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();
  public splineDataOverviewLoad: boolean = false;
  public dataLoading: boolean = false;

  datePipe = new DatePipe('en-US');
  decimalPipe = new DecimalPipe('en-US');
  WiFiCount = 0;
  CellularCount = 0;
  DeviceAlertsCount = 0;
  splineHeightOverview = 250;

  deviceLocationsNewActive: string[] = [];
  // "ca98c22e-9073-440c-9ac4-b3a5b18a33a8",
  // "11395819-cd24-4917-a159-ecb6d1c90f0c",
  // "ca98c22e-9073-440c-9ac4-b3a5b18a33a8",
  // "11395819-cd24-4917-a159-ecb6d1c90f0c",

  deviceLocationsMostActive: string[] = [];
  // "8f1ce822-4fec-4b98-954c-ea1f1c1d42ac",
  // "48507887-9d6b-48b1-83f5-a3be49c9449d",

  splineDataOverview: any =
    [{
      "label": "vehicles",
      "color": "#768294",
      "data": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]]
    },
    {
      "label": "Violators",
      "color": this.colorsService.byName('danger'),
      "data": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]]
    }
    ];

  splineOptionsOverview = {
    series: {
      lines: {
        show: true,
        fill: 0.8
      },
      points: {
        show: true,
        radius: 2
      },
      // splines: {
      //     show: true,
      //     tension: 0.4,
      //     lineWidth: 1,
      //     fill: 0.5
      // }
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 1,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: (label, x, y) => {
        return this.datePipe.transform(new Date(x), 'EEEE, MM/dd/yyyy') + ' : ' + this.decimalPipe.transform(y, '1.0');
      }
    },
    xaxis: {
      tickColor: '#fcfcfc',
      mode: 'time',
      tickFormatter: (v, axis) => {
        if (!v || v == 0) return "----"
        return this.datePipe.transform(new Date(v), 'MM/yyyy');
      },
      // timeformat: "%m/%Y"
      // mode: 'categories'
      // tickFormatter: function (v, axis) {
      // return "hello";
      // }
    },
    yaxis: {
      min: 0,
      max: 1000, // optional: use it for a clear represetation
      tickColor: '#eee',
      // position: ($scope.app.layout.isRTL ? 'right' : 'left'),
      tickFormatter: (v) => {
        return this.decimalPipe.transform(v, '1.0');
      }
    },
    shadowSize: 0
  };

  constructor(
    public eventsService: EventsService,
    public toastrService: ToastrService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    public deviceDailySummariesService: DeviceDailySummariesService,
    public colorsService: ColorsService,
    public devicesService: DevicesService,
    public deviceLocationsService: DeviceLocationsService,
    public deviceNotificationsService: DeviceNotificationsService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    console.log(this.route.routeConfig.component.name + ":ngOnInit", this)

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.toLowerCase().split("?")[0] == '/overview') {
          console.log(this.route.routeConfig.component.name + ":NavigationEnd")
          this.refreshOverview(null);
          // var self = this;
          // setTimeout(function () {
          //   self.refreshOverview(null)
          // }, 100);

        }
      }
    });

  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    console.log(this.route.routeConfig.component.name + ":ngAfterViewInit", this)
    this.refreshOverview(null);
  }

  ngDoCheck() {
    //console.log('doCheck',this.ngZone);
  }

  refreshOverview(event) {
    console.log(this.route.routeConfig.component.name + ":refreshOverview", this.settingsService.client)

    this.dataLoading = true;
    var self = this;

    if (!this.settingsService.client) {
      setTimeout(() => {
        self.refreshOverview(null);
      }, 100);
    }
    else {
      setTimeout(() => {
        self.dataLoading = true;
        self.getDeviceAlertsCount();
        self.getWiFiDevicesCount();
        self.getCellularDevicesCount();
        self.getDeviceLocationsMostActive();
      }, 500);
    }
  }

  colorByName(name) {
    return this.colorsService.byName(name);
  }

  getWiFiDevicesCount(): any {
    if (!this.settingsService.client) {
      return;
    }

    return this.devicesService.countByCommunicationType(DeviceCommunicationType.WiFi)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.WiFiCount = result;
        },
        error => {
          if (error && error.status && error.status != 404)
            this.toastrService.error('Server Error (getWiFiDevicesCount)',
              this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });
  }

  getDeviceAlertsCount(): any {
    if (!this.settingsService.client) {
      return;
    }

    return this.deviceNotificationsService.getCount(CategoriesType.Alert)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.DeviceAlertsCount = result;
        },
        error => {
          if (error && error.status && error.status != 404)
            this.toastrService.error('Server Error (getDeviceAlertsCount)',
              this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });
  }

  getCellularDevicesCount(): any {
    if (!this.settingsService.client) {
      return;
    }

    return this.devicesService.countByCommunicationType(DeviceCommunicationType.Cellular)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.CellularCount = result;
        },
        error => {
          if (error && error.status && error.status != 404)
            this.toastrService.error('Server Error (getCellularDevicesCount)',
              this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });
        });
  }

  getDeviceLocationsMostActive(argHardReload: boolean = false): any {

    if (argHardReload || !this.settingsService.client) this.deviceLocationsMostActive = [];
    if (!this.settingsService.client) {
      return;
    }

    let today = new Date();
    let dateEnd: Date = today;
    let dateBegin: Date = new Date(dateEnd);
    dateBegin.setDate(dateBegin.getDate() - this.settingsService.getDeviceLocationsMostActiveDays());

    return this.deviceLocationsService.readMostActiveByDateRange(
      dateBegin, dateEnd, this.settingsService.getDeviceLocationsMostActiveMaxRecords())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any[]) => {
          //this.deviceLocationsMostActive = result.map(obj => obj.uuid);
          let resultTemp = result.map(obj => obj.uuid);
          if (this.librariesService.arraysChanged(this.deviceLocationsMostActive, resultTemp)) {
            this.deviceLocationsMostActive = resultTemp
          }
          this.getDeviceLocationsNewActivity()
        },
        error => {
          if (error && error.status && error.status != 404)
            this.toastrService.error('Server Error (getDeviceLocationsMostActive)',
              this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });

          this.getDeviceLocationsNewActivity()
        });
  }


  getDeviceLocationsNewActivity(argHardReload: boolean = false): any {

    if (argHardReload || !this.settingsService.client) this.deviceLocationsNewActive = [];
    if (!this.settingsService.client) {
      return;
    }

    let today = new Date();
    let dateEnd: Date = today;
    let dateBegin: Date = new Date(dateEnd);
    dateBegin.setDate(dateBegin.getDate() - this.settingsService.getDeviceLocationsNewActiveDays());

    return this.deviceLocationsService.readNewActiveByDateRange(
      dateBegin, dateEnd)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any[]) => {
          //this.deviceLocationsNewActive = result.map(obj => obj.uuid);
          let resultTemp = result.map(obj => obj.uuid);
          if (this.librariesService.arraysChanged(this.deviceLocationsNewActive, resultTemp)) {
            this.deviceLocationsNewActive = resultTemp
          }
          this.getSplineDataOverview();
        },
        error => {
          if (error && error.status && error.status != 404)
            this.toastrService.error('Server Error (getDeviceLocationsNewActivity)',
              this.errorsService.errorParse(error), { timeOut: 15000, tapToDismiss: true });

          this.getSplineDataOverview();
        });
  }

  getSplineDataOverview(argHardReload: boolean = false): any {

    //this.splineDataOverviewLoad = false;

    if (!this.settingsService.client) {
      this.dataLoading = false;
      return;
    }

    //console.log("getSplineDataOverview")

    let today = new Date();
    // var dateEnd: Date = new Date(today.getFullYear(),today.getMonth(),1);
    let dateEnd: Date = today;
    let dateBegin: Date = new Date(dateEnd);
    dateBegin.setDate(dateBegin.getDate() - 91);

    //this.settingsService.showSpinner("overview", false, 250);

    return this.deviceDailySummariesService.readDeviceDailySummariesSummaryDayByDateRange(dateBegin, dateEnd)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          //this.settingsService.hideSpinner("overview");

          let splineData: any = JSON.parse(JSON.stringify(this.splineDataOverview));
          let splineOptions: any = (this.splineOptionsOverview);
          let vhcl = new Array();
          let vltr = new Array();
          let yaxismax: number = 1000;
          let xValue: Date;

          result.forEach(element => {
            xValue = new Date(element.date);

            //Production Code
            vhcl.push([xValue.getTime(), ((element.vehicles === 0) ? null : element.vehicles)]);
            vltr.push([xValue.getTime(), ((element.violators === 0) ? null : element.violators)]);

            //full chart random data generator
            // vhcl.push([xValue.getTime(), ((element.vehicles === 0 || element.vehicles < 1000) ? Math.random()*10000 : element.vehicles) ]);
            // vltr.push([xValue.getTime(), ((element.violators === 0 || element.violators < 1000 ) ? Math.random()*vhcl[vhcl.length-1][1] : element.violators) ]);

            // vhcl.push([(element.month.toString()+'-'+element.year.toString()),element.vehicles])
            // vltr.push([(element.month.toString()+'-'+element.year.toString()),element.violators])

            if (element.vehicles > yaxismax) {
              yaxismax = element.vehicles;
            }
            if (element.violators > yaxismax) {
              yaxismax = element.violators;
            }
          });

          splineData[0].data = vhcl;
          splineData[1].data = vltr;
          splineOptions.yaxis.max = (yaxismax * 1.2);
          this.splineOptionsOverview = splineOptions;
          // this.splineOptionsOverview.yaxis.max = yaxismax;
          // this.splineOptionsOverview = null;
          this.splineDataOverview = splineData;
          this.splineDataOverviewLoad = true;
          this.dataLoading = false;

          // console.log(JSON.stringify(result))
          // console.log(JSON.stringify(splineOptions))
          // console.log(JSON.stringify(this.splineDataOverview))

          // alert(JSON.stringify(vhcl))
          // alert(JSON.stringify(this.splineDataOverview))

        },
        error => {
          if (error && error.status && error.status != 404)
            this.toastrService.error('Server Error', this.errorsService.errorParse(error), { tapToDismiss: true });

          this.dataLoading = false;
        });
  }

}
